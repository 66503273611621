import styled from 'styled-components';

export const StyledStartFormContainer = styled.section`
   width: 100%;

   padding-top: 10%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: relative;
`;

export const StyledTitle = styled.h3`
   width: 100%;
   padding-left: 15%;
   margin-bottom: 2%;
   font-size: 6vh;
   @media (max-width: 1050px) {
      padding-right: 0;
      padding-left: 10%;
      font-size: 2.4rem;
      line-height: 2.6rem;
   }
   > span {
      color: ${({ theme }) => theme.colors.purple.light};
   }
`;
export const StyledText = styled.p`
   width: 100%;
   color: grey;
   padding-left: 15%;
   padding-right: 40%;
   font-size: 4vh;
   line-height: 4.5vh;
   font-weight: 200;
   z-index: 10000;
   // MOBILE
   @media (max-width: 1050px) {
      padding-right: 0;
      padding-left: 10%;
      margin-bottom: 5vh;
      font-size: 1.5rem;
      line-height: 2.2rem;
   }
`;
export const StyledFormWrapper = styled.div`
   z-index: 1000;
   width: 80%;
   height: 100%;
   transform: translateY(30%);
   display: flex;
   > div:nth-child(1) {
      box-shadow: -5px 0px 20px 0px rgba(191, 191, 191, 1);
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 3% 10%;
      background: white;
      @media (max-width: 1050px) {
         padding: 10% 10%;
      }
      > h5 {
         font-size: 4vh;
         font-weight: 300;
         position: relative;
         transform: translateY(-30px);
         @media (max-width: 1050px) {
            transform: translateY(0px);
         }
         border-bottom: 3px solid ${({ theme }) => theme.colors.purple.light};
         > span {
            color: ${({ theme }) => theme.colors.purple.light};
         }
      }
   }

   > div:nth-child(2) {
      background: ${({ theme }) => theme.colors.blue.normal};
      flex: 1.5;
   }

   // form
   .input-error-span {
      color: red;
      width: 100%;
      font-size: 1.5vh !important;
   }
   .form-submit-button {
      font-size: 2vh;
   }

   // MOBILE
   @media (max-width: 1050px) {
      width: 100%;
      flex-direction: column;
      transform: translateY(0%);
   }
`;

export const StyledPhoneWrapper = styled.div`
   width: 700px;
   height: 90%;
   overflow: hidden;
   position: absolute;
   right: 0%;
   top: -5%;
   &:after {
      content: '';
      z-index: 100;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
         0deg,
         rgba(255, 255, 255, 1) 31%,
         rgba(255, 255, 255, 0) 85%
      );
      margin: auto;
      position: absolute;
   }
   #iphone-mackup {
      transform: translateX(-25%);
      z-index: 100;
      width: 100%;
      height: 100%;
   }
   #screen-platform {
      width: 90%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 10px;
      right: 0;
      margin: auto;
      z-index: -50;
   }
   // MOBILE
   @media (max-width: 1050px) {
      visibility: hidden;
   }
`;
