import styled from 'styled-components';

const backgroundUrl = '/img/services-background.jpg';
const serviceCardBgc1 = '/img/service-background-1.jpg';
const serviceCardBgc2 = '/img/service-background-2.jpg';
const serviceCardBgc3 = '/img/service-background-3.jpg';

export const StyledReviewsMenuContainer = styled.div`
   visibility: hidden;
   opacity: 0;
   transform: translateY(100px);

   width: 40vw;
   height: 100vh;
   position: fixed;
   left: 2.5%;
   display: flex;
   z-index: 100;
   @media (max-width: 1050px) {
      display: none;
   }
`;

export const StyledImageWrapper = styled.figure`
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: -1;
   background-image: url(${backgroundUrl});
   // background: rgba(10, 0, 255, 0.7);
   background-size: cover;
   clip-path: circle(55% at 13% 50%);
`;

export const StyledCardsContainer = styled.div`
   flex: 1;
   position: relative;
   padding: 5% 0;
   display: flex;
   align-items: center;
   justify-content: space-around;
   flex-direction: column;
   z-index: 200;
   .service-card-icon {
      position: absolute;
      font-size: 25vh;
      z-index: 100;
      color: rgba(10, 0, 255, 0.7);
      bottom: 0;
      top: 0;
      right: -10%;
      margin: auto;
   }
   #service-card-1 {
      &:after {
         background-image: url(${serviceCardBgc1});
      }
   }
   #service-card-2 {
      &:after {
         background-image: url(${serviceCardBgc2});
      }
   }
   #service-card-3 {
      &:after {
         background-image: url(${serviceCardBgc3});
      }
   }
`;
export const StyledFeatureCard = styled.div`
   box-shadow: 4px 4px 35px 0px rgba(51, 51, 51, 1);
   background: white;
   border-radius: 6px;
   width: 60%;
   // height: 20%;
   padding: 10px 0;
   overflow: hidden;
   display: flex;
   position: relative;
   cursor: pointer;
   margin-left: 25%;
   &:after {
      content: '';
      width: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      margin: auto;
      background-size: cover;
      position: absolute;
   }
   &:before {
      content: '';
      z-index: 1;
      width: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      margin: auto;
      background: white;
      position: absolute;
      transition: 0.5s ease;
   }
   &:hover:before {
      opacity: 0.7;
   }

   &:nth-child(odd) {
      margin-left: 40%;
   }

   ${({ isActive }) =>
      isActive
         ? `
   &:before{
      opacity:0.7;
   }
         `
         : null}
   // MOBILE
   @media (max-width: 1050px) {
      height: 25%;
   }
`;

export const StyledFeatureNumberWrapper = styled.div`
   display: flex;
   justify-content: center;
   flex: 1;
   > h2 {
      transition: 0.5s ease;
      z-index: 100;
      margin: 0;
      font-size: 10vh;
   }
   // MOBILE
   @media (max-width: 1050px) {
      > h2 {
         font-size: 7vh;
      }
   }
`;
export const StyledFeatureTextWrapper = styled.div`
   flex: 2;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-right: 20px;
   > h4 {
      transition: 0.5s ease;
      z-index: 100;
      margin: 0;
      font-size: 1.5vh;
      padding: 0 5% 0 0;
      text-align: justify;
      color: black;
      position: relative;
      padding-bottom: 10px;
   }
   > h3 {
      font-size: 3.2vh;
      z-index: 100;
      color: black !important;
      position: relative;
      // border-bottom: 1px solid ${({ theme }) => theme.colors.purple.light};
      &:before {
         content: '';
         z-index: 1000 !important;
         width: 95%;
         height: 100%;
         left: 0;
         bottom: 0;
         top: 0;
         // right: 0;
         margin: auto;
         border-bottom: 1px solid ${({ theme }) => theme.colors.purple.light};
         position: absolute;
      }
   }
   // MOBILE
   @media (max-width: 1050px) {
      > h4 {
         font-size: 2vh;
      }
   }
`;
