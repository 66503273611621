import React, { useEffect } from 'react';
import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { StyledProcessWrapper } from './HomePageContent.css.js';

import { StepOne, StepTwo, StepThree, StepFive, StepSix } from './ProcessSteps';

import PlatformMobileSection from './PlatformSection/PlatformSection.js';
import StartProcessForm from './StartProcessForm/StartProcessForm.js';

import AboutCompany from 'views-components/AboutCompany/AboutCompany.js';
import ReviewsSection from 'views-components/ReviewsSection/ReviewsSection.js';
import ReviewsMenu from 'views-components/ReviewsMenu/ReviewsMenu.js';
import { useTranslation } from 'react-i18next';
import { stepsCardsAnimations } from 'animations/homePageAnimations.js';

gsap.registerPlugin(ScrollTrigger);

const HomePageContent = () => {
   const { t } = useTranslation();

   useEffect(() => {
      stepsCardsAnimations();
      gsap.to('.process-container', {
         scrollTrigger: {
            trigger: '.process-container',
            scrub: true,
            start: 'top bottom',
            end: 'top top',
         },
         y: '-30vh',
         ease: 'easeInOut',
      });
      // ScrollTrigger.create({
      //    trigger: '#platform-section-wrapper',
      //    start: 'top bottom',
      //    end: 999999,
      //    onUpdate: () => scrollScreen(),
      // });
   }, []);
   return (
      <>
         <ReviewsMenu />
         <StyledProcessWrapper className='process-container'>
            <AboutCompany />
            <ReviewsSection />
            <div>
               <h2
                  dangerouslySetInnerHTML={{
                     __html: t(
                        window.innerWidth > 1050
                           ? 'homepage.process.header'
                           : 'homepage.process.header-mobile',
                        {
                           interpolation: { escapeValue: false },
                        }
                     ),
                  }}
               ></h2>
            </div>
            <StepOne />
            <StepTwo />
            <StepThree />
            <StepFive />
            <StepSix />
            <PlatformMobileSection />
            <StartProcessForm />
         </StyledProcessWrapper>
      </>
   );
};

export default HomePageContent;
