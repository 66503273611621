import React, { useEffect, useState } from 'react';

import { formatCurrency } from 'assets/theme/formatCurrency.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Accordion from 'components/Accordion/Accordion.js';
import Table from 'components/Table/Table.js';

import style from 'assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(style);

const MortgageMonthsTable = ({
   years,
   amountBorrow,
   interastRate,
   arrayInterestCharge,
}) => {
   const { t, i18n } = useTranslation();

   const classes = useStyles();

   const [tableLines, setTableLines] = useState();
   const [tableYears, setTableYears] = useState([]);

   const CalculatePaymentMonth = () => {
      var p = amountBorrow;
      var r = (interastRate / 12) * 0.01;
      var t = years * 12;

      var a = p * r * (1 + r) ** t;
      var b = (1 + r) ** t - 1;

      var paymentMonth = a / b;

      const month = t;
      var startingBalance = parseInt(p);
      var endingBalance = p;

      var interestCharge;
      var repayment;
      var allEndingBalanceData = [];
      var allInterestCharge = [];
      var allPrincipal = [];
      var allMortgageMonth = [];
      var tablelinesArray = [];

      for (var i = 1; i < month + 1; i++) {
         interestCharge = r * startingBalance;
         endingBalance = startingBalance + interestCharge - paymentMonth;
         repayment = paymentMonth - interestCharge;

         if (i % 12 === 0) {
            allEndingBalanceData.push(endingBalance);
            allInterestCharge.push(interestCharge);
            allPrincipal.push(paymentMonth - interestCharge);
            allMortgageMonth.push(paymentMonth);
         }
         tablelinesArray.push({
            initialCapitalBallance: startingBalance,
            fullInstolment: paymentMonth.toFixed(2),
            leftToBeRepaid: endingBalance.toFixed(2),
            interestRepayment: interestCharge.toFixed(2),
            repayment: repayment.toFixed(2),
         });
         startingBalance = endingBalance;
      }

      setTableLines(tablelinesArray);
   };

   const divideIntoYears = () => {
      if (tableLines !== undefined) {
         const results = [];

         while (tableLines.length) {
            results.push(tableLines.splice(0, 12));
         }

         setTableYears(results);
      } else return [];
   };

   useEffect(() => {
      CalculatePaymentMonth();
   }, [years, amountBorrow, interastRate]);

   useEffect(() => {
      divideIntoYears();
   }, [tableLines]);

   return (
      <div>
         {tableYears.lenght === 0 ? (
            'Put your own mortgage information...'
         ) : (
            <Accordion
               active={0}
               collapses={tableYears.map((year, index) => {
                  const numberOfYear = index + 1;

                  const monthlyCells = year.map((month, index) => {
                     const {
                        initialCapitalBallance,
                        fullInstolment,
                        leftToBeRepaid,
                        interestRepayment,
                        repayment,
                     } = month;

                     return [
                        index + 1,
                        formatCurrency(
                           initialCapitalBallance.toFixed(2),
                           i18n.language
                        ),
                        formatCurrency(interestRepayment, i18n.language),
                        formatCurrency(repayment, i18n.language),
                        formatCurrency(fullInstolment, i18n.language),
                        formatCurrency(leftToBeRepaid, i18n.language),
                     ];
                  });

                  return {
                     title:
                        t('mortgage-calculator.table.title') +
                        ' ' +
                        numberOfYear,
                     content: (
                        <Table
                           striped
                           tableHead={[
                              t('mortgage-calculator.table.table-title1'),
                              t('mortgage-calculator.table.table-title2'),
                              t('mortgage-calculator.table.table-title3'),
                              t('mortgage-calculator.table.table-title4'),
                              t('mortgage-calculator.table.table-title5'),
                              t('mortgage-calculator.table.table-title6'),
                           ]}
                           tableData={monthlyCells}
                           customCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight,
                           ]}
                           customClassesForCells={[0, 4, 5]}
                           customHeadCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight,
                           ]}
                           customHeadClassesForCells={[0, 4, 5]}
                        />
                     ),
                  };
               })}
            />
         )}
      </div>
   );
};

export default MortgageMonthsTable;
