import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledReviewsBox } from './ReviewsSection.css.js';
import {
   StyledCustomerAvatarContainer2,
   StyledCustomerReviewText2,
   StyledReviewContainer,
} from './ReviewsSection2.css.js';

// material
import { makeStyles } from '@material-ui/core/styles';

import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// material icons
import StarIcon from '@material-ui/icons/Star';

import customerStyle from '../../assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';

const karolCustomerAvatar = '/img/k-customer.webp';
const agataCustomerAvatar = '/img/a-customer.png.webp';
const ignacyCustomerAvatar = '/img/i-customer.webp';
const przemysławCustomerAvatar = '/img/p-customer.webp';
const aniaCustomerAvatar = '/img/a-customer.webp';
const sylwiaCustomerAvatar = '/img/s-customer.webp';
const esteraCustomerAvatar = '/img/e-customer.webp';
const sylwia2CustomerAvatar = '/img/s2-customer.webp';
const jerzyCustomerAvatar = '/img/j-customer.webp';

const useStyles = makeStyles(customerStyle);

const ReviewsComponentPC = () => {
   const { t } = useTranslation();

   const classes = useStyles();
   return (
      <>
         <StyledReviewsBox id='reviews-box-1' className='reviews-box'>
            <GridContainer>
               <GridItem lg={6} md={6} xs={12}>
                  <Card profile style={{ maxWidth: '300px' }}>
                     <CardHeader
                        image
                        style={{
                           height: '100px',
                           left: '0',
                           borderRadius: '50%',
                           overflow: 'hidden',
                        }}
                     >
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              width='100'
                              height='100px'
                              style={{
                                 borderRadius: '50%',
                                 width: '100px',
                              }}
                              src={agataCustomerAvatar}
                              alt={`${t(
                                 'homepage.reviews.image-alt-text'
                              )} Agata Niedzwiedzka`}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: { agataCustomerAvatar },
                              opacity: '1',
                           }}
                        />
                     </CardHeader>
                     <CardBody>
                        <span>Agata Niedzwiedzka</span>
                        <p
                           className={`${classes.cardCategory} ${classes.cardDescription}`}
                        >
                           Z czystym sumieniem polecam. Pan Jakub zaproponował
                           rozwiązanie, które pozwoliło mi na zakup wymarzonego
                           mieszkania. Z cierpliwością tłumaczył i odpowiadał na
                           wszystkie pytania. Widać, że zna się na swoim fachu i
                           zależy mu żeby znaleźć rozwiązanie dla klienta. Na
                           dodatek to sympatyczny człowiek. Bardzo przyjemna i
                           efektywna współpraca. Dziękuję Panie Jakubie!
                        </p>
                        <div className='rating-container'>
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </GridItem>
               <GridItem lg={6} md={6} xs={12}>
                  <Card
                     profile
                     style={{ maxWidth: '300px', marginTop: '100px' }}
                  >
                     <CardHeader
                        image
                        style={{
                           height: '100px',
                           left: '0',
                           borderRadius: '50%',
                           overflow: 'hidden',
                        }}
                     >
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              width='100'
                              height='100px'
                              style={{
                                 borderRadius: '50%',
                                 width: '100px',
                              }}
                              src={karolCustomerAvatar}
                              alt={`${t('homepage.reviews.image-alt-text')} 
                         Karol Kochan`}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: { karolCustomerAvatar },
                              opacity: '1',
                           }}
                        />
                     </CardHeader>
                     <CardBody
                        style={{
                           padding: '5px !important',
                        }}
                     >
                        <span>Karol Kochan</span>
                        <p
                           className={`${classes.cardCategory} ${classes.cardDescription}`}
                        >
                           Pełen profesjonalizm. Jakub Ossowski który prowadził
                           sprawę mojego kredytu mieszkaniowego na początek
                           przedstawił mi zasady działania kredytów, wyjaśnił
                           jakich produktów banki używają do zarobienia na nas
                           przy zaciąganiu kredytu po czym wspólnie ze mną
                           zawęził ofertę banków która najlepiej pasuje do moich
                           założeń co do sposobu spłaty kredytu. Na koniec
                           złożył wnioski kredytowe do banków i po zdecydowaniu
                           się na jedną z ofert skontaktował mnie bezpośrednio z
                           przedstawicielem bankowym w celu finalizacji
                           transakcji. Perfekcyjna obsługa, 100% polecam.
                        </p>
                        <div className='rating-container'>
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </GridItem>
               <GridItem lg={6} md={6} xs={12}>
                  <Card
                     profile
                     style={{ maxWidth: '300px', marginLeft: '50px' }}
                  >
                     <CardHeader
                        image
                        style={{
                           height: '100px',
                           borderRadius: '50%',
                           left: '0',
                           overflow: 'hidden',
                        }}
                     >
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              width='100'
                              height='100px'
                              style={{
                                 borderRadius: '50%',
                                 width: '100px',
                              }}
                              width='100px'
                              height='100px'
                              src={ignacyCustomerAvatar}
                              alt={`${t('homepage.reviews.image-alt-text')} 
                           Ignacy Janiszewski`}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: { ignacyCustomerAvatar },
                              opacity: '1',
                           }}
                        />
                     </CardHeader>
                     <CardBody>
                        <span>Ignacy Janiszewski</span>
                        <p
                           className={`${classes.cardCategory} ${classes.cardDescription}`}
                        >
                           Bardzo polecam, profesjonalnie wsparcie kredytowe.
                           Przyjazna atmosfera i konkretnie, a cały proces
                           bardzo szybki.
                        </p>
                        <div className='rating-container'>
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </GridItem>
            </GridContainer>
         </StyledReviewsBox>
         <StyledReviewsBox id='reviews-box-2' className='reviews-box'>
            <GridContainer>
               <GridItem lg={6} md={6} xs={12}>
                  <Card
                     profile
                     style={{ maxWidth: '300px', arginTop: '100px' }}
                  >
                     <CardHeader
                        image
                        style={{
                           height: '100px',
                           borderRadius: '50%',
                           overflow: 'hidden',
                        }}
                     >
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              width='100'
                              height='100px'
                              style={{
                                 borderRadius: '50%',
                                 width: '100px',
                              }}
                              src={sylwiaCustomerAvatar}
                              alt={`${t('homepage.reviews.image-alt-text')} 
                           Sylwia Taźbirek`}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: { sylwiaCustomerAvatar },
                              opacity: '1',
                           }}
                        />
                     </CardHeader>
                     <CardBody>
                        <span>Sylwia Taźbirek</span>
                        <p
                           className={`${classes.cardCategory} ${classes.cardDescription}`}
                        >
                           Ogromnie polecam współpracę z Kubą - w dwóch słowach
                           to: magik kredytu! Kuba już na pierwszym spotkaniu
                           wytłumaczy i powie wszystko o kredycie, potrafi
                           dobrze doradzić - ale nie narzuca ani nie faworyzuje
                           żadnego banku, to nasza decyzja na kogo się
                           decydujemy, a po tym możemy liczyć na to, że zrobi co
                           w jego mocy by warunki jakie nam bank zaproponuje
                           były jak najlepsze - bo przecież na tym nam
                           najbardziej zależy 🙂 Serdecznie polecam, pełen
                           profesjonalizm!
                        </p>
                        <div className='rating-container'>
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </GridItem>
               <GridItem lg={6} md={6} xs={12}>
                  <Card profile style={{ maxWidth: '300px' }}>
                     <CardHeader
                        image
                        style={{
                           height: '100px',
                           borderRadius: '50%',
                           overflow: 'hidden',
                        }}
                     >
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              width='100'
                              height='100px'
                              style={{
                                 borderRadius: '50%',
                                 width: '100px',
                              }}
                              src={przemysławCustomerAvatar}
                              alt={`${t('homepage.reviews.image-alt-text')} 
                         Przemysław Walotek`}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: { przemysławCustomerAvatar },
                              opacity: '1',
                           }}
                        />
                     </CardHeader>
                     <CardBody>
                        <span>Przemysław Walotek</span>
                        <p
                           className={`${classes.cardCategory} ${classes.cardDescription}`}
                        >
                           Wzorowe, rzetelne podejście do klienta, kontakt
                           utrzymywany na każdym etapie współpracy, świetnie
                           wytłumaczony proces kredytowy, bez porównania do
                           poprzednich doświadczeń. Wzór!
                        </p>
                        <div className='rating-container'>
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </GridItem>
               <GridItem lg={6} md={6} xs={12}></GridItem>
               <GridItem lg={6} md={6} xs={12}>
                  <Card profile style={{ maxWidth: '300px' }}>
                     <CardHeader
                        image
                        style={{
                           height: '100px',
                           borderRadius: '50%',
                           overflow: 'hidden',
                        }}
                     >
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              width='100'
                              height='100px'
                              style={{
                                 borderRadius: '50%',
                                 width: '100px',
                              }}
                              src={aniaCustomerAvatar}
                              alt={`${t('homepage.reviews.image-alt-text')} 
                           Ania M`}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: { aniaCustomerAvatar },
                              opacity: '1',
                           }}
                        />
                     </CardHeader>
                     <CardBody>
                        <span>Ania M</span>
                        <p
                           className={`${classes.cardCategory} ${classes.cardDescription}`}
                        >
                           Serdecznie polecam jesli ktos potrzebuje szybkiego i
                           sprawnego dzialania w sprawie kredytu :) super
                           kontakt i zalatwienie sprawy, oby kazda firma
                           funkcjonowala w ten sposob
                        </p>
                        <div className='rating-container'>
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </GridItem>
            </GridContainer>
         </StyledReviewsBox>

         <StyledReviewsBox id='reviews-box-3' className='reviews-box'>
            <GridContainer>
               <GridItem lg={6} md={6} xs={12}>
                  <Card profile style={{ maxWidth: '300px' }}>
                     <CardHeader
                        image
                        style={{
                           height: '100px',
                           borderRadius: '50%',
                           overflow: 'hidden',
                        }}
                     >
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              width='100'
                              height='100px'
                              style={{
                                 borderRadius: '50%',
                                 width: '100px',
                              }}
                              src={jerzyCustomerAvatar}
                              alt={`${t('homepage.reviews.image-alt-text')} 
                           Jerzy Krypel`}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: { jerzyCustomerAvatar },
                              opacity: '1',
                           }}
                        />
                     </CardHeader>
                     <CardBody>
                        <span>Jerzy Krypel</span>
                        <p
                           className={`${classes.cardCategory} ${classes.cardDescription}`}
                        >
                           Bardzo rzeczowa, szybka i profesjonalna obsługa.
                           Polecam!
                        </p>
                        <div className='rating-container'>
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </GridItem>
               <GridItem lg={6} md={6} xs={12}>
                  <Card profile style={{ maxWidth: '300px' }}>
                     <CardHeader
                        image
                        style={{
                           height: '100px',
                           borderRadius: '50%',
                           overflow: 'hidden',
                        }}
                     >
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              width='100'
                              height='100px'
                              style={{
                                 borderRadius: '50%',
                                 width: '100px',
                                 height: '100px',
                              }}
                              src={sylwia2CustomerAvatar}
                              alt={`${t('homepage.reviews.image-alt-text')} 
                           Sylwia Wójcik`}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: { sylwia2CustomerAvatar },
                              opacity: '1',
                           }}
                        />
                     </CardHeader>
                     <CardBody>
                        <span>Sylwia Wojcik</span>
                        <p
                           className={`${classes.cardCategory} ${classes.cardDescription}`}
                        >
                           Decyzje o zakupie mieszkania podjęłam dosyć
                           spontanicznie. W przeszłości często negatywnie
                           komentowałam posiadanie kredytu do emerytury, jednak
                           zmieniłam zdanie po tym jak już prawie wszyscy w
                           gronie moich znajomych zdecydowali się posiadać je na
                           własność. Przed podjęciem ostatecznej decyzji
                           postanowiłam skonsultować swoje możliwości z doradcą
                           kredytowym, przez przypadek trafiając na nazwisko
                           Pana Jakuba Ossowskiego, który zrobił na mnie bardzo
                           pozytywne pierwsze wrażenie. W trakcie godzinnej
                           rozmowy otrzymałam potężną dawkę wiedzy o meandrach
                           świata kredytów hipotecznych. Z całego serca polecam
                           tę firmę!
                        </p>
                        <div className='rating-container'>
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </GridItem>
               <GridItem lg={6} md={6} xs={12}>
                  <Card profile style={{ maxWidth: '300px' }}>
                     <CardHeader
                        image
                        style={{
                           height: '100px',
                           borderRadius: '50%',
                           overflow: 'hidden',
                        }}
                     >
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              width='100'
                              height='100px'
                              style={{
                                 borderRadius: '50%',
                                 width: '100px',
                              }}
                              src={esteraCustomerAvatar}
                              alt={`${t('homepage.reviews.image-alt-text')} 
                           Estera Wojtunik`}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: { esteraCustomerAvatar },
                              opacity: '1',
                           }}
                        />
                     </CardHeader>
                     <CardBody>
                        <span>Estera Wojtunik</span>
                        <p
                           className={`${classes.cardCategory} ${classes.cardDescription}`}
                        >
                           Bardzo profesjonalne podejście do klienta, szybka
                           obsługa, miła atmosfera. Z całego serca polecam.
                        </p>
                        <div className='rating-container'>
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                           <StarIcon
                              style={{
                                 color: 'gold',
                              }}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </GridItem>
            </GridContainer>
         </StyledReviewsBox>
      </>
   );
};

export default ReviewsComponentPC;
