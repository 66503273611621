import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
   StyledPlatformMobileSectionWrapper,
   PlatformSectionHeader,
   StyledCardsContainer,
   StyledTextCard,
   StyledImageCard,
   StyledBigCardLaptop,
   StyledTextHeader,
   StyledMacWrapper,
   StyledMacContainer,
} from './PlatformSection.css.js';

import { platformSectionAnimations } from './platformSectionAnimations.js';

// const PlatformPageScreenUrl = '/img/platform-mobile-all-page.png';
const IphoneMackupUrl = '/img/platform-progess-mobile.png';
const PlatformLaptop = '/img/mac-withss-after-firtscall.png';

const PlatformMobileSection = () => {
   const { t } = useTranslation();

   const laptopCardVersion = () => {
      const width = window.innerWidth;
      if (width > 1050) {
         return (
            <StyledBigCardLaptop id='laptop-card'>
               <StyledTextHeader>
                  <h1
                     dangerouslySetInnerHTML={{
                        __html: t('homepage.finseka-platform.card-2-title', {
                           interpolation: { escapeValue: false },
                        }),
                     }}
                  ></h1>
                  <h2
                     id='text-card-2'
                     dangerouslySetInnerHTML={{
                        __html: t('homepage.finseka-platform.card-2-text', {
                           interpolation: { escapeValue: false },
                        }),
                     }}
                  ></h2>
               </StyledTextHeader>
               <StyledMacContainer></StyledMacContainer>
            </StyledBigCardLaptop>
         );
      } else {
         return (
            <StyledCardsContainer>
               <StyledTextCard>
                  <h2
                     style={{
                        textAlign: 'center',
                     }}
                  >
                     Duża <span>oszczędność czasu</span>
                  </h2>
                  <div>
                     <p
                        dangerouslySetInnerHTML={{
                           __html: t('homepage.finseka-platform.card-2-text', {
                              interpolation: { escapeValue: false },
                           }),
                        }}
                     ></p>
                  </div>
               </StyledTextCard>
               <StyledImageCard id='image-card-1'>
                  <img
                     loading='lazy'
                     src={PlatformLaptop}
                     width='200%'
                     height='auto'
                     alt='mackup'
                  />
               </StyledImageCard>
            </StyledCardsContainer>
         );
      }
   };

   useEffect(() => {
      platformSectionAnimations();
   });
   return (
      <StyledPlatformMobileSectionWrapper id='platform-section-wrapper'>
         <PlatformSectionHeader>
            <h3
               dangerouslySetInnerHTML={{
                  __html: t(
                     window.innerWidth > 1050
                        ? 'homepage.finseka-platform.title'
                        : 'homepage.finseka-platform.title-mobile',
                     {
                        interpolation: { escapeValue: false },
                     }
                  ),
               }}
            ></h3>
         </PlatformSectionHeader>
         <StyledCardsContainer id='cards-container-1'>
            <StyledTextCard id='text-card-1'>
               <h2
                  dangerouslySetInnerHTML={{
                     __html: t('homepage.finseka-platform.card-1-title', {
                        interpolation: { escapeValue: false },
                     }),
                  }}
               ></h2>
               <div>
                  <p
                     dangerouslySetInnerHTML={{
                        __html: t('homepage.finseka-platform.card-1-text', {
                           interpolation: { escapeValue: false },
                        }),
                     }}
                  ></p>
               </div>
            </StyledTextCard>
            <StyledImageCard
               id='image-card-1'
               // style={{ backgroundImage: `url(${imageWrapperBackgroundUrl})` }}
            >
               <img
                  src={IphoneMackupUrl}
                  loading='lazy'
                  id='iphone-mackup'
                  width='auto'
                  height='auto'
                  alt='mackup'
               />
            </StyledImageCard>
         </StyledCardsContainer>
         {laptopCardVersion()}
         <StyledCardsContainer
            id='cards-container-3'
            style={{ flexDirection: 'row-reverse' }}
         >
            <StyledTextCard
               id='text-card-3'
               style={{
                  background: 'none',
                  marginRight: 0,
                  marginLeft: window.innerWidth > 1050 ? '20px' : '0px',
               }}
            >
               <h2
                  style={{ textAlign: 'center' }}
                  dangerouslySetInnerHTML={{
                     __html: t('homepage.finseka-platform.card-3-title', {
                        interpolation: { escapeValue: false },
                     }),
                  }}
               ></h2>
               <div>
                  <p
                     dangerouslySetInnerHTML={{
                        __html: t('homepage.finseka-platform.card-3-text', {
                           interpolation: { escapeValue: false },
                        }),
                     }}
                  ></p>
               </div>
            </StyledTextCard>
            <StyledImageCard
               id='image-card-2'
               bigger={true}
               style={{
                  // backgroundImage: `url(${imageWrapperBackgroundUrl2})`,
                  marginRight: '20px',
                  marginLeft: 0,
               }}
            >
               {/* <img src={IphoneMackupUrl} id='iphone-mackup' alt='mackup' /> */}
               <img
                  loading='lazy'
                  src='/img/woman-tablet.png'
                  id='woman-tablet'
                  alt='Man holding a tablet'
                  height='100%'
                  width='auto'
               />
            </StyledImageCard>
         </StyledCardsContainer>
      </StyledPlatformMobileSectionWrapper>
   );
};

export default PlatformMobileSection;
