import React from 'react';
import { useTranslation } from 'react-i18next';

import {
   StyledMobileVersionWrapper,
   StyledPlatformTutorialCard,
   StyledTextContainer,
   StyledImageContainer,
   StyledNotebookFigureMobile,
} from './PlatformPageMobileVersion.css.js';

import { ReactComponent as ChatIllustration } from './chat-illustration.svg';

const platformLoginImageUrl = '/img/mac-withss-login-page.png';
const platformWelcomeUrl = '/img/mac-withss-welcome-page.png';
const platformFileManager = '/img/mac-withss-file-manager.png';

const PlatformPageMobileSection = () => {
   const { t } = useTranslation();
   return (
      <StyledMobileVersionWrapper>
         <StyledPlatformTutorialCard>
            <StyledTextContainer id='first-card-text'>
               <h2
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-1-title'),
                  }}
               ></h2>
               <p
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-1-p'),
                  }}
               ></p>
            </StyledTextContainer>
            <StyledImageContainer>
               <img
                  id='notebook-screen-login'
                  alt={'Ekran logowania platformy Finseka.'}
                  src={platformLoginImageUrl}
               />
            </StyledImageContainer>
         </StyledPlatformTutorialCard>
         <StyledPlatformTutorialCard>
            <StyledTextContainer id='second-card-text'>
               <h2
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-2-title'),
                  }}
               ></h2>
               <p
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-2-p'),
                  }}
               ></p>
            </StyledTextContainer>
            <StyledImageContainer>
               <img
                  id='notebook-screen-login'
                  alt={'Ekran powitalny platformy.'}
                  src={platformWelcomeUrl}
               />
            </StyledImageContainer>
         </StyledPlatformTutorialCard>
         <StyledPlatformTutorialCard>
            <StyledTextContainer id='third-card-text'>
               <h2
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-3-title'),
                  }}
               ></h2>
               <p
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-3-p'),
                  }}
               ></p>
            </StyledTextContainer>
            <StyledImageContainer>
               <img
                  id='notebook-screen-login'
                  alt={'Menadżer plików platformy Finseka.'}
                  src={platformFileManager}
               />
            </StyledImageContainer>
         </StyledPlatformTutorialCard>

         <StyledPlatformTutorialCard
            style={{
               minHeight: '100vh ',

               marginBottom: '20vh',
            }}
         >
            <StyledTextContainer
               id='first-card-text'
               style={{
                  marginBottom: '5vh',
               }}
            >
               <h2
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-4-title'),
                  }}
               ></h2>
               <p
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-4-p'),
                  }}
               ></p>
            </StyledTextContainer>
            <StyledImageContainer>
               <img
                  id='notebook-screen-login'
                  alt={'Menadżer plików platformy Finseka.'}
                  src={platformFileManager}
               />
            </StyledImageContainer>
         </StyledPlatformTutorialCard>
      </StyledMobileVersionWrapper>
   );
};

export default PlatformPageMobileSection;
