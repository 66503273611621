import React, { Suspense } from 'react';
import MetaTags from 'react-meta-tags';

import { ThemeProvider } from 'styled-components';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import theme from 'assets/theme/theme';
import 'assets/scss/material-kit-pro-react.scss?v=1.10.0';
import 'assets/css/global-styles.css';

import { NavBar } from 'views-components';
import {
   HomePage,
   AboutPage,
   PlatformPage,
   ContactPage,
   BlogPage,
   MortgageCalculator,
   RankingOffers,
   RedirectToGoogleReviews,
} from 'views';
import { useTranslation } from 'react-i18next';
import { PrivacyPolicyPage } from 'views/index.js';

var hist = createBrowserHistory();
const App = () => {
   const { t } = useTranslation();
   return (
      <Router history={hist}>
         <MetaTags>
            <meta name='description' content={t('meta.description')} />
         </MetaTags>
         <ThemeProvider theme={theme}>
            <NavBar />
            <Switch>
               <Route path={t('pages.path.item1')} component={AboutPage} />
               <Route path={t('pages.path.item3')} component={ContactPage} />
               <Route path={t('pages.path.item4')} component={PlatformPage} />
               <Route
                  path={t('pages.path.item2')}
                  component={MortgageCalculator}
               />
               <Route
                  path='/polityka-prywatnosci'
                  component={PrivacyPolicyPage}
               />
               <Route path='/privacy-policy' component={PrivacyPolicyPage} />
               <Route path={'/reviews'} component={RedirectToGoogleReviews} />
               <Route path={'/opinie'} component={RedirectToGoogleReviews} />
               {/* <Route path='/blog' component={BlogPage} /> */}
               {/* <Route path='/ranking' component={RankingOffers} /> */}
               <Route path='/' component={HomePage} />
            </Switch>
            <Suspense fallback={'...'}>
               <MessengerCustomerChat
                  pageId='717055025743540'
                  appId='561443878503071'
               />
            </Suspense>
         </ThemeProvider>
      </Router>
   );
};

export default App;
