const theme = {
   colors: {
      blue: {
         normal: '#270091',
         light: '#2947FF',
      },
      purple: {
         normal: '#8100FF',
         light: '#FF00CF',
      },
      black: {
         normal: '#14004A',
      },
   },
};

export default theme;
