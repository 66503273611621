import styled from 'styled-components';
const macImageUrl = '/img/macbook-new.png';

export const StyledPlatformSectionWrapper = styled.section`
   width: 100%;
   height: 200vh;
   display: flex;
   align-items: flex-start;
   flex-direction: column;
   padding-left: 100px;
   padding-right: 50%;
   background: white;

   @media (max-width: 1450px) {
      padding-right: 40%;
   }
   > h3 {
      font-size: 6vh;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }

   > p {
      > b {
         color: ${({ theme }) => theme.colors.purple.light};
      }
      font-size: 4vh;
      line-height: 4vh;
   }

   /////////////////
   &:nth-child(even) {
      padding-right: 100px;
      padding-left: 50%;
   }
   // &:nth-child(3) {
   //    width: 100vw;
   //    height: 200vh;
   //    display: flex;
   //    align-items: flex-start;
   //    flex-direction: column !important;
   //    padding: 0 50px;
   //    > h3 {
   //       font-size: 6vh;
   //       > span {
   //          color: ${({ theme }) => theme.colors.purple.light};
   //       }
   //    }
   //    > p {
   //       font-weight: 300;
   //       > b {
   //          color: ${({ theme }) => theme.colors.purple.light};
   //       }
   //       font-size: 4vh;
   //       width: 35%;
   //       // padding: 0 50px;
   //       line-height: 4vh;
   //    }
   // }
`;

////////////////////////////////////////////////////////////// CARDS
export const StyledPlatformSectionAsCards = styled.section`
   background: white;
   width: 100%;
   border-radius: 6px;
   transform: translateY(5vh);
   // height: 300vh;
   display: flex;
   flex-direction: column;
   > div {
      padding: 2% 5%;
      display: flex;
      // flex-direction:column;
      // height: 100vh;
   }
   // mobile
   @media (max-width: 1050px) {
      > div {
         flex-direction: column !important;
         height: auto;
      }
   }
`;

export const StyledLeftBox = styled.div`
   flex: 1.1;
   // border: 1px solid #3c4858;
   border-radius: 6px;
   margin-right: 20px;
   display: flex;
   flex-direction: column;
   overflow: hidden;
   position: relative;
   > h3 {
      text-align: start;
      font-size: 6vh;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   > p {
      font-size: 4vh;
      line-height: 4vh;
      // padding: 5%;
      text-align: start;
   }
   // mobile
   @media (max-width: 1050px) {
      > h3 {
         font-size: 4vh !important;
      }
      > p {
         font-size: 2.5vh !important;
      }
   }
   > img {
      position: absolute;
      right: 0;
      left: 0;
      bottom: -30%;
      margin: auto;
      width: 130%;
   }
   // mobile
   @media (max-width: 1050px) {
      margin-bottom: 20px;
      margin-right: 0px;
      > img {
         position: relative !important;
      }
   }
`;
export const StyledRightBox = styled.div`
   > h3 {
      text-align: center;
      font-size: 4vh;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   flex: 1.8;
   // border: 1px solid #3c4858;
   border-radius: 6px;
   display: flex;
   flex-direction: column;
   overflow: hidden;
   > p {
      font-size: 2.5vh;
      line-height: 2.7vh;
      padding: 5%;
      text-align: center;
   }
   @media (max-width: 1050px) {
      > img {
         max-height: 50vh;
      }
   }
`;
export const StyledNotebookFigureCard = styled.figure`
   width: 1330px;
   height: 790px;
   background-image: url(${macImageUrl});
   background-size: 80% 100%;
   background-position: center;
   background-repeat: no-repeat;
   position: relative;
   transform: scale(1.5) translateX(50px) translateY(60px);
   // mobile
   @media (max-width: 1050px) {
      background-size: 100% 100%;
      transform: scale(1.2) translateX(-350px) translateY(100px);
      max-height: 75vh;
   }
   > img {
      position: absolute;
      width: 50%;
      height: 68%;
      left: 0;
      right: 0;
      top: 15%;
      margin: auto;
   }
`;

export const StyledClosingCard = styled.div`
   width: 100%;
   height: auto !important;
   display: flex;
   flex-direction: column;
   > h3 {
      text-align: center;
      font-size: 6vh;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   > p {
      font-size: 4vh;
      line-height: 4.5vh;
      padding: 0 5% 5vh 5%;
      text-align: center;
   }
   // mobile
   @media (max-width: 1050px) {
      > h3 {
         font-size: 4vh;
      }
      > p {
         font-size: 2.5vh;
         line-height: 2.7vh;
      }
   }
`;

export const StyledBottomImageWrapper = styled.figure`
   width: 100%;
   height: 60vh;
   display: flex;
   justify-content: center;
   > svg {
      height: 100%;
   }
`;
