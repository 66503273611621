import styled from 'styled-components';

const backgroundUrl = '/img/service-background-img.jpg';
const serviceCardBgc1 = '/img/service-background-1.jpg';
const serviceCardBgc2 = '/img/service-background-2.jpg';
const serviceCardBgc3 = '/img/service-background-3.jpg';

export const StyledServicesContainer = styled.section`
   width: 100%;
   position: relative;
   flex-direction: column;
   // mobile
   @media (max-width: 1050px) {
      flex-direction: column;
   }
`;

export const StyledCardsContainer = styled.div`
   background-image: url(${backgroundUrl});
   background-size: cover;
   background-repeat: no-repeat;
   flex: 1;
   position: relative;
   padding: 5% 1% 5% 40%;
   // height: 100vh;
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-direction: column;
   z-index: 200;
   .service-card-icon {
      position: absolute;
      font-size: 25vh;
      z-index: 100;
      color: rgba(10, 0, 255, 0.7);
      bottom: 0;
      top: 0;
      right: -10%;
      margin: auto;
   }

   // MOBILE
   @media (max-width: 1050px) {
      padding: 5% 0%;
   }
`;
export const StyledServiceCard = styled.div`
   box-shadow: 4px 4px 35px 0px rgba(51, 51, 51, 1);
   background: white;
   border-radius: 6px;
   width: 550px;
   height: 200px;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   margin-bottom: 10%;
   > div span {
      font-size: 10vh;
      // color: ${({ theme }) => theme.colors.blue.normal};
   }
   &:last-child {
      margin-bottom: 0;
   }
   &:nth-child(odd) {
      margin-left: 15%;
   }
   &:nth-child(even) {
      margin-left: 25%;
   }
   // MOBILE
   @media (max-width: 1050px) {
      > div span {
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
         margin: auto;
         font-size: 20vh;
         opacity: 0.1;
      }
      height: 30%;
      width: 90%;
      &:nth-child(odd) {
         margin-left: 0%;
      }
      &:nth-child(even) {
         margin-left: 0%;
      }
   }
`;

export const StyledServiceNumberWrapper = styled.div`
   display: flex;
   justify-content: center;

   flex: 1;
   > h2 {
      transition: 0.5s ease;
      z-index: 100;
      margin: 0;
      font-size: 10vh;
   }
   // MOBILE
   @media (max-width: 1050px) {
      flex: 0.2;
   }
`;
export const StyledServiceTextWrapper = styled.div`
   flex: 2;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-right: 20px;
   > h4 {
      transition: 0.5s ease;
      z-index: 100;
      margin: 0;
      font-size: 1.5vh;
      padding: 0 5% 0 0;
      text-align: justify;
      color: black;
      position: relative;
      padding-bottom: 10px;
   }
   > h3 {
      font-size: 3.2vh;
      z-index: 100;
      color: black !important;
      position: relative;
      // border-bottom: 1px solid ${({ theme }) => theme.colors.purple.light};
      &:before {
         content: '';
         z-index: 1000 !important;
         width: 95%;
         height: 100%;
         left: 0;
         bottom: 0;
         top: 0;
         // right: 0;
         margin: auto;
         border-bottom: 1px solid ${({ theme }) => theme.colors.purple.light};
         position: absolute;
      }
   }
   // MOBILE
   @media (max-width: 1050px) {
      > h4 {
         font-size: 2vh;
      }
   }
`;

export const StyledServicesContent = styled.article`
   flex: 1;
   display: flex;
   flex-direction: column;
   padding-top: 5%;
   margin-bottom: 5vh;
   .services-title {
      text-align: center;
      position: relative;
      flex: 1;
      font-size: 6vh;
      // transform: translateX(-5%);
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }

   > div {
      padding: 5% 10% 20% 10%;
      height: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      > h3 {
         font-weight: 700;
         font-size: 3.5vh;
         position: relative;
         &:after {
            content: '';
            z-index: 100;
            width: 25%;
            left: 0;
            right: 0;
            bottom: -5%;
            margin: auto;
            border-bottom: 4px solid ${({ theme }) => theme.colors.purple.light};
            position: absolute;
         }
      }
      > p {
         // text-align: center;
         font-size: 2.5vh;
      }
   }
   // MOBILE
   @media (max-width: 1050px) {
      > .services-title {
         text-align: center;
         visibility: visible;
         font-size: 2.4rem;
      }
   }
`;
