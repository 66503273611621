import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger);
CSSPlugin.defaultForce3D = false;

export const stepTwoAnimations = () => {
   // title
   gsap.from('#step-two-title', {
      scrollTrigger: {
         trigger: '#step-two-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   // image elements
   gsap.from('#box_1', {
      scrollTrigger: {
         trigger: '#step-two-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '-25vh',
      y: '-10vh',
      opacity: 0,

      ease: 'easeInOut',
   });
   gsap.from('#box_2', {
      scrollTrigger: {
         trigger: '#step-two-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      y: '-10vh',
      opacity: 0,

      ease: 'easeInOut',
   });
   gsap.from('#box_3', {
      scrollTrigger: {
         trigger: '#step-two-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '25vh',
      y: '-10vh',
      opacity: 0,

      ease: 'easeInOut',
   });
   // all box elements
   // gsap.from(
   //    `#box_1 > #Vector_66, #box_1 > #Vector_67, #box_1 > #Vector_69,
   //    #box_2 > #Vector_60,#box_2 > #Vector_61,#box_2 > #Vector_62,
   //    #box_3 > #Vector_72,#box_3 > #Vector_73,#box_3 > #Vector_74`,
   //    {
   //       scrollTrigger: {
   //          trigger: '#step-two-container',
   //          scrub: true,
   //          start: '50% bottom',
   //          end: '70% bottom',
   //       },
   //       scaleX: 0,
   //       ease: 'easeInOut',
   //    }
   // );
   gsap.from('#women_2', {
      scrollTrigger: {
         trigger: '#step-two-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '10vh',

      ease: 'easeInOut',
   });
   gsap.from('#women_1', {
      scrollTrigger: {
         trigger: '#step-two-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '-10vh',

      ease: 'easeInOut',
   });

   // text
   gsap.from('#step-two-card', {
      scrollTrigger: {
         trigger: '#step-two-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      y: '-15vh',
      opacity: 0,
      ease: 'easeInOut',
   });
};
