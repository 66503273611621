import styled from 'styled-components';
import { renderToStaticMarkup } from 'react-dom/server';
import { createElement } from 'react';

const arrowCursorUrl = '/img/custom-cursor-arrow-small.png';
const arrowCursorActiveUrl = '/img/custom-cursor-arrow-active.png';

export const StyledReviewsSectionContainer = styled.section`
   margin-top: 5vh;
   width: 100%;
   padding: 10% 0;
   display: flex;
   min-height: 100vh;
   flex-direction: column;
   transform: translateZ(0);
   margin-bottom: 10vh;
   position: relative;
   &:before {
      border-bottom: 3px solid #3c4858;
      position: Absolute;
      bottom: -1%;
      right: 0;
      left: 0;
      margin: auto;
      content: '';
      width: 60vw;
      height: 100%;
   }
   @media (max-width: 1050px) {
      padding: 5% 5%;
   }
`;
export const StyledReviewsSectionHeader = styled.header`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 5vh;
   padding-left: 50vh;
   > h2 {
      font-size: 6vh;
      text-align: center;
      width: 100%;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   > #google-name {
      font-size: 3vh;
      text-align: center;
      width: 100%;
   }
   > #google-rating {
      position: relative;
      display: flex;
      font-size: 6vh;
      justify-content: center;
      align-items: center;
      width: 100%;
   }
   > div #google-rating-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
         font-size: 6vh;
         color: gold;
      }
   }
   > #powered-by-google-image {
      width: 360px;
   }
   .google-star:nth-child(1) {
      position: absolute;
      left: 0;
   }
   .google-star:nth-child(2) {
      left: 6vh;
      position: absolute;
   }
   .google-star:nth-child(3) {
      left: 12vh;
      position: absolute;
   }
   .google-star:nth-child(4) {
      left: 18vh;
      position: absolute;
   }
   .google-star:nth-child(5) {
      left: 24vh;
      position: absolute;
   }
   @media (max-width: 1050px) {
      padding-left: 0%;
   }
`;
export const StyledFeaturesWrapper = styled.div`
   flex: 1;
`;

export const StyledFeatureBox = styled.div`
   transition: 0.5s ease;
   background: white;
   margin-bottom: 5px;
   padding-left: 5%;
   padding-right: 5%;
   border: 1px solid #b0b0b0;
   border-radius: 6px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   position: relative;
   > h3 {
      font-weight: 500;
      margin-top: 0px;
      margin-bottom: 0px;
      color: ${({ theme }) => theme.colors.blue.normal};
      font-size: 4vh;
   }
   > p {
      position: relative;
      color: black;
      font-size: 2vh;
      font-weight: 400;
   }
   > span {
      left: 1%;
      z-index: -10;
      color: #ededed;
      font-size: 12vh;
      position: absolute;
   }
   > .feature-button-mobile {
      display: none;
      position: absolute;
      bottom: -5%;
      right: -2%;
      transition: 0.2s ease;
      > span svg {
         width: 50px;
         height: 50px;
      }
      ${({ isActive }) =>
         isActive
            ? `
         transform:rotate(180deg);
      `
            : null}
   }
   @media (max-width: 1050px) {
      > .feature-button-mobile {
         display: flex;
      }
   }
   @media (min-width: 1050px) {
      cursor: url(${arrowCursorUrl}), auto;
      ${({ isActive }) =>
         isActive
            ? `
           
         cursor:url(${arrowCursorActiveUrl}), auto;
      `
            : null}
   }
`;
export const StyledReviewContainerMobile = styled.div`
   padding: 5% 2%;
   display: flex;
   align-items: center;
   flex-direction: column;
   width: 100%;
   position: relative;
`;

export const StyledReviewsContainer = styled.div`
   width: 100%;

   height: auto;
   margin-top: -50vh;
   opacity: 0;
   display: none;
   visibility: hidden;
   z-index: -10;
   transition: 0.7s ease;
   ${({ isActive }) =>
      isActive
         ? `
      margin-top:0;
      // transform: translateY(0);
      opacity:1;
      display:block;
      visibility:visible;
      margin-bottom: 5vh;
   `
         : null}
`;
export const StyledReviewsBox = styled.div`
   min-height: 100vh;
   padding: 5% 0;
   position: relative;
   > h5 {
      position: absolute;
      top: 5%;
      right: 5%;
      font-size: 30vh;
      // transform: rotate(20deg) !important;
      z-index: -1;
      color: ${({ theme }) => theme.colors.blue.normal};
      opacity: 0.1 !important;
      font-weight: 700;
   }
   > div:nth-child(even) {
      margin-left: 20%;
   }
   > div:nth-child(3) {
      margin-left: 15%;
   }
`;
// export const StyledReviewWrapper = styled.div`
//    display: flex;
//    padding: 1%;
//    width: 65%;
//    background: white;
//    min-height: 10vh;
//    position: relative;
//    border-radius: 6px;
//    border: 1px solid ${({ theme }) => theme.colors.blue.normal};
//    box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.75);
// `;

export const StyledCustomerAvatarContainer = styled.div`
   height: 100%;
   flex: 2.2;
   // background: white;
   z-index: 100;
   position: relative;
   &:after {
      position: absolute;
      width: 100%;
      height: 80%;
      top: 0;
      bottom: 0;
      z-index: 100;
      margin: auto;
      border-right: 2px solid ${({ theme }) => theme.colors.purple.light};
      content: '';
   }
   > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      width: 100%;
      height: 100%;
      > img {
         border-radius: 100%;
         width: 120px;
         height: 120px;
      }
      > span {
         width: 100%;
         height: 25%;
         text-align: center;
         font-size: 1.2vh;
         padding-top: 10px;
         font-weight: 600;
         text-transform: uppercase;
         color: black;
      }
   }
`;
export const StyledCustomerReviewText = styled.div`
   flex: 5;
   height: 100%;
   display: flex;
   align-items: flex-start;
   justify-content: center;
   flex-direction: column;
   > p {
      padding: 0% 2%;
      text-align: start;
      color: black;
      position: relative;
      z-index: 100;
      font-size: 1.5vh;

      > svg:nth-child(1) {
         z-index: -10;
         position: absolute;
         top: -30%;
         left: 0;
         margin: auto;
         font-size: 15vh;
         color: rgba(80, 0, 105, 0.7);
      }
      > svg:nth-child(2) {
         z-index: -10;
         position: absolute;
         bottom: -30%;
         right: 0;
         margin: auto;
         font-size: 15vh;
         color: rgba(80, 0, 105, 0.7);
      }
   }
   > .rating-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
         font-size: 2vh;
         color: gold;
      }
   }
   > span {
      text-align: left;
      font-size: 1.5vh;
      padding: 5px 2%;
   }
`;

///////// VERSION 2

export const StyledReviewWrapper = styled.div`
   width: 100%;
   box-shadow: 0px 0px 16px 0px rgba(120, 120, 120, 1);
   border-radius: 6px;
   position: relative;
   z-index: -10;
   padding-bottom: 85%;
   will-change: transform, opacity;
   > .fa-quote-right {
      z-index: 0;
      position: absolute;
      top: 3%;
      left: 3%;
      font-size: 4vh;
      color: ${({ theme }) => theme.colors.purple.light};
   }
`;
export const StyledReviewTextWrapper = styled.div`
   width: 100%;
   // height: 60%;
   padding: 15% 5% 0 5%;

   > p {
      font-size: 1.5vh;
      text-align: center;
      color: grey;
   }

   @media (max-width: 1000px) {
      > p {
         font-size: 2.2vh;
      }
   }
`;

export const StyledConsumerWrapper = styled.div`
   position: absolute;
   bottom: 0;
   width: 100%;
   height: 40%;
   display: flex;
   flex-direction: column;
   align-items: center;

   &:after {
      content: '';
      background: ${({ theme }) => theme.colors.blue.normal};
      height: 60%;
      width: 100%;
      bottom: 0;
      z-index: -10;
      border-top-left-radius: 60%;
      border-top-right-radius: 60%;
      position: absolute;
   }
   > .review-image-wrapper {
      width: 120px;
      height: 120px;
      overflow: hidden;
      border-radius: 50%;
      border: 4px solid white;
      > img {
         width: 100%;
      }
   }
   > .review-rating {
      padding-top: 10px;
      > p {
         color: white;
         font-size: 17px;
         font-weight: 500;
         text-align: center;
      }
      > svg {
         color: #ffdf00;
      }
   }
`;
