import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18next/i18n.js';

import { LoadingIndicator } from 'views-components';

import App from './App.js';

ReactDOM.render(
   <Suspense fallback={<LoadingIndicator width='100vw' height='100vh' />}>
      <App />
   </Suspense>,
   document.getElementById('root')
);
