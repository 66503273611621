import styled, { keyframes } from 'styled-components';

export const underlineAnimation = keyframes`
   0%{
      width:0%;
   }
   100%{
      width:100%;
   }
`;

const backgroundUrl = '/img/ranking-page-background.jpg';

export const StyledRankingHeader = styled.div`
   width: 100vw;
   height: 100vh;
   background-image: url(${backgroundUrl});
   background-size: cover;
   display: flex;
   flex-direction: column;
   padding-right: 12%;
   align-items: center;
   justify-content: center;
   > h2,
   p {
      z-index: 1000;
   }

   > p {
      color: white;
      line-height: 3vh;
      font-size: 2.5vh;
      padding: 0 30%;
      text-align: center;
   }
   > .ranking-page-title {
      text-align: center;
      z-index: 200;
      color: white;
      font-size: 5vh;
      position: relative;
      &:after {
         animation: ${underlineAnimation} 1s ease forwards;
         animation-delay: 1s;
         content: '';
         z-index: 100;
         width: 0%;
         left: 0;
         bottom: 0%;
         // right: 0;
         margin: auto;
         border-bottom: 3px solid ${({ theme }) => theme.colors.purple.light};
         position: absolute;
      }
   }

   // mobile
   @media (max-width: 1050px) {
      padding-right: 0%;
      > p {
         padding: 0;
      }
      > h4 {
         padding: 0 5%;
      }
   }
`;

export const StyledCalculatorsWrapper = styled.div`
   > h2 {
      font-size: 6vh;
      text-align: center;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   > p {
      font-size: 4vh;
      line-height: 4.5vh;
      text-align: center;
   }
   padding: 5vh 10% 5vh 10%;
   background: white;
   width: 95vw;
   box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%),
      0 8px 10px -5px rgb(0 0 0 / 20%);
   margin-left: 2.5%;
   border-radius: 6px;
   margin-top: -9vh;
   transform: translateY(5vh);
   background: white !important;
   // mobile
   @media (max-width: 1050px) {
      padding: 0 5%;
   }
`;
