import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { stepFiveAnimations } from './stepFiveAnimations.js';

import {
   StyledStepWrapper,
   StyledStepCard,
   StyledCardHeader,
   StyledCardBody,
} from '../ProcessSteps.css.js';
import { ReactComponent as StepFiveImage } from '../../ProcessStepsImages/step_five.svg';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';

const StepFive = () => {
   const { t } = useTranslation();

   useEffect(() => {
      stepFiveAnimations();
   });
   return (
      <>
         <h4 id='step-five-title' className='step-title'>
            {t('homepage.process.step-text')} 04
         </h4>
         <StyledStepWrapper
            style={{
               flexDirection:
                  window.innerWidth > 1050 ? 'row-reverse' : 'column',
            }}
            id='step-five-container'
         >
            <div className='image-container'>
               <StepFiveImage
                  style={{
                     transform: 'translateY(15%) translateX(-2%)',
                     height: '100%',
                     width: '120%',
                  }}
                  id='step-five-image'
               />
            </div>
            <div className='steps-card' id='step-five-card'>
               <StyledStepCard>
                  <StyledCardHeader className='steps-card-header'>
                     <h3>{t('homepage.process.step4-title')}</h3>
                  </StyledCardHeader>
                  <StyledCardBody className='text-container'>
                     <p
                        dangerouslySetInnerHTML={{
                           __html: t('homepage.process.step4-text', {
                              interpolation: { escapeValue: false },
                           }),
                        }}
                     ></p>
                  </StyledCardBody>
               </StyledStepCard>
            </div>
         </StyledStepWrapper>
      </>
   );
};

export default StepFive;
