import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// import { executiveTeamAnimations } from 'animations/aboutPageAnimations.js';

import GridContainer from '../../../../components/Grid/GridContainer.js';
import GridItem from '../../../../components/Grid/GridItem.js';
import Card from '../../../../components/Card/Card.js';
import CardHeader from '../../../../components/Card/CardHeader.js';
import CardAvatar from '../../../../components/Card/CardAvatar.js';
import CardBody from '../../../../components/Card/CardBody.js';
import CardFooter from '../../../../components/Card/CardFooter.js';
import Button from '../../../../components/CustomButtons/Button.js';
// icons
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';

import teamStyle from '../../../../assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';

const useStyles = makeStyles(teamStyle);

const msAvatarUrl = '/img/ms2-team.jpg';
const ojAvatarUrl = '/img/oj-team-big1.jpg';
const pzAvatarUrl = '/img/pz-team.jpg';

const mobileOrDesktopBreak = () => {
   const width = window.innerWidth;

   if (width > 1050) {
      return (
         <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
         </>
      );
   } else {
      return (
         <>
            <br />
            <br />
            <br />
            <br />
         </>
      );
   }
};

const ExecutiveTeam = () => {
   const classes = useStyles();
   const { t } = useTranslation();

   useEffect(() => {
      // executiveTeamAnimations();
   }, []);
   return (
      <>
         <GridContainer>
            <GridItem
               xs={12}
               sm={6}
               md={6}
               className={
                  classes.mlAuto +
                  ' ' +
                  classes.mrAuto +
                  ' ' +
                  classes.textCenter
               }
            >
               <div id='executive-team-title'>
                  <h2>{t('about.team.executive.title')}</h2>
                  <h5 className={classes.description}>
                     {t('about.team.executive.text')}
                  </h5>
               </div>
            </GridItem>
         </GridContainer>
         {/* <br />
         <br />
         <br />
         <br />
         <br />
         <br />
         <br /> */}
         {mobileOrDesktopBreak()}
         <GridContainer className='executive-wrapper'>
            <GridItem xs={12} sm={4} md={4} className='executive-team-person'>
               <Card
                  style={{
                     height: '800px',
                  }}
                  profile
               >
                  <CardHeader
                     image
                     style={{
                        overflow: 'hidden',
                     }}
                  >
                     <img
                        src={ojAvatarUrl}
                        style={{
                           width: '',
                        }}
                        alt='Jakub Ossowski - Finseka doradca kredytowy Kraków'
                        title='Jakub Ossowski - Finseka doradca kredytowy Kraków'
                     />
                     <div
                        className={classes.cardTitleAbsolute}
                        style={{
                           padding: '0 .1rem ',
                           position: 'absolute',
                           bottom: '-.6rem',
                           right: '1px',
                           fontWeight: '700',
                        }}
                     ></div>

                     <div
                        className={classes.coloredShadow}
                        style={{
                           backgroundImage: `url(${ojAvatarUrl})`,
                           opacity: '1',
                        }}
                     />
                  </CardHeader>
                  <CardBody>
                     <h4 className={classes.cardTitle}>dr Jakub Ossowski</h4>

                     <h6 className={classes.cardCategory}>
                        {t('about.team.executive.oj-title')}
                     </h6>
                     <p
                        className={classes.cardDescription}
                        style={{
                           fontSize: '16px',
                        }}
                     >
                        {t('about.team.executive.oj')}
                     </p>
                  </CardBody>
                  <CardFooter profile className={classes.justifyContent}>
                     <Button justIcon round color='linkedin'>
                        <a
                           style={{
                              color: 'white',
                           }}
                           href='https://www.linkedin.com/in/jakub-ossowski-0487b0129/'
                           target='_blank'
                        >
                           <i className='fab fa-linkedin-in' />
                        </a>
                     </Button>
                  </CardFooter>
               </Card>
            </GridItem>

            {/* <GridItem xs={12} sm={6} md={1}></GridItem> */}
            <GridItem xs={12} sm={4} md={4} className='executive-team-person'>
               <Card
                  style={{
                     height: '800px',
                  }}
                  profile
               >
                  <CardHeader image>
                     <img
                        src={msAvatarUrl}
                        alt='Miltiadis Saratzidis - Finseka doradca kredytowy Kraków'
                        title='Miltiadis Saratzidis - Finseka doradca kredytowy Kraków'
                     />
                  </CardHeader>
                  <CardBody>
                     <h4 className={classes.cardTitle}>Miltiadis Saratzidis</h4>

                     <h6 className={classes.cardCategory}>
                        {t('about.team.executive.ms-title')}
                     </h6>
                     <p
                        className={classes.cardDescription}
                        style={{
                           fontSize: '16px',
                        }}
                     >
                        {t('about.team.executive.ms')}
                     </p>
                  </CardBody>
                  <CardFooter profile className={classes.justifyContent}>
                     <Button justIcon round color='linkedin'>
                        <a
                           style={{
                              color: 'white',
                           }}
                           href='https://www.linkedin.com/in/miltiadis-saratzidis-b1a396129/'
                           target='_blank'
                        >
                           <i className='fab fa-linkedin-in' />
                        </a>
                     </Button>
                  </CardFooter>
               </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} className='executive-team-person'>
               <Card
                  style={{
                     height: '800px',
                  }}
                  profile
               >
                  <CardHeader image>
                     <img
                        src={pzAvatarUrl}
                        alt='Paweł Zyguła - Finseka doradca kredytowy Kraków'
                        title='Paweł Zyguła - Finseka doradca kredytowy Kraków'
                     />
                  </CardHeader>
                  <CardBody>
                     <h4 className={classes.cardTitle}>Paweł Zyguła</h4>

                     <h6 className={classes.cardCategory}>
                        {t('about.team.executive.pz-title')}
                     </h6>
                     <p
                        className={classes.cardDescription}
                        style={{
                           fontSize: '16px',
                        }}
                     >
                        {t('about.team.executive.pz')}
                     </p>
                  </CardBody>
                  <CardFooter profile className={classes.justifyContent}>
                     <Button justIcon round color='linkedin'>
                        <a
                           style={{
                              color: 'white',
                           }}
                           href='https://www.linkedin.com/in/pawel-zygula-2b27bb68/'
                           target='_blank'
                        >
                           <i className='fab fa-linkedin-in' />
                        </a>
                     </Button>
                  </CardFooter>
               </Card>
            </GridItem>

            {/* <GridItem xs={12} sm={6} md={1}></GridItem> */}
         </GridContainer>
      </>
   );
};

export default ExecutiveTeam;

// Pablo text - Paweł Zyguła | Dyrektor do spraw rozwoju
