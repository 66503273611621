import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form.js';
import InfoContainer from 'views-components/InfoContainer/InfoContainer.js';
import {
   StyledStartFormContainer,
   StyledTitle,
   StyledText,
   StyledFormWrapper,
   StyledPhoneWrapper,
} from './StartProcessForm.css.js';

import { formStepAnimations } from './FormAnimations.js';

const platformProgessMobileUrl = '/img/form-phone.png';
const PlatformBarMobileUrl = '/img/platform-mobile-bar-low.png';

const StartProcessForm = () => {
   const { t } = useTranslation();

   useEffect(() => {
      window.innerWidth > 1050 ? formStepAnimations() : null;
   });
   return (
      <StyledStartFormContainer id='form-section-container'>
         <StyledPhoneWrapper id='phone-wrapper'>
            <img
               src={platformProgessMobileUrl}
               id='iphone-mackup'
               alt='mackup'
            />
         </StyledPhoneWrapper>
         <StyledTitle
            id='form-section-title'
            dangerouslySetInnerHTML={{
               __html: t('homepage.contact-us.title', {
                  interpolation: { escapeValue: false },
               }),
            }}
         ></StyledTitle>
         <StyledText
            dangerouslySetInnerHTML={{
               __html: t('homepage.contact-us.text', {
                  interpolation: { escapeValue: false },
               }),
            }}
         ></StyledText>
         <StyledFormWrapper>
            <div id='form-left-element'>
               <h5
                  dangerouslySetInnerHTML={{
                     __html: t('homepage.contact-us.form-title', {
                        interpolation: { escapeValue: false },
                     }),
                  }}
               ></h5>
               <Form />
            </div>
            <div id='form-right-element'>
               <InfoContainer flexDirection='column' />
            </div>
         </StyledFormWrapper>
      </StyledStartFormContainer>
   );
};

export default StartProcessForm;
