import React from 'react';
import { Line } from 'react-chartjs-2';

const options = {
   legend: {
      labels: {
         fontSize: '30px',
      },
   },
   scales: {
      yAxes: [
         {
            ticks: {
               beginAtZero: true,
            },
         },
      ],
   },
};

function LineF(props) {
   console.log('Tibouktou', props.arrayEndingBalance);

   var labelsArray = [];
   props.arrayEndingBalance.forEach((pos, index) => {
      labelsArray.push(index);
   });

   const data = {
      labels: labelsArray,
      datasets: [
         {
            label: 'Balance',
            data: props.arrayEndingBalance,
            fill: false,
            backgroundColor: '#270091',
            borderColor: '#270091',
            tension: 0.4,
            pointRadius: 0,
         },
         // {
         //     label: '# Principal',
         //     data: props.arrayPrincipal,
         //     fill: false,
         //     backgroundColor: 'rgb(255, 39, 232)',
         //     borderColor: 'rgba(255, 39, 232, 0.6)',
         //     tension: 0.4,
         //     pointRadius: 0,
         // },
         //   {
         //     label: '# of Interest Payment',
         //     data: props.arrayInterestCharge,
         //     fill: false,
         //     backgroundColor: 'rgb(255, 99, 132)',
         //     borderColor: 'rgba(255, 99, 132, 0.2)',
         //     tension: 0.4,
         //     pointRadius: 0,
         //   },
         //   {
         //     label: '# of Votes',
         //     data: [12, 19, 3, 5, 2, 3],
         //     fill: false,
         //     backgroundColor: 'rgb(255, 99, 132)',
         //     borderColor: 'rgba(255, 99, 132, 0.2)',
         //     tension: 0.4,
         //   //   borderWidth: 2,
         //   //   fill: true,
         //   //   steppedLine: false,
         //     pointRadius: 0,
         //   },
      ],
   };

   return (
      <>
         <div className='header'>
            {/* <h3 className='title'>Bank Balance</h3> */}
         </div>
         <Line
            data={data}
            options={options}
            width={2000}
            height={window.innerWidth > 1050 ? 800 : 1700}
         />
      </>
   );
}

export default LineF;
