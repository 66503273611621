import styled, { keyframes } from 'styled-components';

const box1BackgroundUrl = '/img/box-1-background.jpg';
const box2BackgroundUrl = '/img/box-2-background.jpg';
const box3BackgroundUrl = '/img/box-3-background.webp';
const box4BackgroundUrl = '/img/box-4-background.jpg';
const box5BackgroundUrl = '/img/box-5-background.jpg';

const bubbleLevitationAnimation = keyframes`
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-10px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
`;

export const StyledAboutCompanyContainer = styled.section`
   width: 100%;
   display: flex;
   justify-content: center;
   flex-direction: column;
   position: relative;
   &:before {
      // border-bottom: 3px solid #3c4858;
      position: Absolute;
      bottom: -2%;
      right: 0;
      left: 0;
      margin: auto;
      content: '';
      width: 60vw;
      height: 100%;
   }
   > h2 {
      font-size: 6vh;
      text-align: center;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
      @media (max-width: 1050px) {
         font-size: 2.4rem;
      }
   }
   // border-bottom: 3px solid ;
`;

export const StyledTextWrapper = styled.div`
padding-top: 3%;
padding-left: 3%;
> p {
   font-size: 3vh;
   line-height: 3.5vh;
   position: relative;
   text-align: justify;
   z-index: 100;
   > span {
      color: ${({ theme }) => theme.colors.purple.light};
   }
`;

export const StyledAboutCompanyContentWrapper = styled.div`
   width: 100%;
   padding: 0 5%;
   // > div:first-child {
   //    flex: 1;
   //    padding-top: 3%;
   //    padding-left: 3%;
   //    > p {
   //       font-size: 3vh;
   //       line-height: 3.5vh;
   //       position: relative;
   //       text-align: justify;
   //       z-index: 100;
   //       > span {
   //          color: ${({ theme }) => theme.colors.purple.light};
   //       }
   //    }
   // }

   #about-company-text {
      padding-right: 5vw;
   }

   // grid bubbles styles
   #bubble-box-1 {
   }
   #bubble-box-2 {
   }
   #bubble-box-3 {
   }
   #bubble-box-4 {
   }
   #bubble-box-5 {
      @media (max-width: 1280px) {
         > div h5 {
            font-size: 30px !important;
         }
      }
   }
   @media (max-width: 1280px) {
      #empty-grid {
         display: none;
      }
   }
   .grid-bubble {
      border-radius: 6px;
      position: relative;
      width: 100%;
      height: 250px;
      padding-bottom: 15px;
      max-width: 100%;
      background-size: cover;
      opacity: 0.7;
      padding: 7% 5%;
      will-change: width, height, opacity;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      > div {
         display: flex;
         flex-direction: column;
         align-items: center;
         > h5 {
            z-index: 100;
            padding: 0;
            margin: 0;
            color: white;
         }
         > p {
            font-size: 20px;
            z-index: 100;
            text-align: center;
            color: white;
            z-index: 100;
            margin-top: -5%;
            position: relative;
            &:before {
               z-index: -10;
               content: '';
               width: 70%;
               height: 10%;
               left: 0;
               right: 0;
               bottom: -50%;
               margin: auto;
               border-bottom: 2px solid
                  ${({ theme }) => theme.colors.purple.light};
               position: absolute;
            }
         }
         > span {
            z-index: 100;
            display: none;
            font-weight: 300;
         }
         &::before {
            background: black;
            opacity: 0.4;
            border-radius: 6px;
            content: '';
            height: 100%;
            inset: 0px;
            margin: auto;
            position: absolute;
            width: 100%;
         }
      }
   }
   // MOBILE
   @media (max-width: 1050px) {
      flex-direction: column;
      // height: 150vh;
      > div:first-child {
         > p {
            text-align: center;
         }
         > p:before {
            border-bottom: 5px solid #8900b3;
            left: 0;
            height: 120%;
            background: 0;
            width: 50%;
         }
      }
   }
`;

export const StyledBubblesContainer = styled.div`
   flex: 1.5;
   padding-left: 5%;
   background: white;
   position: relative;
   height: 100vh;

   > .bubble {
      width: ${20 * 1.6}vh;
      height: ${20 * 1}vh;
      background-size:cover;
      opacity:0.7;
      padding:7% 5%;
      will-change: width, height, opacity;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      &:hover h5 {
         transform: translateY(-5px);
      }
      > h5 {
         
         z-index: 100;
         padding: 0;
         margin: 0;
         color: white;
      }
      > p {
         z-index: 100;
         text-align: center;
         // font-weight: 400;
         color: white;
         z-index: 100;
         margin-top: -5%;
         &:before {
            z-index: -10;
            content: '';
            width: 70%;
            height: 10%;
            left: 0;
            right: 0;
            bottom: -50%;
            margin: auto;
            border-bottom:2px solid ${({ theme }) => theme.colors.purple.light};
            position: absolute;
         }
      }
      > span {
         z-index: 100;
         display: none;
         font-weight:300;
      }
      > .bubble-icon {
         z-index: 100;
         transition: 1s ease;
         position: absolute;

         right: -10%;
         bottom: -15%;
      }
      border-radius: 6px;
      position: absolute;
      transition: 1s ease;
      &:before {
         background:black;
         opacity:0.4;
         border-radius: 6px;
         box-shadow: inset 0 20px 30px rgba(129, 0, 255.0.3);
         content: '';
         height: 100%;
         left: 0;
         right: 0;
         bottom: 0;
         top: 0;
         margin: auto;
         position: absolute;
         width: 100%;
      }
   }
   > .bubble-1 {
      top: 71%;
      left: 60%;
      // background-image:url(${box1BackgroundUrl});
      >h5{
         font-size:6vh !important;
      }
   }
   > .bubble-2 {
      background-image:url(${box2BackgroundUrl});
      top: 61%;
      left: -47%;
      >h5{
         font-size:6vh !important;
      }
   }
   > .bubble-3 {
      background-image:url(${box3BackgroundUrl});
      top: 5% ;
      left: 10% ; 
      margin: auto;
      >h5{
         font-size:6vh !important;
      }
   }
   > .bubble-4 {
      background-image:url(${box4BackgroundUrl});
      top: 40%;
      left: 66%;
      >h5{
         font-size:6vh !important;
      }
   }
   > .bubble-5 {
      background-image:url(${box5BackgroundUrl});
      top: 8%;
      left: 60%;
      > h5 {
         font-size: 3vh;
      }
      > p {
         font-size: 1.5vh;
      }
      > .bubble-icon {
         font-size: 18vh;
      }
   }

   // MOBILE
   @media (max-width: 1050px) {
      flex: 5;
    
      > .bubble{
         width: ${18 * 1.6}vh; 
         height: ${18 * 1}vh;
         > p {
            font-size: 1.8vh !important;
         }
      }
      > .bubble-1 {
         top: 0%;
         left: 5%;
      }
      > .bubble-2 {
         top: 65%;
         left: 15%;
         > h5 {
            font-size:3vh !important;
         }
      }
      > .bubble-3 {
         top: 0%;
         left: 55%;
      }
      > .bubble-4 {
         top: 50%;
         left: 55%;
         > h5 {
            font-size:5vh;
         }
      }
      > .bubble-5 {
         top: 45%;
         left: 1%;
         > h5 {
            font-size:2vh;
         }
   }
   @media (max-width: 600px) {
      flex: 5;
      > .bubble{
         width: ${14 * 1.6}vh; 
         height: ${14 * 1}vh;
      }
      > .bubble-1 {
         top: 5%;
         left: 1%;
      }
      > .bubble-2 {
         top: 60%;
         left: 5%;
         > h5 {
            font-size:3vh !important;
         }
      }
      > .bubble-3 {
         top: 0%;
         left: 55%;
      }
      > .bubble-4 {
         top: 50%;
         left: 55%;
         > h5 {
            font-size:5vh;
         }
      }
      > .bubble-5 {
         top: 45%;
         left: 1%;
         > h5 {
            font-size:2vh;
         }
   }
   
`;
