import styled from 'styled-components';

export const StyledOfficeSection = styled.section`
   width: 100%;
   padding: 5% 15% 10% 15%;
   > .office-section-grid-header {
      width: 100%;
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom:5vh;
      .office-title {
         text-align: center;
         position: relative;
         flex: 1;
         font-size: 6vh;

         > span {
            color: ${({ theme }) => theme.colors.purple.light};
         }
      }
    
   }
   > .office-section-grid-images {
      height: 70%;
      > div .office-section-image {
         cursor: pointer;
         width: 100%;
         max-height: 100%;
         border-radius: 6px;
         margin: 20px 0px;
         box-shadow: 0 5px 15px -8px rgb(0 0 0 / 24%),
            0 8px 10px -5px rgb(0 0 0 / 20%);
         &:hover {
         }
      }
   }
   // mobile
   @media (max-width: 1050px) {
      padding-top:10vh;
      .office-title {
         font-size:2.4rem !important;
         visibility: visible !important;
      }
      > .office-section-grid-header {
         > h2 {
            font-size:5vh;
         }
   }
`;
