import styled from "styled-components";

export const StyledPrivacyPolicyPageContainer = styled.div`
  width: 100vw;
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    width: 100vw !important;
  }
  > div .WordSection1 {
    width: 100vw !important;
  }
  @media (max-width: 600px) {
    > div .WordSection1 {
      padding: 0 5% 5% 5% !important;
    }
  }
`;
