import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {
   StyledReviewsSectionContainer,
   StyledReviewsSectionHeader,
   StyledFeaturesWrapper,
   StyledFeatureBox,
   StyledReviewsContainer,
   StyledReviewWrapper,
   StyledReviewTextWrapper,
   StyledConsumerWrapper,
   StyledCustomerAvatarContainer,
   StyledCustomerReviewText,
   StyledReviewsBox,
} from './ReviewsSection.css.js';

// reviews 2
import {
   StyledReviewContainer,
   StyledCustomerAvatarContainer2,
   StyledCustomerReviewText2,
   StyledSectionContainer,
   StyledFeatureCard,
   StyledFeatureNumberWrapper,
   StyledFeatureTextWrapper,
   StyledImageWrapper,
   StyledCardsContainer,
   StyledReviewsMenuContainer,
} from './ReviewsSection2.css.js';
import {
   reviewsSectionAnimations,
   reviewCardsAnimationOnMount,
   ReviewsCardAnimations,
} from 'animations/homePageAnimations.js';

// mobile and PC version of reviews
import ReviewsComponentMobile from './ReviewsComponentMobile.js';
import ReviewsComponentPC from './ReviewsComponentPC.js';
import { reviewStarsAnimations } from 'animations/homePageAnimations.js';

gsap.registerPlugin(ScrollTrigger);

const poweredByGoogleImageUrl = '/img/powered-by-google.png';

const ReviewsSection = () => {
   const { t } = useTranslation();
   const renderSectionComponent = () => {
      const width = window.innerWidth;

      if (width > 1050) {
         return <ReviewsComponentPC />;
      } else {
         return <ReviewsComponentMobile />;
      }
   };

   useEffect(() => {
      // ReviewsCardAnimations();
      // reviewStarsAnimations();
   }, []);
   return (
      <StyledReviewsSectionContainer id='reviews-section-container'>
         <StyledReviewsSectionHeader>
            <h2
               dangerouslySetInnerHTML={{
                  __html: t('homepage.reviews.title', {
                     interpolation: { escapeValue: false },
                  }),
               }}
            ></h2>
            <h3 id='google-name'>Finseka | Kredyty Hipoteczne Kraków</h3>
            <div id='google-rating'>
               5.0
               <div id='google-rating-container'>
                  <StarIcon className='google-star' />
                  <StarIcon className='google-star' />
                  <StarIcon className='google-star' />
                  <StarIcon className='google-star' />
                  <StarIcon className='google-star' />
                  <StarBorderIcon className='google-star-border' />
                  <StarBorderIcon className='google-star-border' />
                  <StarBorderIcon className='google-star-border' />
                  <StarBorderIcon className='google-star-border' />
                  <StarBorderIcon className='google-star-border' />
               </div>
            </div>
            <img
               src={poweredByGoogleImageUrl}
               id='powered-by-google-image'
               alt='powered by google'
               width='360px'
               height='auto'
            />
         </StyledReviewsSectionHeader>
         <StyledSectionContainer id='container-section'>
            {renderSectionComponent()}
         </StyledSectionContainer>
      </StyledReviewsSectionContainer>
   );
};

export default ReviewsSection;
