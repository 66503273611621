import React from 'react';
import { Line } from 'react-chartjs-2';

const options = {
   labels: {
      fontSize: '30px',
   },

   scales: {
      yAxes: [
         {
            ticks: {
               beginAtZero: true,
            },
         },
      ],
   },
};

// function LineF (props) {
export const LineF = React.memo(props => {
   // console.log('Tibouktou', props.arrayEndingBalance);

   var labelsArray = [];
   props.arrayEndingBalance.forEach((pos, index) => {
      labelsArray.push(index);
   });

   const data = {
      labels: labelsArray,
      datasets: [
         //   {
         //       label: '# Balance',
         //       data: props.arrayEndingBalance,
         //       fill: false,
         //       backgroundColor: 'rgb(255, 39, 232)',
         //       borderColor: 'rgba(255, 39, 232, 0.2)',
         //       tension: 0.4,
         //       pointRadius: 0,
         //   },
         {
            label: 'Principal (zł)',

            data: props.arrayPrincipal,
            fill: false,
            backgroundColor: 'rgb(100, 0, 232)',
            borderColor: 'rgba(0, 0, 232, 0.6)',
            tension: 0.4,
            pointRadius: 0,
         },
         {
            label: 'Interest Payment (zł)',
            data: props.arrayInterestCharge,
            fill: false,
            backgroundColor: 'rgb(100, 99, 132)',
            borderColor: 'rgba(100, 99, 132, 0.6)',
            tension: 0.4,
            pointRadius: 0,
         },
         {
            label: 'Monthly Payment (zł)',
            data: props.arrayMortgageMonth,
            fill: false,
            backgroundColor: 'rgb(200, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.9)',
            tension: 0.9,
            pointRadius: 0,
         },
         //   {
         //     label: '# of Votes',
         //     data: [12, 19, 3, 5, 2, 3],
         //     fill: false,
         //     backgroundColor: 'rgb(255, 99, 132)',
         //     borderColor: 'rgba(255, 99, 132, 0.2)',
         //     tension: 0.4,
         //   //   borderWidth: 2,
         //   //   fill: true,
         //   //   steppedLine: false,
         //     pointRadius: 0,
         //   },
      ],
   };

   return (
      <>
         <div className='header'>{/* <h1 className='title'>Chart</h1> */}</div>
         <Line
            data={data}
            options={options}
            width={900}
            height={window.innerWidth > 1050 ? '650px' : 2000}
         />
      </>
   );
});

export default LineF;
