import styled from 'styled-components';

const macImageUrl = '/img/mac-img.png';
const PlatformLaptop = '/img/mac-withss-after-firtscall.png';

const laptopBackgroundUrl = '/img/laptop-background.png';

export const StyledPlatformMobileSectionWrapper = styled.section`
   width: 100%;
   position: relative;
   padding: 5% 5% 0 5%;
   margin-bottom: 10vh;
   position: relative;
   &:before {
      border-bottom: 3px solid #3c4858;
      position: Absolute;
      bottom: -5%;
      right: 0;
      left: 0;
      margin: auto;
      content: '';
      width: 60vw;
      height: 100%;
   }
   > #platform-screen-all {
      position: absolute;
      width: 280px;
      right: 5%;
   }
   > #platform-section-text-wrapper {
      height: 75%;
      display: flex;
      flex-direction: column;
      padding-top:15vh;
      justify-content: space-between;
      z-index: 10000 !important;
      >div:nth-child(even){
         align-items:flex-end;
      }
      > div {
         display:flex;
         flex-direction:column;
         align-items:flex-start;
         > h4 {
            font-weight: 600;
            font-size: 2vh;
            margin-bottom: 2%;
            color:${({ theme }) => theme.colors.purple.light}
         }
         > p {
            font-weight: 600;
            font-size: 1.8vh;
            width:50%;
            >ul{
               // list-style:none;
               padding-left:20px;
               >li{
                  padding-bottom:10px;
               }
            }
         }
      }
   }
   // MOBILE
   @media (max-width: 1050px) {
      margin-bottom: 0vh !important;
      &:before {
      
         bottom: -1%;
       
      }
      // padding: 5% 0% 0 5%;
      > h3 {
         font-size: 5vh;
      }
      > p {
         padding-right: 0%;
         font-size: 20px;
      }
      > #platform-section-text-wrapper {
         
         > div {
           
            > p {
               padding-right:0;
            }
         }
         >div:nth-child(even){

         }
      > #platform-screen-all {
         right: -5%;
      }
   }
`;
export const PlatformSectionHeader = styled.header`
   margin-bottom: 10vh;
   > h3 {
      z-index: 1000;
      font-size: 5vh;
      text-align: center;
      line-height: 4vh;
      margin-bottom: 2%;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   > p {
      font-size: 24px;
      line-height: 1.33341;
      font-weight: 600;
      padding-right: 20%;
   }
   @media (max-width: 1050px) {
      margin-bottom: 0vh;
      > h3 {
         font-size: 2.4rem;
         line-height: 2.6rem;
      }
      > p {
         font-size: 20px;
         padding-right: 0%;
      }
   }
`;

export const StyledCardsContainer = styled.div`
   width: 100%;

   display: flex;
   margin-bottom: 50px;
   @media (max-width: 1050px) {
      height: 100vh;
      flex-direction: column !important;
   }
`;
export const StyledTextCard = styled.div`
   flex: 1;
   height: 80vh;
   border-radius: 6px;
   margin-right: 20px;
   border: 2px solid rgb(240, 240, 240);
   // background: rgb(39, 0, 145);
   // background: linear-gradient(
   //    45deg,
   //    rgba(39, 0, 145, 1) 10%,
   //    rgba(255, 0, 207, 1) 90%
   // );
   display: flex;
   padding: 15% 0;
   flex-direction: column;
   align-items: center;
   justify-content: space-around;
   @media (max-width: 1250px) {
      margin-right: 0px;
      margin-bottom: 50px;
      border-radius: 20px;
      padding-top: 2vh;
   }
   > h2 {
      font-size: 6vh;
      @media (max-width: 1050px) {
         font-size: 2.2rem;
      }
      font-weight: 300;
      position: relative;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   > div p {
      padding: 0 10%;
      text-align: center;
      // margin-top: -10%;
      line-height: 4.5vh;
      font-size: 4vh;
      @media (max-width: 1050px) {
         font-size: 1.5rem;
      }
      font-weight: 100;
      // color: black;
   }
`;
export const StyledBigCardLaptop = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   border-radius: 60px;
   border: 2px solid rgb(240, 240, 240);
   // background-image: url(${laptopBackgroundUrl});
   background-size: 100% 100%;
   margin-bottom: 50px;
   overflow: hidden;
   @media (max-width: 1050px) {
      border-radius: 20px;
   }
`;
export const StyledTextHeader = styled.div`
   padding-top: 5vh;
   margin-bottom: 50px;
   > h1 {
      font-size: 6vh;
      text-align: center;
      @media (max-width: 1050px) {
         font-size: 2.2rem;
      }
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   > h2 {
      font-size: 4vh;
      text-align: center !important;
      @media (max-width: 1050px) {
         font-size: 1.5rem;
      }
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
`;

export const StyledImageCard = styled.div`
   background-size: 100% 100%;
   overflow: hidden;
   border-radius: 6px;
   flex: 1;
   margin-left: 20px;
   height: 80vh;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 2px solid rgb(240, 240, 240);
   @media (max-width: 1050px) {
      max-height: 70vh;
      border-radius: 20px;
      margin: 0 !important;
   }
   > #iphone-mackup {
      z-index: 1000;
      min-width: 500px;
      min-height: 700px;
   }

   > figure {
      width: 25%;
      height: 50%;
      transform: translateX(-50px) translateY(-50px);
      border-radius: 40px;
      overflow: hidden;
      position: relative;
      > #screen-platform {
         width: 90%;
         height: 100%;
         position: absolute;
         left: 0;
         top: 10px;
         right: 0;
         margin: auto;
         z-index: 50;
      }
      > img {
         position: absolute;
      }
      @media (max-width: 1050px) {
         width: 600px;
         height: 800px;
         > #iphone-mackup {
            height: 100%;
            width: 100%;
         }
      }
      @media (max-width: 650px) {
         transform: scaleX(1.7);
      }
   }
   ${({ bigger }) =>
      bigger
         ? `
      >#iphone-mackup{
     
         transform:scale(1.7) translateY(15%) translateX(-10%);
         > #screen-platform {
            top:20px;
         }
      }
 
    
         `
         : null}
`;
export const StyledPhoneScreenContainer = styled.div`
   opacity: 1;
   width: 95vw;
   height: 100vh;
   z-index: 100;
   left: 0;
   right: 0;
   bottom: 0%;
   margin: auto;
   z-index: 10;
   > #iphone-mackup-bigger {
      position: absolute;
      bottom: 0;
      top: 0;
      right: 5%;
      margin: auto;
      height: 70%;
      width: 300px;
      z-index: 50;
   }
   > #image-wrapper {
      position: absolute;
      bottom: 0;
      top: 0;
      right: 5%;
      margin: auto;
      height: 68%;
      width: 295px;
      border-radius: 50px;
      overflow: hidden;
      > img {
         width: 100%;
      }
   }

   // MOBILE
   @media (max-width: 1050px) {
      > #iphone-mackup-bigger {
         bottom: -30%;
         top: auto;
         right: -5%;
         margin: auto;
         height: 70%;
         width: 300px;
      }
      > #image-wrapper {
         width: 295px;
         bottom: -29%;
         top: auto;
         right: -5%;
      }
   }
   @media (max-width: 960px) {
      > #iphone-mackup-bigger {
         width: 250px !important;
      }
      > #image-wrapper {
         width: 245px !important;
      }
   }
`;

export const StyledMacContainer = styled.figure`
   width: 1080px;
   height: 570px;
   // margin: 200px 0;
   background-image: url(${PlatformLaptop});
   background-size: 100% 175%;
   background-position: center;
   background-repeat: no-repeat;
   position: relative;
   left: 0%;
   top: 0;
   bottom: 0;
   margin: auto;
   z-index: 1000;

   @media (max-width: 1400px) {
      width: 950px;
      height: 440px;
   }
   @media (max-width: 1050px) {
      left: -50%;
      top: 20%;
      width: 980px;
      height: 470px;
   }
`;
