import React, { lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import {
   StyledHomePage,
   StyledMainSection,
   StyledMainSectionTextWrapper,
} from './HomePage.css.js';

const HomePageContent = lazy(() =>
   import('../../views-components/HomePageContent')
);

import { mainSectionAnimations } from '../../animations/homePageAnimations.js';

import Parallax from '../../components/Parallax/Parallax.js';
import CustomCursor from 'views-components/CustomCursor/CustomCursor.js';

const HomePage = () => {
   const { t } = useTranslation();

   useEffect(() => {
      document.title = t('pages.titles.item0');
      mainSectionAnimations();
      window.scrollTo({
         top: 0,
      });
      return () => {
         ScrollTrigger.getAll().forEach(t => {
            if (
               t.vars.id === 'navbar-trigger' ||
               t.vars.id === 'navbar-trigger-2'
            ) {
               return;
            } else {
               t.kill();
            }
         });
      };
   }, []);
   return (
      <>
         <CustomCursor />
         <StyledHomePage>
            <Parallax large filter='dark' className='parallax-home'>
               <StyledMainSection className='main-container'>
                  <StyledMainSectionTextWrapper>
                     <h1 className='landing-page-title'>
                        {t('homepage.header.title-text')}
                     </h1>

                     <p
                        className='landing-page-text'
                        dangerouslySetInnerHTML={{
                           __html: t(
                              window.innerWidth > 1050
                                 ? 'homepage.header.paragraph-text'
                                 : 'homepage.header.paragraph-text-mobile',
                              {
                                 interpolation: { escapeValue: false },
                              }
                           ),
                        }}
                     >
                        {/* {t('homepage.header.paragraph-text')} */}
                     </p>
                  </StyledMainSectionTextWrapper>
               </StyledMainSection>
            </Parallax>
            <HomePageContent />
         </StyledHomePage>
      </>
   );
};

export default HomePage;
