import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ScrollTrigger from 'gsap/ScrollTrigger';
import {
   mainSectionAnimations,
   tutorialAnimations,
   tutorialAnimationsMobile,
} from '../../animations/platformPageAnimations.js';

import {
   PlatformPageSection,
   PlatformPageSectionAsCards,
} from 'views-components/PlatformPageSections';
import {
   StyledNotebookFigure,
   StyledPlatformTutorialHeader,
} from './PlatformPage.css.js';

import Parallax from '../../components/Parallax/Parallax.js';
import { PlatformPageMobileVersion } from 'views-components';
import InfoContainer from 'views-components/InfoContainer/InfoContainer.js';
import { Footer } from 'views-components/index.js';

const platformLoginImgUrl = '/img/mac-withss-login-page.png';
const platformVideoUrl = '/video/platform-video1.mp4';

const wrapperStyle = {
   width: '100%',
   position: 'relative',
   // marginBottom: '40vh',
};

const DesktopVersion = () => {
   const { t } = useTranslation();
   return (
      <>
         <PlatformPageSection
            title={t('platform.text-1-title')}
            text={t('platform.text-1-p')}
            style={{}}
         />
         <PlatformPageSection
            title={t('platform.text-2-title')}
            text={t('platform.text-2-p')}
            // style={{
            //    justifyContent: 'flex-end',
            // }}
         />
         <PlatformPageSection
            title={t('platform.text-3-title')}
            text={t('platform.text-3-p')}
            style={{}}
         />
         <PlatformPageSection
            title={t('platform.text-4-title')}
            text={t('platform.text-4-p')}
         />
         <PlatformPageSectionAsCards />
         <StyledNotebookFigure id='mac-container'>
            <img
               id='macbook-screen'
               src={platformLoginImgUrl}
               alt={'Strona logowanie Platformy Finseka'}
               width={'100%'}
               height={'100%'}
            />
         </StyledNotebookFigure>
      </>
   );
};

const Platform = () => {
   const { t } = useTranslation();

   useEffect(() => {
      document.title = t('pages.titles.item3');
      const windowWidth = window.innerWidth;
      windowWidth > 1050 ? tutorialAnimations() : tutorialAnimationsMobile();
      mainSectionAnimations();
      return () => {
         ScrollTrigger.getAll().forEach(t => {
            if (
               t.vars.id === 'navbar-trigger' ||
               t.vars.id === 'navbar-trigger-2'
            ) {
               return;
            } else {
               t.kill();
            }
         });
      };
   }, []);
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <div style={wrapperStyle} id='platform-wrapper'>
         <Parallax large filter='dark' style={{ zIndex: 0, height: '100vh' }}>
            <StyledPlatformTutorialHeader>
               <h2 className='platform-page-title'>Platforma Finseka</h2>
               <p
                  dangerouslySetInnerHTML={{
                     __html: t('platform.header-text'),
                  }}
               ></p>
            </StyledPlatformTutorialHeader>
         </Parallax>
         <div
            style={{
               position: 'sticky',
               marginTop: '-1vh',

               zIndex: '10000',
               width: '95vw',
               marginLeft: '2.5%',
               background: 'white',
               borderRadius: '6px',

               paddingTop: window.innerWidth > 1050 ? '150vh' : '0vh',
               boxShadow: `0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%),
               0 8px 10px -5px rgb(0 0 0 / 20%)`,
            }}
         >
            {window.innerWidth > 1050 ? (
               <DesktopVersion />
            ) : (
               <>
                  <PlatformPageMobileVersion />
                  <PlatformPageSectionAsCards />
               </>
            )}
         </div>
         {/* <div
            style={{
               width: '100vw',
               height: window.innerWidth > 1050 ? '40vh' : '60vh',
               position: 'fixed',
               bottom: '0',
               margin: 'auto',
               // transform: 'translateY(100%)',
               background: '#270091',
               zIndex: -100,
            }}
         >
            <InfoContainer />
         </div> */}
         <Footer />
      </div>
   );
};

export default Platform;
