import styled from 'styled-components';

const macImageUrl = '/img/mac-img.png';
const cardBackground = '/img/platform-card-background.png';
const cardBackground2 = '/img/platform-card-background-2.png';

export const StyledMobileVersionWrapper = styled.section`
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 5vh;
   overflow-x: hidden;
`;

export const StyledPlatformTutorialCard = styled.div`
   width: 95%;
   min-height: 90vh;
   // background: white;
   border-radius: 6px;
   margin-bottom: 10vh;
   display: flex;
   flex-direction: column;
   &:nth-child(even) div img {
      margin-left: -200px;
   }
   &:nth-child(odd) div img {
      margin-left: 200px;
   }
   @media (max-width: 1050px) {
      margin-bottom: 0vh !important;
   }
`;
export const StyledTextContainer = styled.header`
   flex: 1;
   padding: 5px 5%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   > h2 {
      font-size: 4vh;
      line-height: 4.2vh;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   > p {
      font-size: 2.5vh;
   }
`;
export const StyledImageContainer = styled.div`
   flex: 1.2;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   > svg {
      width: 90%;
      height: 90%;
   }
   > img {
      width: 150%;
      height: 150%;
   }

   > #third-card-image {
      width: 350px;
      height: 350px;
      transform: scaleX(1.5);
      left: -16%;
      top: -10%;
      bottom: 0;
   }
`;
