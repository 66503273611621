export const formatCurrency = (value, language) => {
   const number = Number(value);

   switch (language) {
      case 'pl':
         return new Intl.NumberFormat('pl', {
            style: 'currency',
            currency: 'PLN',
         }).format(number);
      case 'en':
         return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'USD',
         }).format(number);

      default:
         break;
   }
};
