import React, { useEffect } from 'react';

import { stepOneAnimations } from './stepOneAnimations.js';

import {
   StyledStepWrapper,
   StyledStepCard,
   StyledCardHeader,
   StyledCardBody,
} from '../ProcessSteps.css.js';
// import StepOneImage from '../../../../../public/img/step_one.svg';
import { ReactComponent as StepOneImage } from '../../ProcessStepsImages/step_one.svg';
import Button from 'components/CustomButtons/Button.js';

//icons
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import { useTranslation } from 'react-i18next';

const StepOne = () => {
   const { t } = useTranslation();

   const handleScrollDown = () => {
      document
         .querySelector('.info-area-form')
         .scrollIntoView({ behavior: 'smooth' });
   };
   useEffect(() => {
      stepOneAnimations();
   });
   return (
      <>
         <h4 id='step-one-title' className='step-title'>
            {t('homepage.process.step-text')} 01
         </h4>
         <StyledStepWrapper id='step-one-container'>
            <div className='image-container'>
               <StepOneImage
                  style={{
                     transform: 'translateY(3%)',
                  }}
                  id='step-one-image'
               />
            </div>
            <div className='steps-card' id='step-one-card'>
               <StyledStepCard>
                  <StyledCardHeader className='steps-card-header'>
                     <h3>{t('homepage.process.step1-title')}</h3>
                  </StyledCardHeader>
                  <StyledCardBody className='text-container'>
                     <p
                        dangerouslySetInnerHTML={{
                           __html: t('homepage.process.step1-text', {
                              interpolation: { escapeValue: false },
                           }),
                        }}
                     ></p>
                     <Button
                        round
                        style={{
                           opacity: 0,
                        }}
                     >
                        <PhoneOutlinedIcon />
                        {t('homepage.process.step1-button')}
                     </Button>
                  </StyledCardBody>
                  <Button
                     round
                     className='step-one-button'
                     // onClick={handleScrollDown}
                  >
                     <PhoneOutlinedIcon />
                     <a href='tel:+48698235973'>
                        {t('homepage.process.step1-button')}
                     </a>
                  </Button>
               </StyledStepCard>
            </div>
         </StyledStepWrapper>
      </>
   );
};

export default StepOne;
