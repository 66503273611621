import styled from 'styled-components';

export const StyledCustomCursor = styled.div`
   width: 50px;
   height: 50px;
   border: 2px solid #fefefe;
   border-radius: 100%;
   position: fixed;
   transform: translate(-50%, -50%);
   pointer-events: none;
   z-index: 9999;
   transition: transform 0.15s ease-in-out;
   mix-blend-mode: difference;
   display: flex;
   align-items: center;
   justify-content: center;
   ${({ hoverInActiveBox }) =>
      hoverInActiveBox
         ? `
      transform:rotate(180deg);
   `
         : null}
   @media (max-width: 1050px) {
      display: none;
   }
`;
