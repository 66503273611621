import React from 'react';

import { StyledPlatformSectionWrapper } from './PlatformPageSection.css.js';

const PlatformPageSection = ({ title, text, style, textStyle }) => {
   return (
      <StyledPlatformSectionWrapper style={style}>
         {title ? (
            <h3
               dangerouslySetInnerHTML={{
                  __html: title,
               }}
            ></h3>
         ) : null}
         <p
            style={textStyle}
            dangerouslySetInnerHTML={{
               __html: text,
            }}
         ></p>
      </StyledPlatformSectionWrapper>
   );
};

export default PlatformPageSection;
