import styled from 'styled-components';

export const StyledBlogPageContainer = styled.main`
   width: 100vw;
   padding: 15vh 15% 0 15%;
`;

export const StyledBlogPageHeader = styled.header`
   width: 100%;
   height: 10vh;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   > #blog-menu-button {
      cursor: pointer;
      position: absolute;
      font-size: 4vh;
      color: ${({ theme }) => theme.colors.purple.light};
      right: 0;
   }
   > h1 {
      font-weight: 800;
      color: ${({ theme }) => theme.colors.purple.light};
   }
`;
export const StyledSearchBar = styled.div`
   width: 100%;
   height: 60px;
   display: flex;
   justify-content: center;
   align-items: center;
   #search-icon {
      color: ${({ theme }) => theme.colors.purple.light};
      transform: translateY(3px);
   }
   > .blog-category-dropdown {
      //   transform: translateX(-100%);
   }
   > .blog-category-dropdown div button {
      background-color: transparent !important;
      box-shadow: 0 !important;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
`;
export const StyledPostsList = styled.div`
   padding-left: 20px;
   z-index: 1000;
   width: 30vw;
   height: 85vh;
   background: white;
   position: absolute;
   transition: 0.5s ease;
   left: 0;

   ${({ isActive }) =>
      isActive
         ? `
      margin-left:0%;
      `
         : `
      margin-left:-100%;
      `}
`;

export const StyledBlogPostsContainer = styled.figure`
   margin-top: 5vh;
   width: 100%;
   #blog-more-button {
      color: ${({ theme }) => theme.colors.purple.light};
   }
`;
