import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

import anime from 'animejs/lib/anime.es.js';

gsap.registerPlugin(ScrollTrigger);
CSSPlugin.defaultForce3D = false;

export const mainSectionAnimations = () => {
   const textWrapper = document.querySelector('.about-page-title');
   if (textWrapper) {
      textWrapper.innerHTML = textWrapper.textContent.replace(
         /\S/g,
         "<span class='letter'>$&</span>"
      );

      anime.timeline().add({
         targets: '.about-page-title .letter',
         translateX: [40, 0],
         translateZ: 0,
         opacity: [0, 1],
         easing: 'easeOutExpo',
         duration: 1200,
         delay: (el, i) => 500 + 30 * i,
      });
   }

   // gsap.to('#about-bottom-container', {
   //    y: '0vh',
   //    scrollTrigger: {
   //       markers: true,
   //       trigger: '#root',
   //       start: '90% top',
   //       end: '95% top',
   //       scrub: true,
   //    },
   // });
};

export const servicesSectionAnimations = () => {
   const cards = document.querySelectorAll('.service-card');
   cards.forEach((card, index) => {
      gsap.fromTo(
         card,
         { x: '-=100', opacity: 0 },
         {
            x: 0,
            opacity: 1,
            delay: `0.${index * 2}`,
            stagger: 0.2,
            duration: 1,
            ease: 'easeInOut',
            scrollTrigger: {
               trigger: '#services-container',
               start: 'top 30%',
            },
         }
      );
   });

   gsap.from('#service-text-wrapper', {
      x: 100,
      opacity: 0,
      scrollTrigger: {
         trigger: '#services-container',
         start: 'top 30%',
      },
   });

   if (window.innerWidth > 1050) {
      ScrollTrigger.create({
         trigger: '#services-container',
         start: 'top 30%',
         once: true,
         onEnter: () => {
            const textWrapper = document.querySelector(
               '.services-title .letters'
            );
            if (textWrapper) {
               textWrapper.innerHTML = textWrapper.textContent.replace(
                  /\S/g,
                  "<span class='letter'>$&</span>"
               );
               textWrapper.style = 'visibility:visible';
               anime.timeline().add({
                  targets: '.services-title .letter',
                  scale: [0, 1],
                  duration: 1500,
                  elasticity: 600,
                  delay: (el, i) => 45 * (i + 1),
               });
            }
         },
      });
   }
};

export const teamSectionAnimations = () => {
   if (window.innerWidth > 1050) {
      ScrollTrigger.create({
         trigger: '#teams-container',
         start: 'top 80%',
         once: true,
         onEnter: () => {
            const textWrapper = document.querySelector('.teams-title .letters');
            if (textWrapper) {
               textWrapper.innerHTML = textWrapper.textContent.replace(
                  /\S/g,
                  "<span class='letter'>$&</span>"
               );
               textWrapper.style = 'visibility:visible';
               anime.timeline().add({
                  targets: '.teams-title .letter',
                  scale: [0, 1],
                  duration: 1500,
                  elasticity: 600,
                  delay: (el, i) => 45 * (i + 1),
               });
            }
         },
      });
   }
};
export const officeSectionAnimations = () => {
   if (window.innerWidth > 1050) {
      ScrollTrigger.create({
         trigger: '#office-section-container',
         start: 'top 80%',
         once: true,
         onEnter: () => {
            const textWrapper = document.querySelector(
               '.office-title .letters'
            );
            if (textWrapper) {
               textWrapper.innerHTML = textWrapper.textContent.replace(
                  /\S/g,
                  "<span class='letter'>$&</span>"
               );
               textWrapper.style = 'visibility:visible';
               anime.timeline().add({
                  targets: '.office-title .letter',
                  scale: [0, 1],
                  duration: 1500,
                  elasticity: 600,
                  delay: (el, i) => 45 * (i + 1),
               });
            }
         },
      });
   }
};
