import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { teamSectionAnimations } from 'animations/aboutPageAnimations.js';

import {
   ExecutiveTeam,
   ProgrammingTeam,
   FinancialSpecialistTeam,
} from './Teams';
import {
   StyledTeamContainer,
   StyledSectionTitle,
   StyledTeamWrapper,
} from './TeamSection.css.js';

const TeamSection = () => {
   const { t } = useTranslation();
   return (
      <StyledTeamContainer id='teams-container'>
         <StyledSectionTitle>
            <h2
               className='teams-title'
               dangerouslySetInnerHTML={{
                  __html: t('about.team.title', {
                     interpolation: { escapeValue: false },
                  }),
               }}
            ></h2>
         </StyledSectionTitle>
         <StyledTeamWrapper id='executive-team'>
            <h4>Executive</h4>
            <ExecutiveTeam />
         </StyledTeamWrapper>
         <StyledTeamWrapper id='financial-team'>
            <h4>Specialist</h4>
            <FinancialSpecialistTeam />
         </StyledTeamWrapper>
         <StyledTeamWrapper id='programming-team'>
            <h4>Programmers</h4>
            <ProgrammingTeam />
         </StyledTeamWrapper>
      </StyledTeamContainer>
   );
};

export default TeamSection;
