import anime from 'animejs/lib/anime.es';
import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const mainSectionAnimations = () => {
   const textWrapper = document.querySelector('.landing-page-title');
   if (textWrapper) {
      textWrapper.innerHTML = textWrapper.textContent.replace(
         /\S/g,
         "<span class='letter'>$&</span>"
      );

      anime.timeline().add({
         targets: '.landing-page-title .letter',
         translateX: [40, 0],
         translateZ: 0,
         opacity: [0, 1],
         easing: 'easeOutExpo',
         duration: 1200,
         delay: (el, i) => 500 + 30 * i,
      });
   }
   gsap.from('.landing-page-text', {
      x: '-100',
      opacity: 0,
   });
};

export const aboutCompanyAnimations = () => {
   const bubbles = document.querySelectorAll('.bubble');
   bubbles.forEach((bubble, index) => {
      gsap.from(bubble, {
         opacity: 0,
         duration: 0.2,
         delay: `0.${index * 1.5}`,
         scrollTrigger: {
            trigger: '#about-company-container',
            start: '-50% 20%',
         },
      });
      gsap.from(bubble.children, {
         y: '50',
         opacity: 0,
         stagger: 0.2,
         duration: 0.5,

         delay: 1,
         scrollTrigger: {
            trigger: '#about-company-container',
            start: '-50% 20%',
         },
      });
   });
   // text
   gsap.from('#about-company-text', {
      x: '-100',
      opacity: 0,

      scrollTrigger: {
         trigger: '#about-company-container',
         start: '-50% 20%',
      },
   });
   gsap.from('#bubble-text-expansion', {
      x: 50,
      opacity: 0,
   });
};

export const stepsCardsAnimations = () => {
   const cards = document.querySelectorAll('.steps-card');
   cards.forEach(card => {
      gsap.to(`${card.id} div div p`, {
         x: 1000,
      });
   });
};

export const reviewsMenuAnimations = () => {
   let isUserEnterLeaveSpace = false;

   const showMenu = () => {
      const menuCards = document.querySelectorAll('.feature-card');
      gsap.to('#reviews-menu', {
         opacity: 1,
         visibility: 'visible',
         duration: 0.5,
      });
      gsap.to('#reviews-menu', {
         duration: 0.7,
         y: 0,
      });
      menuCards.forEach((card, index) => {
         gsap.from(card, {
            opacity: 0,
            x: -100,
            duration: 0.5,
            stagger: 0.5,
            delay: `0.${index + 2}`,
            ease: 'easeInOut',
         });
      });
   };
   const hideMenu = () => {
      const menuCards = document.querySelectorAll('.feature-card');
      gsap.to('#reviews-menu', {
         opacity: 0,
         duration: 0.5,
      });
      gsap.to('#reviews-menu', {
         y: -100,
         duration: 0.7,
      });
      setTimeout(() => {
         gsap.to('#reviews-menu', {
            visibility: 'hidden',
         });
      }, 500);
   };
   ScrollTrigger.create({
      trigger: '#reviews-section-container',
      start: 'top 35%',
      end: '82% 70%',
      id: 'leave',

      onLeave: () => (isUserEnterLeaveSpace = false),
      onEnter: () => (isUserEnterLeaveSpace = true),
      onEnterBack: () => (isUserEnterLeaveSpace = true),
      onLeaveBack: () => {
         isUserEnterLeaveSpace = false;
         gsap.to('#reviews-menu', {
            opacity: 0,
            duration: 0.5,
         });
         gsap.to('#reviews-menu', {
            y: 100,
            duration: 0.7,
         });
         setTimeout(() => {
            gsap.to('#reviews-menu', {
               visibility: 'hidden',
            });
         }, 500);
      },
      onLeave: hideMenu,
   });
   ScrollTrigger.create({
      trigger: '#reviews-section-container',
      start: 'top 50%',
      id: 'enter',
      end: '82% 60%',

      onEnter: showMenu,
      onEnterBack: showMenu,
      onLeave: () => (isUserEnterLeaveSpace ? hideMenu() : null),
      onUpdate: self => {
         self.direction === -1
            ? !isUserEnterLeaveSpace
               ? hideMenu()
               : null
            : null;
      },
   });
};

export const activeCardsTrigger = setActiveCardFunction => {
   const reviewsBoxes = document.querySelectorAll('.reviews-box');

   reviewsBoxes.forEach((box, id) => {
      ScrollTrigger.create({
         scrub: true,
         trigger: box,
         start: 'top 60%',
         end: 'bottom bottom',
         onEnter: () => setActiveCardFunction(id),
         onEnterBack: () => setActiveCardFunction(id),
      });
   });
};

const reviewsShowAnimation = reviews => {
   reviews.forEach((card, index) => {
      gsap.to(card, {
         y: '0',
         opacity: 1,
         duration: 0.2,
         delay: `0.${index * 2}`,
         stagger: 0.2,
         duration: 1,
         ease: 'easeInOut',
      });
   });
};

const reviewsHideAnimation = reviews => {
   reviews.forEach((card, index) => {
      gsap.to(card, {
         y: -100,
         opacity: 0,
         duration: 0.2,
         delay: `0.${index * 2}`,
         stagger: 0.2,
         duration: 1,
         ease: 'easeInOut',
      });
   });
};

const reviewsShowBackAnimation = reviews => {
   reviews.forEach((card, index) => {
      gsap.to(card, {
         y: 0,
         opacity: 1,
         duration: 0.2,
         delay: `0.${index * 2}`,
         stagger: 0.2,
         duration: 1,
         ease: 'easeInOut',
      });
   });
};

export const reviewStarsAnimations = () => {
   ScrollTrigger.create({
      trigger: '#about-company-container',
      start: 'bottom bottom',
      end: '120% 150%',
      scrub: true,
      onEnter: showStars,
   });
};

const showStars = () => {
   const stars = document.querySelectorAll('.google-star');

   stars.forEach((star, index) => {
      gsap.from(star, {
         // y: 100,
         opacity: 0,
         // scale: 2,
         duration: 0.3,
         delay: index * 0.5,
         // stagger: 1,
         ease: 'easeInOut',
      });
   });
   setTimeout(() => {
      stars.forEach((star, index) => {
         gsap.to(star, {
            scale: 1.3,
            duration: 0.1,
            ease: 'easeInOut',
         });
      });
   }, 3000);
   setTimeout(() => {
      stars.forEach((star, index) => {
         gsap.to(star, {
            scale: 1,
            duration: 0.1,
            ease: 'easeInOut',
         });
      });
   }, 3100);
};

export const ReviewsCardAnimations = () => {
   const reviewsBoxes = document.querySelectorAll('.reviews-box');

   reviewsBoxes.forEach(box => {
      ScrollTrigger.create({
         trigger: box,
         start: 'top 70%',
         end: 'bottom 50%',
         onEnter: () => reviewsShowAnimation(box.childNodes),
         onLeave: () => reviewsHideAnimation(box.childNodes),
         onLeaveBack: () => reviewsHideAnimation(box.childNodes),
         onEnterBack: () => reviewsShowBackAnimation(box.childNodes),
      });
   });
};

// MOBILE VERSION

export const changeActiveCardTrigger = setStateFunction => {
   ScrollTrigger.create({
      trigger: '#google-rating-container',
      start: 'top bottom',
      end: 'top bottom',
      onEnter: () => setStateFunction(1),
   });
};

export const reviewCardsAnimationOnMount = () => {
   const reviewCards = document.querySelectorAll('.review-card');

   reviewCards.forEach((card, index) => {
      gsap.fromTo(
         card,
         { y: '-=100', opacity: 0 },
         {
            y: 0,
            opacity: 1,
            delay: `0.${index}`,
            stagger: 0.2,
            duration: 1,
            ease: 'easeInOut',
            scrollTrigger: {
               once: true,
               trigger: '#container-section',
               start: 'top 70%',
            },
         }
      );
   });
};
