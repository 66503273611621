import React from 'react';
import { useTranslation } from 'react-i18next';

import {
   StyledPlatformSectionAsCards,
   StyledLeftBox,
   StyledRightBox,
   StyledNotebookFigureCard,
   StyledClosingCard,
   StyledBottomImageWrapper,
} from './PlatformPageSection.css.js';

import { ReactComponent as ChatIllustration } from '../PlatformPageMobileVersion/chat-illustration.svg';

const platformInformationImgUrl = '/img/mac-withss-credit-info.png';
const platformAfterFirstCAall = '/img/mac-withss-after-firtscall.png';
const platformProgessMobileUrl = '/img/platform-progess-mobile.png';

const PlatformPageSectionAsCards = () => {
   const { t } = useTranslation();

   return (
      <StyledPlatformSectionAsCards>
         <div
            id='desktop-card-1'
            style={{
               marginBottom: '10vh',
               // flexDirection: window.innerWidth > 1050? 'row-reverse': 'column',
            }}
         >
            <StyledLeftBox
               id='card-left-box-1'
               style={{
                  display: 'flex',
                  // alignItems: 'center',
                  justifyContent: 'center',
                  border: 0,
               }}
            >
               <h3
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-6-title'),
                  }}
               ></h3>
               <p
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-6-p'),
                  }}
               ></p>
            </StyledLeftBox>
            <StyledRightBox id='card-right-box-1'>
               <img
                  src={platformInformationImgUrl}
                  alt={'Informacja kredytowa dla klienta'}
               />
            </StyledRightBox>
         </div>
         <div
            id='desktop-card-1'
            style={{
               marginBottom: '10vh',
               flexDirection:
                  window.innerWidth > 1050 ? 'row-reverse' : 'column',
            }}
         >
            <StyledLeftBox
               id='card-left-box-1'
               style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 0,
               }}
            >
               <h3
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-5-title'),
                  }}
               ></h3>
               <p
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-5-p'),
                  }}
               ></p>
            </StyledLeftBox>
            <StyledRightBox id='card-right-box-1'>
               <img
                  src={platformAfterFirstCAall}
                  alt={'Informacja dla klienta po pierwszej rozmowie'}
               />
            </StyledRightBox>
         </div>
         <div id='desktop-card-2'>
            <StyledLeftBox
               id='card-left-box-2'
               style={{
                  display: 'flex',
                  // alignItems: 'center',
                  justifyContent: 'center',
                  border: 0,
               }}
            >
               <h3
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-7-title'),
                  }}
               ></h3>
               <p
                  dangerouslySetInnerHTML={{
                     __html: t('platform.text-7-p'),
                  }}
               ></p>
            </StyledLeftBox>
            <StyledRightBox
               id='card-right-box-2'
               style={{
                  flex: '1',
                  border: 0,
               }}
            >
               <img
                  width={'100%'}
                  src={platformProgessMobileUrl}
                  alt={'Pasek postępu - smartphone'}
               />
            </StyledRightBox>
         </div>
         <StyledClosingCard>
            <h3
               dangerouslySetInnerHTML={{
                  __html: t('platform.closing-text-title'),
               }}
            ></h3>
            <p
               dangerouslySetInnerHTML={{
                  __html: t('platform.closing-text-p'),
               }}
            ></p>

            <StyledBottomImageWrapper id='bottom-card-image'>
               <ChatIllustration id='chat-illustration' />
            </StyledBottomImageWrapper>
         </StyledClosingCard>
      </StyledPlatformSectionAsCards>
   );
};

export default PlatformPageSectionAsCards;
