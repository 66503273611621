import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui icons
import imagesStyles from 'assets/jss/material-kit-pro-react/imagesStyles.js';

// material
import { Button } from '@material-ui/core';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { cardTitle } from 'assets/jss/material-kit-pro-react.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem';
//material icons
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const style = {
   ...imagesStyles,
   cardTitle,
   textMuted: {
      color: '#6c757d',
   },
};
const useStyles = makeStyles(style);

const BlogHomeContainer = ({ handleTogglePostList }) => {
   const classes = useStyles();
   return (
      <GridContainer>
         <GridItem md='6'>
            <GridItem>
               <Card>
                  <img
                     className={classes.imgCardTop}
                     src='https://images.unsplash.com/photo-1517303650219-83c8b1788c4c?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=bd4c162d27ea317ff8c67255e955e3c8&auto=format&fit=crop&w=2691&q=80'
                     alt='Card-img-cap'
                  />
                  <CardBody>
                     <h4 className={classes.cardTitle}>Mortgage Rates</h4>
                     <p>
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                     </p>
                     <p>
                        <small className={classes.textMuted}>
                           July 8, 2021
                        </small>
                     </p>
                  </CardBody>
               </Card>
            </GridItem>
         </GridItem>
         <GridItem md='6'>
            <Card>
               <CardBody>
                  <h4 className={classes.cardTitle}>Buying a house</h4>
                  <p>
                     This is a wider card with supporting text below as a
                     natural lead-in to additional content. This content is a
                     little bit longer.
                  </p>
                  <p>
                     <small className={classes.textMuted}>July 8, 2021</small>
                  </p>
               </CardBody>
            </Card>
            <Card>
               <CardBody>
                  <h4 className={classes.cardTitle}>
                     How to but a house step by step in Poland
                  </h4>
                  <p>
                     This is a wider card with supporting text below as a
                     natural lead-in to additional content. This content is a
                     little bit longer.
                  </p>
                  <p>
                     <small className={classes.textMuted}>July 8, 2021</small>
                  </p>
               </CardBody>
            </Card>
            <Button id='blog-more-button' round onClick={handleTogglePostList}>
               <ArrowRightAltIcon style={{ transform: 'rotate(180deg)' }} />{' '}
               More
            </Button>
         </GridItem>
      </GridContainer>
   );
};

export default BlogHomeContainer;
