import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollTrigger from 'gsap/ScrollTrigger';
// react component for creating beautiful carousel
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';

// import dg2 from 'assets/img/dg1.jpg';
// import cardProfile1Square from 'assets/img/faces/card-profile1-square.jpg';

import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
// import VolumeUp from '@material-ui/icons/VolumeUp';
import Grid from '@material-ui/core/Grid';

import VolumeDown from '@material-ui/icons/VolumeDown';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import DateRangeIcon from '@material-ui/icons/DateRange';
import VolumeUp from '@material-ui/icons/VolumeUp';
// import Button from '@material-ui/core/Button';

import Lines from '../../views-components/Lines';
import LinesInterest from '../../views-components/LinesInterest';

// styled components
import {
   StyledMortgageHeader,
   StyledButton,
   StyledCalculatorWrapper,
} from './MortgageCalculator.css.js';

import contactsStyleTT from 'assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js';

import NumberFormat from 'react-number-format';
import { formatCurrency } from 'assets/theme/formatCurrency.js';
import Parallax from 'components/Parallax/Parallax.js';
import { mortgagePageAnimations } from 'animations/mortagagePageAnimations.js';
import { Footer } from 'views-components';
import InfoContainer from 'views-components/InfoContainer/InfoContainer.js';
import gsap from 'gsap/dist/gsap';
// components custom
import { MortgageMonthsTable } from 'views-components/index.js';
import { Button } from '@material-ui/core';

const useStylesTT = makeStyles(contactsStyleTT);
const PrettoSlider = withStyles({
   root: {
      color: '#52af77',
      height: 8,
   },
   thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
         boxShadow: 'inherit',
      },
   },
   active: {},
   valueLabel: {
      left: 'calc(-50% + 4px)',
   },
   track: {
      height: 8,
      borderRadius: 4,
   },
   rail: {
      height: 8,
      borderRadius: 4,
   },
})(Slider);

const useStyles = makeStyles(testimonialsStyle);

function NumberFormatCustom(props) {
   const { inputRef, onChange, ...other } = props;

   return (
      <NumberFormat
         {...other}
         getInputRef={inputRef}
         onValueChange={values => {
            onChange({
               target: {
                  name: props.name,
                  value: values.value,
               },
            });
         }}
         thousandSeparator
         isNumericString
         // suffix='zł'
      />
   );
}

export default function SectionTestimonials({ ...rest }) {
   const classes = useStyles();
   const classesTT = useStylesTT();

   const { t, i18n } = useTranslation();

   const [amountBorrow, setAmountBorrow] = React.useState(400000);
   const [interastRate, setInterestRate] = React.useState(5);
   const [lengthMortgage, setLengthMortgage] = React.useState(22);

   const [arrayEndingBalance, setArrayEndingBalance] = React.useState([]);
   const [arrayInterestCharge, setArrayInterestCharge] = React.useState([]);
   const [arrayPrincipal, setArrayPrincipal] = React.useState([]);
   const [arrayMortgageMonth, setArrayMortgageMonth] = React.useState([]);

   const [timeLastChange, setTimeLastChange] = React.useState(new Date());
   const [isTimeChangeData, setIsTimeChangeData] = React.useState(false);
   const [tableLines, setTableLines] = React.useState();

   const [paymentMonth, setPaymentMonth] = React.useState(() => {
      var p = amountBorrow;
      var r = (interastRate / 12) * 0.01;
      var t = lengthMortgage * 12;

      var a = p * r * (1 + r) ** t;
      var b = (1 + r) ** t - 1;

      // console.log("Top Bottom = ",a,b)

      var paymentMonth = a / b;

      const month = t;
      var startingBalance = parseInt(p);
      var endingBalance = p;

      var interestCharge;

      var allEndingBalanceData = [];
      var allInterestCharge = [];
      var allPrincipal = [];
      var allMortgageMonth = [];
      var tablelinesArray = [];

      for (var i = 1; i <= month; i++) {
         interestCharge = r * startingBalance;
         endingBalance = startingBalance + interestCharge - paymentMonth;

         if (i % 12 === 0) {
            allEndingBalanceData.push(endingBalance);
            allInterestCharge.push(interestCharge);
            allPrincipal.push(paymentMonth - interestCharge);
            allMortgageMonth.push(paymentMonth);
         }

         startingBalance = endingBalance;
      }

      setArrayEndingBalance(allEndingBalanceData);
      setArrayInterestCharge(allInterestCharge);
      setArrayPrincipal(allPrincipal);
      setArrayMortgageMonth(allMortgageMonth);
      return paymentMonth;
   });

   const CalculatePaymentMonth = () => {
      var p = amountBorrow;
      var r = (interastRate / 12) * 0.01;
      var t = lengthMortgage * 12;

      var a = p * r * (1 + r) ** t;
      var b = (1 + r) ** t - 1;

      var paymentMonth = a / b;

      setPaymentMonth(paymentMonth);

      const month = t;
      var startingBalance = parseInt(p);
      var endingBalance = p;

      var interestCharge;
      var allEndingBalanceData = [];
      var allInterestCharge = [];
      var allPrincipal = [];
      var allMortgageMonth = [];
      var tablelinesArray = [];

      for (var i = 1; i < month + 1; i++) {
         interestCharge = r * startingBalance;
         endingBalance = startingBalance + interestCharge - paymentMonth;

         if (i % 12 === 0) {
            allEndingBalanceData.push(endingBalance);
            allInterestCharge.push(interestCharge);
            allPrincipal.push(paymentMonth - interestCharge);
            allMortgageMonth.push(paymentMonth);
         }
         tablelinesArray.push({
            initialCapitalBallance: startingBalance,
            fullInstolment: paymentMonth.toFixed(2),
            leftToBeRepaid: endingBalance.toFixed(2),
         });
         startingBalance = endingBalance;
      }

      setTableLines(tablelinesArray);
      setArrayEndingBalance(allEndingBalanceData);
      setArrayInterestCharge(allInterestCharge);
      setArrayPrincipal(allPrincipal);
      setArrayMortgageMonth(allMortgageMonth);
   };

   const handleChange = (event, newValue) => {
      // setTimeout(() => {

      setAmountBorrow(newValue);
      CalculatePaymentMonth();
      // }, 5000);
   };

   const handleChangeVal = event => {
      setAmountBorrow(event.target.value);
      CalculatePaymentMonth();
   };

   const handleChangeInterastRate = (event, newValue) => {
      setInterestRate(newValue);
      CalculatePaymentMonth();
   };

   const handleChangeInterastRateVal = event => {
      setInterestRate(event.target.value);
      CalculatePaymentMonth();
   };

   const handleChangeTerm = (event, newValue) => {
      setLengthMortgage(newValue);
      CalculatePaymentMonth();
   };

   const handleChangeValTerm = event => {
      setLengthMortgage(event.target.value);
      CalculatePaymentMonth();
   };

   useEffect(() => {
      document.title = t('pages.titles.item2');
      mortgagePageAnimations();
      return () => {
         ScrollTrigger.getAll().forEach(t => {
            if (
               t.vars.id === 'navbar-trigger' ||
               t.vars.id === 'navbar-trigger-2'
            ) {
               return;
            } else {
               t.kill();
            }
         });
      };
   }, []);
   return (
      <>
         <Parallax
            large
            filter='dark'
            style={{
               zIndex: '-1',
            }}
         >
            <StyledMortgageHeader>
               <h2 className='mortgage-page-title'>
                  {t('mortgage-calculator.header.title-text')}
               </h2>
               <p> {t('mortgage-calculator.header.paragraph-text')}</p>
            </StyledMortgageHeader>
         </Parallax>
         <div className='cd-section' {...rest}>
            {/* Testimonials 1 START */}

            {/* <div
            className={classes.container}
            // className={classesTT.contacts2}
         > */}
            <StyledCalculatorWrapper>
               <h2
                  dangerouslySetInnerHTML={{
                     __html: t('mortgage-calculator.section.title'),
                  }}
               ></h2>
               <p
                  style={{
                     marginBottom: '10vh',
                     fontSize: '2.5vh',
                  }}
               >
                  {t('mortgage-calculator.section.text')}
               </p>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                     <Card
                        testimonial
                        className={(classes.card1, 'calculator-card')}
                     >
                        <GridContainer>
                           <GridItem xs={12} sm={1} md={1}></GridItem>

                           <GridItem xs={12} sm={10} md={10}>
                              <h2 id='calculator-title'>
                                 {t('mortgage-calculator.calculator.title')}
                              </h2>
                           </GridItem>
                        </GridContainer>

                        <br />

                        <GridContainer>
                           <GridItem xs={12} sm={1} md={1}></GridItem>

                           <GridItem xs={12} sm={7} md={7}>
                              <label htmlFor='myInput'>
                                 {' '}
                                 {t('mortgage-calculator.calculator.input1')}
                              </label>
                              <Grid container spacing={2}>
                                 <Grid item>
                                    <LocalAtmIcon />
                                 </Grid>
                                 <Grid item xs>
                                    <Slider
                                       step={500}
                                       min={0}
                                       max={1000000}
                                       valueLabelDisplay='auto'
                                       value={amountBorrow}
                                       onChange={handleChange}
                                       aria-labelledby='continuous-slider'
                                    />
                                 </Grid>
                              </Grid>
                           </GridItem>

                           <GridItem xs={12} sm={3} md={3}>
                              <TextField
                                 className='text-field-mortgage-calculator'
                                 id='standard-basic'
                                 label={t(
                                    'mortgage-calculator.calculator.input1-label'
                                 )}
                                 style={{ marginTop: '16px' }}
                                 variant='outlined'
                                 size='small'
                                 value={amountBorrow}
                                 onChange={e => {
                                    handleChangeVal(e);
                                 }}
                                 InputProps={{
                                    inputComponent: NumberFormatCustom,
                                 }}
                              />
                           </GridItem>
                        </GridContainer>
                        <br />
                        {/* <br/> */}

                        <GridContainer>
                           <GridItem xs={12} sm={1} md={1}></GridItem>

                           <GridItem xs={12} sm={7} md={7}>
                              <label htmlFor='myInput'>
                                 {' '}
                                 {t('mortgage-calculator.calculator.input2')}
                              </label>

                              <Grid container spacing={2}>
                                 <Grid item>
                                    <PermDataSettingIcon />
                                 </Grid>
                                 <Grid item xs>
                                    <Slider
                                       step={0.1}
                                       min={0.5}
                                       max={7}
                                       valueLabelDisplay='auto'
                                       value={interastRate}
                                       onChange={handleChangeInterastRate}
                                       aria-labelledby='continuous-slider'
                                    />
                                 </Grid>
                              </Grid>
                           </GridItem>

                           <GridItem xs={12} sm={3} md={3}>
                              <TextField
                                 className='text-field-mortgage-calculator'
                                 id='standard-basic'
                                 label={t(
                                    'mortgage-calculator.calculator.input2-label'
                                 )}
                                 style={{ marginTop: '16px' }}
                                 variant='outlined'
                                 size='small'
                                 value={interastRate}
                                 aria-labelledby='continuous-slider'
                                 onChange={e => {
                                    handleChangeInterastRateVal(e);
                                 }}
                              />
                           </GridItem>
                        </GridContainer>
                        <br />
                        <br />

                        <GridContainer>
                           <GridItem xs={12} sm={1} md={1}></GridItem>

                           <GridItem xs={12} sm={7} md={7}>
                              <label htmlFor='myInput'>
                                 {t('mortgage-calculator.calculator.input3')}
                              </label>

                              <Grid container spacing={2}>
                                 <Grid item>
                                    <DateRangeIcon />
                                 </Grid>
                                 <Grid item xs>
                                    <Slider
                                       valueLabelDisplay='auto'
                                       min={1}
                                       max={30}
                                       value={lengthMortgage}
                                       onChange={handleChangeTerm}
                                       aria-labelledby='continuous-slider'
                                    />
                                 </Grid>
                              </Grid>
                           </GridItem>

                           <GridItem xs={12} sm={3} md={3}>
                              <TextField
                                 className='text-field-mortgage-calculator'
                                 id='standard-basic'
                                 label={t(
                                    'mortgage-calculator.calculator.input3-label'
                                 )}
                                 style={{ marginTop: '16px' }}
                                 variant='outlined'
                                 size='small'
                                 value={lengthMortgage}
                                 onChange={e => {
                                    handleChangeValTerm(e);
                                 }}
                              />
                           </GridItem>
                        </GridContainer>
                        {/* <br />
                        <br />
                        <br />

                        <GridContainer>
                           <GridItem xs={12} sm={1} md={1}></GridItem>
                           <GridItem xs={12} sm={10} md={10}>
                              <TextField
                                 id='filled-basic'
                                 disabled
                                 label='Filled'
                                 variant='filled'
                                 fullWidth='true'
                              />
                           </GridItem>
                           <GridItem xs={12} sm={1} md={1}></GridItem>
                        </GridContainer> */}

                        <br />
                        <br />

                        <GridContainer>
                           <GridItem xs={12} sm={12} md={1}></GridItem>
                           <GridItem xs={12} sm={12} md={10}>
                              <TextField
                                 id='filled-basic'
                                 // disabled
                                 // style={{color: "rgba(0, 0, 0, 0.9)"}}
                                 // value =`${paymentMonth.toFixed(2)}`
                                 value={formatCurrency(
                                    paymentMonth,
                                    i18n.language
                                 )}
                                 label={t(
                                    'mortgage-calculator.calculator.label4'
                                 )}
                                 variant='outlined'
                                 fullWidth='true'
                              />
                           </GridItem>
                           <GridItem xs={12} sm={1} md={1}></GridItem>
                        </GridContainer>

                        <CardBody>
                           {/* <h5 className={classes.cardDescription}>
                  Jakub h
                  </h5> */}
                        </CardBody>
                        <CardFooter testimonial>
                           <GridContainer>
                              <GridItem
                                 xs={12}
                                 sm={12}
                                 md={12}
                                 style={{ padding: 30 }}
                              >
                                 <Button round type='button' color='primary'>
                                    {t('mortgage-calculator.calculator.button')}
                                 </Button>
                              </GridItem>
                           </GridContainer>
                        </CardFooter>
                     </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                     <Card
                        testimonial
                        className={(classes.card1, 'calculator-card')}
                        id='calculator-chart'
                     >
                        <div style={{ padding: 30 }}>
                           <Lines
                              arrayEndingBalance={arrayEndingBalance}
                              arrayInterestCharge={arrayInterestCharge}
                              arrayPrincipal={arrayPrincipal}
                              arrayMortgageMonth={arrayMortgageMonth}
                           />
                        </div>
                     </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}></GridItem>
                  {/* <GridItem xs={12} sm={12} md={12}>
                     <Card
                        testimonial
                        className={classes.card1}
                        style={{ border: '1px solid #FF00CF' }}
                     >
                        <div style={{ padding: 30 }}>
                           <LinesInterest
                              arrayEndingBalance={arrayEndingBalance}
                              arrayInterestCharge={arrayInterestCharge}
                              arrayPrincipal={arrayPrincipal}
                           />
                        </div>
                     </Card>
                  </GridItem> */}
               </GridContainer>
               <MortgageMonthsTable
                  years={lengthMortgage}
                  amountBorrow={amountBorrow}
                  interastRate={interastRate}
                  arrayInterestCharge={arrayInterestCharge}
               />
            </StyledCalculatorWrapper>
            <Footer />
         </div>
      </>
   );
}
