import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from 'components/CustomButtons/Button.js';

import style from '../../../../assets/jss/material-kit-pro-react/modalStyle.js';

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction='down' ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

export default function ImageModal({
   isModalActive,
   activeModalImage,
   handleCloseModal,
}) {
   const classes = useStyles();

   return (
      <div>
         <Dialog
            classes={{
               root: classes.modalRoot,
               paper: classes.modal + ' ' + classes.modalLarge,
            }}
            open={isModalActive}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseModal}
            aria-labelledby='classic-modal-slide-title'
            aria-describedby='classic-modal-slide-description'
         >
            <DialogTitle
               id='classic-modal-slide-title'
               disableTypography
               className={classes.modalHeader}
            >
               <Button
                  simple
                  className={classes.modalCloseButton}
                  key='close'
                  aria-label='Close'
                  onClick={handleCloseModal}
               >
                  <Close className={classes.modalClose} />
               </Button>
            </DialogTitle>
            <DialogContent
               id='classic-modal-slide-description'
               className={classes.modalBody}
            >
               <img
                  src={activeModalImage}
                  style={{ width: 'auto', maxHeight: '90vh' }}
               />
            </DialogContent>
         </Dialog>
      </div>
   );
}
