import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
import Assignment from '@material-ui/icons/Assignment';
import Face from '@material-ui/icons/Face';
import Timeline from '@material-ui/icons/Timeline';
import Code from '@material-ui/icons/Code';
import Group from '@material-ui/icons/Group';
import Email from '@material-ui/icons/Email';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import Check from '@material-ui/icons/Check';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel } from '@material-ui/core';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import style from 'assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

const currencyStatus = [
   {
      value: 'PLN',
      label: 'PLN',
   },
   {
      value: 'USD',
      label: 'USD',
   },
   {
      value: 'EUR',
      label: 'EUR',
   },
   {
      value: 'GPB',
      label: 'GPB',
   },
   {
      value: 'Other',
      label: 'Inna',
   },
];

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction='down' ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

const useStyles2 = makeStyles(theme => ({
   persImg: {
      width: '14em',
      // verticalAlign : "bottom",
      [theme.breakpoints.down('md')]: {
         width: '14em',
      },
      [theme.breakpoints.down('sm')]: {
         width: '15em',
      },
      [theme.breakpoints.down('xs')]: {
         width: '14em',
      },
   },
   mainText: {
      fontSize: 50,
      color: 'white',
      [theme.breakpoints.down('md')]: {
         fontSize: 40,
      },
      [theme.breakpoints.down('sm')]: {
         fontSize: 38,
      },
      [theme.breakpoints.down('xs')]: {
         fontSize: 35,
      },
   },
}));

export default function ExampleSingup(props) {
   const [signupModal, setSignupModal] = React.useState(false);
   const [employment, setEmployment] = React.useState('');
   const [currency, setCurrency] = React.useState('');

   const { t } = useTranslation();

   const classes2 = useStyles2();

   const employmentStatus = [
      {
         value: 'contract',
         label: t('ranking.bank.select1'),
      },
      {
         value: 'self_employment',
         label: t('ranking.bank.select2'),
      },
      {
         value: 'own_business',
         label: t('ranking.bank.select3'),
      },
      {
         value: 'other',
         label: t('ranking.bank.select4'),
      },
   ];

   const handleChangeEmployment = event => {
      setEmployment(event.target.value);
   };
   const handleChangeCurrency = event => {
      setCurrency(event.target.value);
   };

   const classes = useStyles();
   return (
      <div>
         <Button
            round
            onClick={() => setSignupModal(true)}
            variant='contained'
            color='primary'
            className='talk-to-expert-button'
            style={{
               minWidth: '300px',
               height: '50px',
               marginLeft: '15px',
               fontSize: '2vh',
            }}
         >
            {t('ranking.bank.button')}
         </Button>
         <Dialog
            classes={{
               root: classes.modalRoot,
               paper: classes.modal + ' ' + classes.modalSignup,
            }}
            open={signupModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setSignupModal(false)}
            aria-labelledby='signup-modal-slide-title'
            aria-describedby='signup-modal-slide-description'
         >
            <Card plain className={classes.modalSignupCard}>
               <DialogTitle
                  id='signup-modal-slide-title'
                  disableTypography
                  className={classes.modalHeader}
               >
                  <GridContainer>
                     <GridItem xs={12} sm={2} md={2}></GridItem>
                     <GridItem xs={12} sm={4} md={4} className={classes.mlAuto}>
                        <a href='#pablo' onClick={e => e.preventDefault()}>
                           <img
                              src={props.bank.picture}
                              alt='...'
                              style={{ maxHeight: '10em', maxWidth: '15em' }}
                           />
                        </a>
                        <div
                           className={classes.coloredShadow}
                           style={{
                              backgroundImage: `url(${props.bank.picture})`,
                              opacity: '1',
                              maxHeight: '10em',
                              maxWidth: '15em',
                           }}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                        <h3
                           className={
                              classes.cardTitle + ' ' + classes.modalTitle
                           }
                        >
                           {t('ranking.bank.button')}
                        </h3>
                     </GridItem>
                     <GridItem xs={12} sm={1} md={1}>
                        <Button
                           simple
                           className={classes.modalCloseButton}
                           key='close'
                           aria-label='Close'
                           onClick={() => setSignupModal(false)}
                        >
                           {' '}
                           <Close className={classes.modalClose} />
                        </Button>
                     </GridItem>
                  </GridContainer>
               </DialogTitle>
               <DialogContent
                  id='signup-modal-slide-description'
                  className={classes.modalBody}
               >
                  <GridContainer>
                     <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                        <InfoArea
                           className={classes.infoArea}
                           title=' '
                           description={
                              <p
                                 style={{
                                    fontSize: '2vh',
                                    fontWeight: 'bold',
                                    marginTop: '10%',
                                 }}
                              >
                                 {t('ranking.bank.modal-text1')}{' '}
                                 {props.bank.name}
                              </p>
                           }
                           icon={Group}
                           iconColor='info'
                        />

                        <img
                           style={{
                              transform: 'translateX(-30px)',
                              position: 'relative',
                              top: '20px',
                              margin: 'auto',
                              width: '85%',
                              display: 'flex',
                              justifyContent: 'center',
                              borderRadius: '5px',
                              padding: '0px',
                              boxShadow: '5px 10px 18px #101519',
                           }}
                           alt='black decorative slash'
                           src='/img/talk-to-expert.jpg'
                           className={classes2.persImg}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                        <form className={classes.form}>
                           <CustomInput
                              formControlProps={{
                                 fullWidth: true,
                                 className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                 startAdornment: (
                                    <InputAdornment
                                       position='start'
                                       className={classes.inputAdornment}
                                    ></InputAdornment>
                                 ),
                                 placeholder: t(
                                    'homepage.contact-us.form-input-1'
                                 ),
                              }}
                           />
                           <CustomInput
                              formControlProps={{
                                 fullWidth: true,
                                 className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                 startAdornment: (
                                    <InputAdornment
                                       position='start'
                                       className={classes.inputAdornment}
                                    ></InputAdornment>
                                 ),
                                 placeholder: t(
                                    'homepage.contact-us.form-input-2'
                                 ),
                              }}
                           />
                           <CustomInput
                              formControlProps={{
                                 fullWidth: true,
                                 className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                 startAdornment: (
                                    <InputAdornment
                                       position='start'
                                       className={classes.inputAdornment}
                                    >
                                       {/* <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon> */}
                                    </InputAdornment>
                                 ),
                                 placeholder: t('ranking.bank.modal-input3'),
                              }}
                           />
                           <FormControl
                              fullWidth
                              className={
                                 classes.selectFormControl +
                                 ' ' +
                                 classes.selectUnderlineRoot
                              }
                           >
                              <InputLabel
                                 style={{
                                    fontSize: '2vh',
                                 }}
                                 id='demo-controlled-open-select-label'
                              >
                                 {t('ranking.bank.modal-input4')}
                              </InputLabel>
                              <Select
                                 MenuProps={{
                                    className: classes.selectMenu,
                                 }}
                                 classes={{
                                    select: classes.select,
                                 }}
                                 style={{
                                    fontSize: '2vh',
                                 }}
                                 // value={specialitySelect}
                                 // onChange={handleSpeciality}
                                 inputProps={{
                                    labelText: 'Imię i nazwisko',
                                    name: 'specialitySelect',
                                    id: 'speciality-select',
                                 }}
                              >
                                 {employmentStatus.map(option => (
                                    <MenuItem
                                       style={{
                                          fontSize: '2vh',
                                       }}
                                       key={option.value}
                                       value={option.value}
                                    >
                                       {option.label}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>
                           <br />
                           <br />

                           <FormControl
                              fullWidth
                              className={
                                 classes.selectFormControl +
                                 ' ' +
                                 classes.selectUnderlineRoot
                              }
                           >
                              <InputLabel
                                 style={{
                                    fontSize: '2vh',
                                 }}
                                 id='demo-controlled-open-select-label'
                              >
                                 {t('ranking.bank.modal-input5')}
                              </InputLabel>
                              <Select
                                 MenuProps={{
                                    className: classes.selectMenu,
                                 }}
                                 classes={{
                                    select: classes.select,
                                 }}
                                 style={{
                                    fontSize: '2vh',
                                 }}
                                 // value={specialitySelect}
                                 // onChange={handleSpeciality}
                                 inputProps={{
                                    labelText: 'Imię i nazwisko',
                                    name: 'specialitySelect',
                                    id: 'speciality-select',
                                 }}
                              >
                                 {currencyStatus.map(option => (
                                    <MenuItem
                                       style={{
                                          fontSize: '2vh',
                                       }}
                                       key={option.value}
                                       value={option.value}
                                    >
                                       {option.label}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>

                           <br />
                           <br />

                           {/* <FormControlLabel
                    classes={{
                      label: classes.label
                    }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label={
                      <span>
                        I agree to the <a href="#pablo">terms and conditions</a>
                        .
                      </span>
                    }
                  /> */}
                           <div className={classes.textCenter}>
                              <Button
                                 style={{
                                    marginTop: '2.2vh',
                                    fontSize: '2vh',
                                 }}
                                 round
                                 color='primary'
                              >
                                 {t('ranking.bank.modal-button')}
                              </Button>
                           </div>
                        </form>
                     </GridItem>
                  </GridContainer>
               </DialogContent>
            </Card>
         </Dialog>
      </div>
   );
}
