import React, { useEffect } from 'react';

function RedirectToGoogleReviews() {
   useEffect(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.title = t('pages.titles.item1');
   });

   return (
      <>
         {window.location.replace(
            'https://search.google.com/local/writereview?placeid=ChIJGaae64tdFkcRzoUvWTAOtZQ'
         )}
      </>
   );
}

export default RedirectToGoogleReviews;
