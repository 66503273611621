import styled, { keyframes } from 'styled-components';

const macImageUrl = '/img/mac-with-ss.png';
const backgroundImageUrl = '/img/platform-image.jpg';

export const underlineAnimation = keyframes`
   0%{
      width:0%;
   }
   100%{
      width:100%;
   }
`;

export const StyledPlatformTutorialHeader = styled.header`
   width: 100%;
   height: 100vh;
   position: relative;
   background:black;
   &:after {
      content: '';
      z-index: 0;
      background-image: url(${backgroundImageUrl});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 20% 20%;
      opacity:0.8;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      // MOBILE
      @media (max-width: 1050px) {
         background-position: 50% 20%; 
      }
   }
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding-left:20%;
   padding-bottom:10%;
   > h2,
   p {
      z-index: 200;
      color: white;
   }
   > .platform-page-title {
      text-align: center;
      margin-bottom: 3vh;
      z-index: 200;
      color: white;
      font-size: 5vh;
      position: relative;
      &:after {
         animation: ${underlineAnimation} 1s ease forwards;
         animation-delay: 1s;
         content: '';
         z-index: 100;
         width: 0%;
         left: 0;
         bottom: 0%;
         // right: 0;
         margin: auto;
         border-bottom: 3px solid ${({ theme }) => theme.colors.purple.light};
         position: absolute;
      }
   }
  
   > p {
      padding: 0 35%;
      line-height: 30px;
      text-align: center;
      font-size: 2.5vh;
   }
   @media (max-width: 1050px) {
      padding-top: 0%;
      padding-left:0;
      > p {
         padding: 0 10%;
   }
`;

export const StyledNotebookFigure = styled.figure`
   width: 1300px;
   height: 1300px;
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   margin: auto;
   opacity: 0;
   > img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
   }

   @media (max-width: 1050px) {
      left: -250%;
      bottom: -30%;
      opacity: 1;
      width: 100%;
      height: 32%;
      top: 10%;
      > img,
      video {
         width: 55%;
         height: 55%;
      }
   }
`;
