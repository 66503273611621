import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger);
CSSPlugin.defaultForce3D = false;

export const formStepAnimations = () => {
   gsap.from('#phone-wrapper', {
      scrollTrigger: {
         trigger: '#form-section-container',
         scrub: true,
         start: '50% bottom',
         end: '90% bottom',
      },
      y: '10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   gsap.from('#form-left-element', {
      scrollTrigger: {
         trigger: '#form-section-container',
         scrub: true,
         start: '50% bottom',
         end: '90% bottom',
      },
      x: '-10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   gsap.from('#form-right-element', {
      scrollTrigger: {
         trigger: '#form-section-container',
         scrub: true,
         start: '50% bottom',
         end: '90% bottom',
      },
      x: '10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
};
