import { gsap } from 'gsap/dist/gsap.js';
import anime from 'animejs/lib/anime.es.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const platformLoginImageUrl = '/img/mac-withss-login-page.png';
const platformWelcomeUrl = '/img/mac-withss-welcome-page.png';
const platformFileManager = '/img/mac-withss-file-manager.png';

gsap.registerPlugin(ScrollTrigger);

export const mainSectionAnimations = () => {
   const textWrapper = document.querySelector('.platform-page-title');
   if (textWrapper) {
      textWrapper.innerHTML = textWrapper.textContent.replace(
         /\S/g,
         "<span class='letter'>$&</span>"
      );

      anime.timeline().add({
         targets: '.platform-page-title .letter',
         translateX: [40, 0],
         translateZ: 0,
         opacity: [0, 1],
         easing: 'easeOutExpo',
         duration: 1200,
         delay: (el, i) => 500 + 30 * i,
      });
   }
   //footer
   gsap.to('#footer-container', {
      y: '0',
      visibility: 'visible',
      scrollTrigger: {
         trigger: '#platform-wrapper',
         start: '95% top',
         end: '110% top',
         scrub: true,
      },
   });
};
export const tutorialAnimations = () => {
   const macScreen = document.querySelector('#macbook-screen');

   // scroll timeline
   gsap.to('#mac-container', {
      scrollTrigger: {
         trigger: '#platform-wrapper',
         scrub: true,
         start: '5% top',
         end: '10% top',

         onEnterBack: () => {
            macScreen.src = platformLoginImageUrl;
         },
      },

      opacity: 1,
      ease: 'easeInOut',
   });
   gsap.from('#notebook-screen', {
      scrollTrigger: {
         trigger: '#platform-wrapper',
         scrub: true,
         start: '3% top',
         end: '8% top',
      },
      y: '100vw',
      x: '120vw',
      ease: 'easeInOut',
   });

   gsap.to('#mac-container', {
      scrollTrigger: {
         trigger: '#platform-wrapper',
         scrub: true,
         start: '10% top',
         end: '15% top',
      },
      x: '50%',
      y: '10%',
      ease: 'easeInOut',
      immediateRender: false,
   });
   ScrollTrigger.create({
      trigger: '#platform-wrapper',
      start: '5% top',
      end: '22% top',
      onEnterBack: () => {
         macScreen.src = platformLoginImageUrl;
      },
   });

   gsap.to('#mac-container', {
      scrollTrigger: {
         trigger: '#platform-wrapper',
         scrub: true,
         start: '22% top',
         end: '27.5% top',
      },
      x: '-50%',
      scale: 1.1,
      ease: 'easeInOut',
      immediateRender: false,
   });
   ScrollTrigger.create({
      trigger: '#platform-wrapper',
      start: '25% top',
      end: '27.5% top',
      onEnter: () => {
         macScreen.src = platformWelcomeUrl;
      },
      onEnterBack: () => {
         macScreen.src = platformWelcomeUrl;
      },
   });
   gsap.to('#mac-container', {
      scrollTrigger: {
         trigger: '#platform-wrapper',
         scrub: true,
         start: '35% top',
         end: '40% top',
         onEnter: () => {
            macScreen.src = platformFileManager;
         },
      },
      x: '50%',
      scale: 1.2,
      ease: 'easeInOut',
      immediateRender: false,
   });
   gsap.to('#mac-container', {
      scrollTrigger: {
         trigger: '#platform-wrapper',
         scrub: true,
         start: '50% top',
         end: '56% top',
         onEnter: () => {
            // macScreen.src = platformFileManagerUrl;
         },
      },
      x: '-50%',
      y: '5%',
      scale: 1.2,
      ease: 'easeInOut',
      immediateRender: false,
   });
   gsap.to('#mac-container', {
      scrollTrigger: {
         trigger: '#platform-wrapper',
         scrub: true,
         start: '63% top',
         end: '67% top',
         onEnter: () => {
            // macScreen.src = platformFileManagerUrl;
         },
      },
      x: '-40%',
      opacity: 0,
      scale: 1,
      ease: 'easeInOut',
      immediateRender: false,
   });

   // cards
   // bottomCardsAnimations();
};

export const tutorialAnimationsMobile = () => {
   const textContainer1 = document.querySelector('#first-card-text');
   const textContainer2 = document.querySelector('#second-card-text');
   const textContainer3 = document.querySelector('#third-card-text');

   // all cards text
   textContainer1.childNodes.forEach((text, index) => {
      gsap.from(text, {
         y: -100,
         opacity: 0,
         stagger: 0.2,
         delay: `0.${index}`,
         duration: 1,
         ease: 'easeInOut',
         scrollTrigger: {
            trigger: textContainer1,
            start: 'top 80%',
         },
      });
   });
   textContainer2.childNodes.forEach((text, index) => {
      gsap.from(text, {
         y: 100,
         opacity: 0,
         stagger: 0.2,
         delay: `0.${index}`,
         duration: 1,
         ease: 'easeInOut',
         scrollTrigger: {
            trigger: textContainer2,
            start: 'top 80%',
         },
      });
   });
   textContainer3.childNodes.forEach((text, index) => {
      gsap.from(text, {
         y: 100,
         opacity: 0,
         stagger: 0.2,
         delay: `0.${index}`,
         duration: 1,
         ease: 'easeInOut',
         scrollTrigger: {
            trigger: textContainer3,
            start: 'top 80%',
         },
      });
   });

   // first card
   gsap.from('#notebook-screen-login', {
      x: 100,
      opacity: 0,
      scrollTrigger: {
         trigger: '#first-card-image',
         start: 'top 90%',
      },
   });
   bottomCardsAnimations();
};

export const bottomCardsAnimations = () => {
   const childrenOfFirstLeftCard =
      document.querySelector('#card-left-box-1').childNodes;
   const childrenOfFirstRightCard =
      document.querySelector('#card-right-box-1').childNodes;

   childrenOfFirstLeftCard.forEach((text, index) => {
      gsap.from(text, {
         y: 100,
         opacity: 0,
         stagger: 0.2,
         delay: `0.${index}`,
         duration: 1,
         ease: 'easeInOut',
         scrollTrigger: {
            trigger: '#desktop-card-1',
            start: 'top 50%',
         },
      });
   });
   childrenOfFirstRightCard.forEach((text, index) => {
      gsap.from(text, {
         y: 100,
         opacity: 0,
         stagger: 0.2,
         delay: `0.${index}`,
         duration: 1,
         ease: 'easeInOut',
         scrollTrigger: {
            trigger: '#desktop-card-1',
            start: 'top 50%',
         },
      });
   });
   const childrenOfSecondLeftCard =
      document.querySelector('#card-left-box-2').childNodes;
   const childrenOfSecondRightCard =
      document.querySelector('#card-right-box-2').childNodes;

   childrenOfSecondLeftCard.forEach((text, index) => {
      gsap.from(text, {
         y: 100,
         opacity: 0,
         stagger: 0.2,
         delay: `0.${index}`,
         duration: 1,
         ease: 'easeInOut',
         scrollTrigger: {
            trigger: '#desktop-card-2',
            start: 'top 50%',
         },
      });
   });
   childrenOfSecondRightCard.forEach((text, index) => {
      gsap.from(text, {
         y: 100,
         opacity: 0,
         stagger: 0.2,
         delay: `0.${index}`,
         duration: 1,
         ease: 'easeInOut',
         scrollTrigger: {
            trigger: '#desktop-card-2',
            start: 'top 50%',
         },
      });
   });

   // ILLUSTATION
   gsap.from('#chat-illustration', {
      x: -100,
      opacity: 0,
      scrollTrigger: {
         trigger: '#bottom-card-image',
         start: 'top 90%',
      },
   });
   gsap.from('#chat-1, #chat-2', {
      y: -50,
      scale: 0,
      delay: 0.3,
      scrollTrigger: {
         trigger: '#bottom-card-image',
         start: 'top 90%',
      },
   });
};
