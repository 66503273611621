import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { officeSectionAnimations } from 'animations/aboutPageAnimations.js';

import { StyledOfficeSection } from './OfficeSection.css.js';
import ImageModal from './ImageModal/ImageModal.js';

// material components
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';

// IMAGES
const office1 = '/img/office1.jpg';
const office2 = '/img/office2.jpg';
const office3 = '/img/office3.jpg';
const office4 = '/img/office4.jpg';
const office5 = '/img/office5.jpg';
const office6 = '/img/about-image2.jpg';

const OfficeSection = () => {
   const [isModalActive, setIsModalActive] = useState(false);
   const [activeModalImage, setActiveModalImage] = useState(null);

   const { t } = useTranslation();

   const handleOpenImageModal = image => {
      // setIsModalActive(true);
      // setActiveModalImage(image.src);
   };
   const handleCloseModal = () => {
      // setIsModalActive(false);
      // setActiveModalImage(null);
   };

   useEffect(() => {}, []);
   return (
      <StyledOfficeSection id='office-section-container'>
         <ImageModal
            handleCloseModal={handleCloseModal}
            isModalActive={isModalActive}
            activeModalImage={activeModalImage}
         />
         <header className='office-section-grid-header'>
            <h2
               className='office-title'
               dangerouslySetInnerHTML={{
                  __html: t('about.office.title'),
               }}
            ></h2>
         </header>
         <GridContainer className='office-section-grid-images'>
            <GridItem md={12} sm={12}>
               <img
                  onClick={e => handleOpenImageModal(e.target)}
                  src={office6}
                  className='office-section-image'
                  alt='office6'
               />
            </GridItem>
            <GridItem md={4} sm={4}>
               <img
                  onClick={e => handleOpenImageModal(e.target)}
                  src={office1}
                  className='office-section-image'
                  alt='office1'
               />
            </GridItem>
            <GridItem md={4} sm={4}>
               <img
                  onClick={e => handleOpenImageModal(e.target)}
                  className='office-section-image'
                  src={office2}
                  alt='office2'
               />
            </GridItem>
            <GridItem md={4} sm={4}>
               <img
                  onClick={e => handleOpenImageModal(e.target)}
                  className='office-section-image'
                  src={office3}
                  alt='office3'
               />
            </GridItem>
            <GridItem md={6} sm={6}>
               <img
                  onClick={e => handleOpenImageModal(e.target)}
                  className='office-section-image'
                  src={office4}
                  alt='office4'
               />
            </GridItem>
            <GridItem md={6} sm={6}>
               <img
                  onClick={e => handleOpenImageModal(e.target)}
                  className='office-section-image'
                  src={office5}
                  alt='office5'
               />
            </GridItem>
         </GridContainer>
      </StyledOfficeSection>
   );
};

export default OfficeSection;
