import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const languages = ['en', 'pl'];

const options = {
   order: ['querystring', 'navigator'],
   lookupQuerystring: 'lng',
};

i18n
   .use(Backend)
   // .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      //   resources: resources,
      debug: true,
      whitelist: languages,
      detection: options,
      interpolation: {
         escapeValue: false,
      },
   });

i18n.changeLanguage('pl');

export default i18n;
