import React from 'react';
import { Router, Route, Switch } from 'react-router';
import { createBrowserHistory } from 'history';

import { BlogHomeContainer, BlogPost } from '../../views-components';

var hist = createBrowserHistory();

const BlogRouter = ({ handleTogglePostList }) => {
   return (
      <Router history={hist}>
         <Switch>
            <Route path='/blog/:id'>
               <BlogPost />
            </Route>
            <Route path='/'>
               <BlogHomeContainer handleTogglePostList={handleTogglePostList} />
            </Route>
         </Switch>
      </Router>
   );
};

export default BlogRouter;
