import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Confetti from 'react-confetti';

import { stepSixAnimations } from './stepSixAnimations.js';

import {
   StyledStepWrapper,
   StyledStepCard,
   StyledCardHeader,
   StyledCardBody,
} from '../ProcessSteps.css.js';
import { ReactComponent as StepSixImage } from '../../ProcessStepsImages/step_six.svg';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';

const StepSix = () => {
   const { t } = useTranslation();

   useEffect(() => {
      stepSixAnimations();
   });
   return (
      <>
         <h4 id='step-six-title' className='step-title'>
            {t('homepage.process.step-text')} 05
         </h4>
         <StyledStepWrapper id='step-six-container'>
            <div
               className='image-container'
               style={{
                  // overflow: 'hidden',
                  position: 'relative',
               }}
            >
               <Confetti
                  width={'700px'}
                  height={'350px'}
                  style={{
                     transform:window.innerWidth>1050? 'translateY(70%) translateX(15%)':"translateY(10%) translateX(0%)",
                  }}
               />
               <StepSixImage
                  style={{
                     transform: 'translateY(-7%)',
                  }}
                  id='step-six-image'
               />
            </div>
            <div className='steps-card' id='step-six-card'>
               <StyledStepCard>
                  <StyledCardHeader className='steps-card-header'>
                     <h3>{t('homepage.process.step5-title')}</h3>{' '}
                  </StyledCardHeader>
                  <StyledCardBody className='text-container'>
                     <p
                        dangerouslySetInnerHTML={{
                           __html: t('homepage.process.step5-text', {
                              interpolation: { escapeValue: false },
                           }),
                        }}
                     ></p>
                  </StyledCardBody>
               </StyledStepCard>
            </div>
         </StyledStepWrapper>
      </>
   );
};

export default StepSix;
