import React, { useEffect, Suspense } from "react";
import { useTranslation } from "react-i18next";

import { StyledPrivacyPolicyPageContainer } from "./PrivacyPolicyPage.css";
// import _html from "./privacy.html";
import "./style.css";

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelector(".nav-bar").classList.add("nav-bar-active");
    document.querySelector("#nav-bar-logo").src = "/img/logo-navbar.png";
    return () => {
      document.querySelector(".nav-bar").classList.remove("nav-bar-active");
      document.querySelector("#nav-bar-logo").src =
        "/img/logo-navbar-white.png";
    };
  }, []);
  return (
    <StyledPrivacyPolicyPageContainer>
      <img src="/img/logo-navbar.png" />

      <div
        dangerouslySetInnerHTML={{
          __html: ` <div class="WordSection1" style="padding: 0 15% 5% 15%">
      

      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><span
            style="
              font-size: 24pt;
              line-height: 110%;
              font-family: 'Arial', sans-serif;
            "
            >Polityka Ochrony Danych Osobowych oraz Bezpieczeństwa System</span
          ></b
        ><b
          ><span
            lang="ES-TRAD"
            style="
              font-size: 24pt;
              line-height: 110%;
              font-family: 'Arial', sans-serif;
            "
            >ó</span
          ></b
        ><b
          ><span
            style="
              font-size: 24pt;
              line-height: 110%;
              font-family: 'Arial', sans-serif;
            "
            >w Informacji</span
          ></b
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif; font-size:20px"
          >Niniejszy dokument został stworzony dla Finseka spółki z ograniczoną
          odpowiedzialnością z siedzibą w Krakowie przy ul. Wadowicka 7, 30-363
          Kraków zarejestrowanej pod numerem NIP: 6793220081 (zwanej dalej:
          <b>Administratorem</b>).</span
        >
      </p>

      <span
        style="
          font-size: 10.5pt;
          line-height: 110%;
          font-family: 'Arial Unicode MS', sans-serif;
          color: black;
        "
        ><br clear="all" style="page-break-before: always" />
      </span>

      <p class="MsoNormal">
        <span
          style="
            font-size: 10pt;
            line-height: 110%;
            font-family: 'Times New Roman', serif;
            color: windowtext;
          "
          >&nbsp;</span
        >
      </p>

      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><span lang="FR" style="font-family: 'Arial', sans-serif"
            >Spis tre</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif">ści</span></b>
      </p>

      <p class="MsoNormal"></p>

      <p class="TOC11" style="margin-left: 28.5pt; text-indent: -10.5pt">
        <span style="text-transform: none; text-decoration: none"
          >1.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><span lang="IT"
          >Terminologia                                                                                                                                                           </span
        >
      </p>

      <p class="TOC21" style="margin-left: 16.2pt; text-indent: -16.2pt">
        <span style="font-variant: normal !important">1.1.</span
        >Wstęp                                                                                                                                                                                   
        
      </p>

      <p class="TOC21">
        1.2. Podstawy stworzenia
        dokumentu                                                                                                                               
        
      </p>

      <p class="TOC21">
        1.3. Systemy Informacyjne jako wyznacznik poziomu bezpieczeństwa
        biznesu                                                           
      </p>

      <p class="TOC21">
        1.4. Zagrożenia w zakresie bezpieczeństwa
        IT                                                                                                                   
        
      </p>

      <p class="TOC21">
        1.5. Główne cele bezpieczeństwa systemów
        IT                                                                                                                  
        
      </p>

      <p class="TOC11">
        2. Prezentacja polityki
        bezpieczeństwa                                                                                                                
        
      </p>

      <p class="TOC21">
        2.1.
        Cel                                                                                                                                                                                        
        
      </p>

      <p class="TOC21">
        2.2. Zasady bezpieczeństwa przy podejściu
        globalnym                                                                                                    
        
      </p>

      <p class="TOC21">
        2.3. Projektowanie bezpieczeństwa danych przez
        Administratora                                                                             
        
      </p>

      <p class="TOC21">
        2.4. Schemat
        zastosowania                                                                                                                                                   
        
      </p>

      <p class="TOC21">
        <span lang="ES-TRAD"
          >2.5. Przegląd polityki bezpieczeństwa systemów
          informacji                                                                                          </span
        >
      </p>

      <p class="TOC11">
        3. Podstawowe cele
        BEZPIECZEŃSTWA                                                                                                                     
        
      </p>

      <p class="TOC21">
        3.2. Rozporządzenie dotyczące danych
        Klienta                                                                                                                
        
      </p>

      <p class="TOC21">
        3.4. Umożliwienie śledzenia
        operacji                                                                                                                                   
        
      </p>

      <p class="TOC11">
        4. Polityka Ochrony danych
        osobowych                                                                                                            
        
      </p>

      <p class="TOC21">
        4.1. Ochrona danych osobowych u Administratora – procedury
        ochrony.                                                             
      </p>

      <p class="TOC31">
        4.1.1. Podstawy ochrony danych
        osobowych:                                                                                                                  
        
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Zasady ochrony
        danych                                                                                                                                   
        
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >1.1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Stosowane systemy ochrony
        danych                                                                                                            
        
      </p>

      <p class="TOC21" style="margin-left: 36.9pt; text-indent: -18.9pt">
        <span style="font-variant: normal !important">1.</span
        ><span lang="DE"
          >INWENTARYZACJA                                                                                                                                                     </span
        >
      </p>

      <p class="TOC31">
        <span lang="ES-TRAD"
          >4.2.1. Dane szczególnych kategorii i dane
          karne                                                                                                              </span
        >
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >1.1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Dane
        niezidentyfikowane                                                                                                                                 
        
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >1.2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Profilowanie                                                                                                                                                      
        
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >1.3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Współadministrowanie                                                                                                                                   
      
      </p>

      <p class="TOC21" style="margin-left: 36.9pt; text-indent: -18.9pt">
        <span style="font-variant: normal !important">2.</span
        ><span lang="PT"
          >REJESTR CZYNNOŚCI PRZETWARZANIA DANYCH (DALEJ:
          „RCPD”)                                                              </span
        >
      </p>

      <p class="TOC21" style="margin-left: 36.9pt; text-indent: -18.9pt">
        <span style="font-variant: normal !important">5.</span>PODSTAWY PRAWNE
        PRZETWARZANIA                                                                                                            
        
      </p>

      <p class="TOC21" style="margin-left: 36.9pt; text-indent: -18.9pt">
        <span style="font-variant: normal !important">6.</span
        ><span lang="DE"
          >PROCEDURY OBSŁUGI PRAW JEDNOSTKI I OBOWIĄZKÓW
          INFORMACYJNYCH                                      </span
        >
      </p>

      <p class="TOC21" style="margin-left: 36.9pt; text-indent: -18.9pt">
        <span style="font-variant: normal !important">7.</span
        ><span lang="DE"
          >OBOWIĄZKI
          INFORMACYJNE                                                                                                                                  </span
        >
      </p>

      <p class="TOC21" style="margin-left: 36.9pt; text-indent: -18.9pt">
        <span style="font-variant: normal !important">8.</span
        ><span lang="DE"
          >ŻĄDANIA OSÓB FIZYCZNYCH, KTÓRYCH DANE PRZETWARZA
          ADMINISTRATOR                                    </span
        >
      </p>

      <p class="TOC21" style="margin-left: 35.4pt; text-indent: -26.4pt">
        <span style="font-variant: normal !important"
          >1.1.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span lang="DE"
          >MINIMALIZACJA PRZETWARZANIA
          DANYCH                                                                                                     </span
        >
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >1.1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Minimalizacja dostępu do danych
        osobowych                                                                                            
        
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >1.2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Minimalizacja czasu przetwarzania
        danych                                                                                                
        
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >1.3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Minimalizacja zakresu przetwarzania
        danych                                                                                            
        
      </p>

      <p class="TOC21" style="margin-left: 0.4in; text-indent: -19.8pt">
        <span style="font-variant: normal !important"
          >10.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;
          </span></span
        ><span lang="DE"
          >BEZPIECZEŃSTWO PRZETWARZANIA DANYCH PRZEZ
          ADMINISTRATORA                                                </span
        >
      </p>

      <p class="TOC31">
        4.9.1. Analizy
        ryzyka                                                                                                                                                              
        
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >10.1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="ES-TRAD"
          >Oceny skutków dla ochrony
          danych                                                                                                              </span
        >
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >10.2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Środki bezpieczeństwa podejmowane przez
        Administratora                                                                    
        
      </p>

      <p class="TOC31" style="margin-left: 47.1pt; text-indent: -29.1pt">
        <span style="font-variant: normal !important"
          >10.3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        >Raportowanie
        naruszeń                                                                                                                                  
        
      </p>

      <p class="TOC21" style="margin-left: 0.3in; text-indent: -0.3in">
        <span style="font-variant: normal !important">1.1.</span>PODMIOTY
        PRZETWARZAJĄCE DANE OSOBOWE (TZW. „PROCESORY” LUB „PODMIOTY
        PRZETWARZAJĄCE”)      
      </p>

      <p class="TOC21" style="margin-left: 0.4in; text-indent: -19.8pt">
        <span style="font-variant: normal !important"
          >11.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;
          </span></span
        ><span lang="DE"
          >PRZESYŁANIE DANYCH DO PAŃSTW
          TRZECICH                                                                                                 </span
        >
      </p>

      <p class="TOC21" style="margin-left: 0.4in; text-indent: -19.8pt">
        <span style="font-variant: normal !important"
          >12.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;
          </span></span
        >PROJEKTOWANIE
        PRYWATNOŚCI                                                                                                                        
        
      </p>

      <p class="TOC11">
        <span lang="ES-TRAD"
          >5. Klasyfikacja
          dokumentów                                                                                                                                    </span
        >
      </p>

      <p class="TOC21">
        5.1. Własność, aktualizacja i
        przegląd                                                                                                                            
        
      </p>

      <p class="MsoNormal">
        <b
          ><span style="font-family: 'Arial', sans-serif"
            ><u
              ><span style="text-transform: uppercase"
                ><span style="text-decoration: none">&nbsp;</span></span
              ></u
            ></span
          ></b
        >
      </p>

      <div
        style="
          border: none;
          border-bottom: solid #e48312 1pt;
          padding: 0in 0in 0in 0in;
        "
      >
        <p class="Heading11">
          <span style="font-family: 'Arial', sans-serif"
            >                      
          </span>
        </p>
      </div>


      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <div
        style="
          border: none;
          border-bottom: solid #e48312 1pt;
          padding: 0in 0in 0in 0in;
          margin-left: 19.8pt;
          margin-right: 0in;
        "
      >
        <p class="Heading11" style="margin-left: 0.25in; text-indent: -0.25in">
          <a name="_Toc"
            ><span style="font-family: Symbol"
              >·<span style="font: 7pt 'Times New Roman'"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span></span
            ><span lang="IT" style="font-family: 'Arial', sans-serif"
              >Terminologia</span
            ></a
          >
        </p>
      </div>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 73.8pt; text-indent: -0.25in">
        <a name="_Toc1"
          ><span style="font-family: 'Arial Unicode MS', sans-serif"
            >o<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif">Wstęp</span></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Niniejszy dokument, zatytułowany „Polityka ochrony danych osobowych
          oraz Bezpieczeństwa System</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w Informacji” (dalej: „<b>Polityka</b>”) stanowi mapę wymog</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w, zasad i regulacji ochrony danych osobowych jak też bezpieczeństwa
          informacji w systemach używanych przez Administratora. Polityka
          stanowi opis zabezpieczania system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informacji Administratora, jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież politykę ochrony danych osobowych w rozumieniu rozporządzenia
          Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016
          r. w sprawie ochrony os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
          swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
          (og</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lne rozporządzenie o ochronie danych, dalej: „</span
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >RODO</span
          ></b
        ><span style="font-family: 'Arial', sans-serif">”).</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21">
        <a name="_Toc2"
          ><span style="font-family: 'Arial', sans-serif"
            >1.2. Podstawy stworzenia
            dokumentu                                          
          </span></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: 150%"
      >
        <span style="font-family: 'Arial', sans-serif"
          >Administrator, wypełniający we wskazany w niniejszym dokumencie
          spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b swoje obowiązki nałożone na niego przez RODO jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież przepisy wprowadzające RODO do polskiego porządku prawnego to w
          rozumieniu wyżej wskazanych akt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w prawnych r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">wnież:</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-bottom: 0in; text-indent: -0.25in; line-height: 150%"
      >
        <span style="font-family: 'Arial', sans-serif"
          >a)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >współpracownicy Administratora,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-bottom: 0in; text-indent: -0.25in; line-height: 150%"
      >
        <span style="font-family: 'Arial', sans-serif"
          >b)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >procesy biznesowe i metody pracy Administratora,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-bottom: 0in; text-indent: -0.25in; line-height: 150%"
      >
        <span style="font-family: 'Arial', sans-serif"
          >c)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >wiedza o Klientach Administratora,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-bottom: 0in; text-indent: -0.25in; line-height: 150%"
      >
        <span style="font-family: 'Arial', sans-serif"
          >d)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >partnerzy biznesowi Administratora i jego relacje z nimi.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-bottom: 0in; text-indent: -0.25in; line-height: 150%"
      >
        <span style="font-family: 'Arial', sans-serif">d)</span>
      </p>

      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >Zaufanie pomiędzy Klientami a naszą
          </span></b
        ><b
          ><span
            lang="ES-TRAD"
            style="font-family: 'Arial', sans-serif; color: #222222"
            >firm</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >ą</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> i</span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222">
            współpracownikami</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> oraz </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >nasze dziedzictwo</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >to elementy,</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >kt</span
          ></b
        ><b
          ><span
            lang="ES-TRAD"
            style="font-family: 'Arial', sans-serif; color: #222222"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >re sprawiają, że</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >wartość</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >Administratora</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif">
            wyróżnia nas i tworzy
          </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >naszą tożsamość</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif">, </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >odzwierciedla</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >naszą
          </span></b
        ><b
          ><span
            lang="SV"
            style="font-family: 'Arial', sans-serif; color: #222222"
            >kultur</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >ę</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >. Naszym obowiązkiem jest ich ochrona</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >.</span
          ></b
        >
      </p>

      <p class="MsoNormal" align="center" style="text-align: center"></p>

      <p class="Heading21">
        <a name="_Toc3"
          ><span style="font-family: 'Arial', sans-serif"
            >1.3. Systemy Informacyjne jako wyznacznik poziomu bezpieczeństwa
            biznesu
          </span></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Systemy informatyczne rozwijają się coraz bardziej każdego dnia,
          ułatwiają wymianę informacji. Z tych powod</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w Systemy Informatyczne Administratora stały się głównym narzędziem
          w:</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >- rozwoju i dzieleniu się naszym dziedzictwem, co pozwala nam być
          bardziej dynamicznymi i skutecznymi;</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >- tworzeniu i utrzymaniu z naszymi Klientami i pracownikami relacji
          trwałych i godnych zaufania, umożliwia to zapewnienie wysokiej
          wydajności oraz zapewnienie usług dostosowanych do potrzeb i
          zwyczaj</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w każdego człowieka.</span
        >
      </p>

      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><span style="font-family: 'Arial', sans-serif"
            >Nasz system IT jest kluczowym czynnikiem w rozwoju naszego
            dziedzictwa i rozwoju pełnego zaufania Klient</span
          ></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif">w.</span></b>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Jednak jesteśmy świadomi, że w dzisiejszych czasach nasze systemy IT
          podlegają wszelkiego rodzaju zagrożeniom, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re w razie wystąpienia incydentu mogą </span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">mie</span
        ><span style="font-family: 'Arial', sans-serif"
          >ć negatywne konsekwencje dla naszej działalności, w związku z czym
          dochowujemy należytej staranności by chronić je w odpowiedni
          spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b i codziennie stawiać czoła nowym wyzwaniom w tym zakresie jak
          r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież dążyć do nieustannego zwiększania bezpieczeństwa używanych
          przez nas system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informatycznych.</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21">
        <a name="_Toc4"
          ><span style="font-family: 'Arial', sans-serif"
            >1.4. Zagrożenia w zakresie bezpieczeństwa IT</span
          ></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Główne ryzyko związane z bezpieczeństwem IT jest otrzymywane na
          podstawie globalnej strategicznej mapy ryzyka. Głównymi zagrożeniami
          bezpieczeństwa IT są:</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >- niezdolność Systemu Informacji w momencie krytycznym dla
          biznesu;</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >- niezdolność do wykrywania nadużyć wewnętrznych w systemach
          informatycznych;</span
        >
      </p>

      <p class="MsoNormal">
        <span lang="RU" style="font-family: 'Arial', sans-serif">- b</span
        ><span style="font-family: 'Arial', sans-serif"
          >łędy decyzyjne z powodu błędnych danych finansowych;</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >- utrata danych lub ujawnienie zapis</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w danych Klienta;</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >- utrata przewagi konkurencyjnej w wyniku wycieku danych;</span
        >
      </p>

      <p class="MsoListParagraph">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><span style="font-family: 'Arial', sans-serif"
            >Nasze dziedzictwo i systemy informacji, kt</span
          ></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >re wspierają nasze krytyczne procesy biznesowe są uwzględnione w
            zagrożeniach bezpieczeństwa.</span
          ></b
        >
      </p>

      <p class="MsoListParagraph">
        <b><span style="font-family: 'Arial', sans-serif">&nbsp;</span></b>
      </p>

      <p class="Heading21">
        <a name="_Toc5"
          ><span style="font-family: 'Arial', sans-serif"
            >1.5. Główne cele bezpieczeństwa system</span
          ></a
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w IT</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Tak, aby uniknąć ryzyka, musimy chronić nasze wrażliwe systemy
          informacyjne w praktyce. Strategia ta jest zawarta w Polityce
          Bezpieczeństwa System</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w Informacji i odnosi się </span
        ><span lang="PT" style="font-family: 'Arial', sans-serif">do g</span
        ><span style="font-family: 'Arial', sans-serif">łównych cel</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w bezpieczeństwa, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re mają na celu zmniejszenie ryzyka na akceptowalnym poziomie.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Główne cele bezpieczeństwa są opisane szczegółowo w rozdziale 4
          niniejszego dokumentu.</span
        >
      </p>

      <p class="MsoNormal">
        <b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >&nbsp;</span
          ></b
        >
      </p>

      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >&nbsp;</span
          ></b
        >
      </p>

      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >Polityka Ochrony Danych Osobowych i Bezpieczeństwa System</span
          ></b
        ><b
          ><span
            lang="ES-TRAD"
            style="font-family: 'Arial', sans-serif; color: #222222"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >w Informacji</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >jest</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >podstawowym dokumentem</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif">
            bezpieczeństwa k</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >orporacyjnego</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif">
            Administratora, dostosowanym do s</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >trategicznych</span
          ></b
        ><b
          ><span lang="DE" style="font-family: 'Arial', sans-serif"> z</span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >agrożeń</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif">
            i dokumentem
          </span></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >sp</span
          ></b
        ><b
          ><span
            lang="ES-TRAD"
            style="font-family: 'Arial', sans-serif; color: #222222"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif; color: #222222"
            >jnym z</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif"> </span></b
        ><b
          ><span
            lang="ES-TRAD"
            style="font-family: 'Arial', sans-serif; color: #222222"
            >RODO.</span
          ></b
        >
      </p>

      <span
        style="
          font-size: 10.5pt;
          line-height: 110%;
          font-family: 'Arial Unicode MS', sans-serif;
          color: black;
        "
        ><br clear="all" style="page-break-before: always" />
      </span>

      <p class="MsoNormal">
        <span
          style="
            font-size: 10pt;
            line-height: 110%;
            font-family: 'Times New Roman', serif;
            color: windowtext;
          "
          >&nbsp;</span
        >
      </p>

      <div
        style="
          border: none;
          border-bottom: solid #e48312 1pt;
          padding: 0in 0in 0in 0in;
        "
      >
        <p class="Heading11">
          <a name="_Toc6"
            ><span style="font-family: 'Arial', sans-serif"
              >2. Prezentacja polityki bezpieczeństwa</span
            ></a
          >
        </p>
      </div>

      <p class="Heading21">
        <a name="_Toc7"
          ><span style="font-family: 'Arial', sans-serif">2.1. Cel</span></a
        >
      </p>

      <p class="MsoListParagraph" style="margin-left: 0.25in">
        <b><span style="font-family: 'Arial', sans-serif">&nbsp;</span></b>
      </p>

      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><span style="font-family: 'Arial', sans-serif"
            >Polityka Ochrony Danych Osobowych i Bezpieczeństwa System</span
          ></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >w Informacji Administratora ma ambicję do inspirowania, zachęcania
            i zwiększania zaufania wśr</span
          ></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif">d użytkownik</span></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >w (współpracownik</span
          ></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif">w, Klient</span></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif">w, partner</span></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >w) w systemach informacji i świadczonych usługach.</span
          ></b
        >
      </p>

      <p class="MsoListParagraph">
        <b><span style="font-family: 'Arial', sans-serif">&nbsp;</span></b>
      </p>

      <p class="Heading21">
        <a name="_Toc8"
          ><span style="font-family: 'Arial', sans-serif"
            >2.2. Zasady bezpieczeństwa przy podejściu globalnym</span
          ></a
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.25in;
          line-height: normal;
        "
      >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif"
          >Mając na myśli globalne bezpieczeństwo system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informacyjnych Administratora, wyróżniamy następujące zasady
          motywowania:</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif"
          >- realizm: polityka bezpieczeństwa IT zbudowana jest krok po kroku,
          dostosowana do poziomu wielkości Administratora, dążąc przy tym do
          stopniowej poprawy (podejście dynamiczne),</span
        >
      </p>

      <p class="MsoNormal" style="margin-bottom: 0in; line-height: normal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif"
          >- pragmatyzm: rozwiązania (zasady, środki, procedury) są stosowane w
          taki spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b, aby znaleźć odpowiedni kompromis pomiędzy efektywnością, prostotą
          i kontrolą koszt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w, koncentrując się na obsłudze klienta,</span
        >
      </p>

      <p class="MsoNormal" style="margin-bottom: 0in; line-height: normal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif"
          >- odpowiedzialność: organizacja systemu zarządzania bezpieczeństwem
          jest dostosowana do Administratora, autonomiczna i odpowiedzialna,
          działająca w synergii wsp</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">lnego interesu,</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span lang="IT" style="font-family: 'Arial', sans-serif">- sp</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >jność: działania os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b współpracujących z Administratorem są zgodne z bezpieczeństwem,
          obowiązującym na terenie działalności Administratora z uwzględnieniem
          poprawy współpracy i wsp</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lnej wizji (globalne podejście),</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif"
          >- przewidywanie: większe bezpieczeństwo przewidywania (w projektach
          IT, definicjach usług, tworzeniu nowych projekt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w lub ich ewolucji), bardziej określone działania i aplikacje mogą
          być dostosowane skutecznie i trwale,</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.25in;
          line-height: normal;
        "
      >
        <span style="font-size: 12pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p class="Heading21">
        <a name="_Toc9"
          ><span style="font-family: 'Arial', sans-serif"
            >2.3. Projektowanie bezpieczeństwa danych przez Administratora</span
          ></a
        >
      </p>

      <p class="MsoListParagraph">
        <b><span style="font-family: 'Arial', sans-serif">&nbsp;</span></b>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >Architektura bezpieczeństwa Administratora jest oparta na wzorcowym
          dokumencie odniesienia. Wzorzec ten składa się z:</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >- niniejszego dokumentu, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >ry określa strategiczne punkty powiązane z bezpieczeństwem u
          Administratora i przełożenie ich na fundamentalne cele: stanowi
          podstawy we wszystkich kwestiach bezpieczeństwa Administratora;</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">- standard</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w bezpieczeństwa definiujących stopnie bezpieczeństwa, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re będą osiągane przez realizację podstawowych cel</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w bezpieczeństwa określonych przez Administratora i to na różne
          sposoby, w tym przy użyciu narzędzi i najlepszych praktyk znanych
          Administratorowi;</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">- procedur i tryb</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w operacyjnych opisujących technicznie sposoby wdrożenia środk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w bezpieczeństwa.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.25in;
          line-height: normal;
        "
      >
        <span style="font-size: 12pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p class="MsoNormal" style="margin-bottom: 0in; line-height: normal">
        <span style="font-family: 'Arial', sans-serif"
          >Ta architektura bezpieczeństwa jest wdrożona u Administratora i
          przyjmuje ona formę Polityki Ochrony Danych Osobowych i Bezpieczeństwa
          System</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w Informacji tak, aby umożliwić realizację konkretnych cel</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w.</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.25in;
          line-height: normal;
        "
      >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21">
        <a name="_Toc10"
          ><span style="font-family: 'Arial', sans-serif"
            >2.4. Schemat zastosowania</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 0in; text-align: justify; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif"
          >Niniejszy dokument odnosi się do wszystkich system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informacyjnych, używanych przez Administratora, w tym w
          szczeg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">lnoś</span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">ci do:</span>
      </p>

      <p class="MsoNormal" style="margin-bottom: 0in; line-height: normal"></p>

      <p class="MsoNormal" style="margin-bottom: 0in; line-height: normal">
        <span style="font-family: 'Arial', sans-serif"
          >- wszystkich współpracownik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w Administratora;</span>
      </p>

      <p class="MsoNormal" style="margin-bottom: 0in; line-height: normal">
        <span style="font-family: 'Arial', sans-serif"
          >- wszystkich partner</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w (spółki handlowe, usługodawcy, podwykonawcy);</span
        >
      </p>

      <p class="MsoNormal" style="margin-bottom: 0in; line-height: normal">
        <span style="font-family: 'Arial', sans-serif">- wszystkich proces</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w i aplikacji;</span>
      </p>

      <p class="MsoNormal" style="margin-bottom: 0in; line-height: normal">
        <span style="font-family: 'Arial', sans-serif"
          >- wszystkich komponent</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informatycznych (komputery biurowe, laptopy, smartfony, tablety,
          itp).</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-bottom: 0in; line-height: normal"
      >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21">
        <a name="_Toc11"
          ><span style="font-family: 'Arial', sans-serif"
            >2.5. Przegląd polityki bezpieczeństwa system</span
          ></a
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w informacji</span>
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0.25in;
          line-height: normal;
        "
      >
        <span style="font-size: 12pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >W celu zapewnienia jej stałej przydatności, adekwatności i
          skuteczności, Polityka Ochrony Danych Osobowych i Bezpieczeństwa
          System</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w Informacji Administratora jest <b>uaktualniana co dwa lata</b>, lub
          w przypadku istotnych zmian przy procesie ponownej oceny jej
          zasadności i w procesie określenia ryzyk strategicznych.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-bottom: 0in; line-height: normal"
      >
        <span style="font-size: 12pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p class="MsoNormal" style="margin-bottom: 0in; line-height: normal">
        <span style="font-size: 12pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <div
        style="
          border: none;
          border-bottom: solid #e48312 1pt;
          padding: 0in 0in 0in 0in;
        "
      >
        <p class="Heading11">
          <a name="_Toc12"
            ><span style="font-family: 'Arial', sans-serif"
              >3. Podstawowe cele BEZPIECZEŃSTWA</span
            ></a
          >
        </p>
      </div>

      <p class="MsoListParagraph" style="margin-left: 0.25in">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span
          style="
            font-size: 14pt;
            line-height: 110%;
            font-family: 'Arial', sans-serif;
            color: #aa610d;
          "
          >3.1. Kultura bezpieczeństwa</span
        >
      </p>

      <p class="MsoNormal"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Osoby współpracujące z Administratorem są głównymi elementami w
          systemach bezpieczeństwa informacji. To oni stanowią trzon w strategii
          bezpieczeństwa. Jednak ich działania mogą r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież prowadzić do poważnych wypadk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w z powodu nieznajomości ryzyka i nieprzestrzegania najlepszych
          praktyk.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >W konsekwencji tego, powinien być realizowany program informacyjny i
          szkoleniowy tak, aby szerzyć </span
        ><span lang="SV" style="font-family: 'Arial', sans-serif">kultur</span
        ><span style="font-family: 'Arial', sans-serif"
          >ę bezpieczeństwa u wszystkich pracownik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w Administratora z uwzględnieniem os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b trzecich (partner</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w, podwykonawc</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w, itd.) przez cały okres spędzony u Administratora i na
          wyjeździe.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoListParagraph">
        <b><span style="font-family: 'Arial', sans-serif">&nbsp;</span></b>
      </p>

      <p class="Heading21">
        <a name="_Toc13"
          ><span style="font-family: 'Arial', sans-serif"
            >3.2. Rozporządzenie dotyczące danych Klienta</span
          ></a
        >
      </p>

      <p class="MsoListParagraph">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Systemy informatyczne są przedmiotem licznych regulacji prawnych (o
          ochronie danych osobowych, ochrony informacji finansowej) lub
          przepis</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w o ochronie informacji (płatność kartą kredytową).
        </span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Rozporządzenie nie jest opcją, lecz obowiązkiem. W związku z tym,
          monitorowanie regulacyjne odnoszące się do bezpieczeństwa IT musi być
          zgodne z lokalnymi przepisami prawnymi. Doradztwa w zakresie
          wymog</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w prawnych należy szukać u radc</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w prawnych. </span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >Co więcej, w systemach informacji muszą być stosowane wszystkie
          niezbędne środki bezpieczeństwa uwzględniające wymogi
          regulacyjne.</span
        >
      </p>

      <p class="MsoNormal"></p>

      <p class="MsoNormal">
        <span
          style="
            font-size: 14pt;
            line-height: 110%;
            font-family: 'Arial', sans-serif;
            color: #aa610d;
          "
          >3.3. Kontrola dostępu i zezwolenia</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >System Informacji przechowuje większość danych, co więcej,
          niekt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re informacje są w większym stopniu niż inne narażone na wyciek ze
          względu na swoją treść, ale r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież ze względu na nieustannie zmieniające się zagrożenia
          informatyczne. Niekt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">re spośr</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >d tych danych podlegają regulacji lub zobowiązaniom prawnym (dane
          Klienta itd.). Dostęp do informacji poufnych musi być w naturalny
          spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b ściśle ograniczony.</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >W związku z tym, procedury oraz działania operacyjne są wprowadzone w
          celu kontrolowania dostępu do systemu Informacji, tam, gdzie jest to
          konieczne. Są to następujące zasady:</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >- jednoznaczna identyfikacja użytkownik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w,</span>
      </p>

      <p class="MsoNormal" style="text-align: justify"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >- bezpieczne uwierzytelnianie użytkownik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w, co oznacza, że środki do autentyfikacji są osobiste i poziom
          bezpieczeństwa jest zapewniony,</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >- niższe przywileje, co oznacza, że użytkownicy posiadają uprawnienia
          dostosowane do ich stanowiska, nie mniej i nie więcej,</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >- potrzeba wiedzy - to oznacza, że użytkownicy mają dostęp tylko do
          tych usług niezbędnych do wykonywania swojej pracy, nie więcej i nie
          mniej.</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21">
        <a name="_Toc14"
          ><span style="font-family: 'Arial', sans-serif"
            >3.4. Umożliwienie śledzenia operacji</span
          ></a
        >
      </p>

      <p class="Heading21"></p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >Liczne wrażliwe operacje przechodzą przez system Informacyjny. Warto
          wymienić tutaj operacje finansowe, operacje na Kliencie lub
          zarządzanie pracownikami. Operacje te mają być monitorowane zgodnie z
          zaadaptowanym procesem przepływności.</span
        >
      </p>

      <p class="MsoNormal"></p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >W konsekwencji, możliwość śledzenia operacji wrażliwych jest
          zapewniana przez:</span
        >
      </p>

      <p class="MsoNormal"></p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif"
          >- definicję polityki zapisu log</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w dostosowanym do wagi operacji monitorowania i zgodności z
          obowiązującymi wymogami prawnymi,</span
        >
      </p>

      <p class="MsoNormal"></p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >- definiowanie i wdrażanie automatycznych rozwiązań do bezpiecznego
          zarządzania wszystkimi aspektami procesu zarządzania dziennikami
          (generowanie, gromadzenie, przechowywania, archiwizacji, czas
          przechowywania),</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <div
        style="
          border: none;
          border-bottom: solid #e48312 1pt;
          padding: 0in 0in 0in 0in;
        "
      >
        <p class="Heading11">
          <a name="_Toc15"
            ><span style="font-family: 'Arial', sans-serif"
              >4. Polityka Ochrony danych osobowych</span
            ></a
          >
        </p>
      </div>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Polityka w swojej treści przedstawia:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >a)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >opis zasad ochrony danych obowiązujących u Administratora,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >b)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >jeśli jest to niezbędne – r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież odwołania do załącznik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w uszczegółowiających (wzorcowe procedury lub instrukcje dotyczące
          poszczeg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">lnych obszar</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w z zakresu ochrony danych osobowych wymagających doprecyzowania w
          odrębnych dokumentach).</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Odpowiedzialny za wdrożenie i utrzymanie niniejszej Polityki jest
          zarząd Administratora, a w ramach zarządu:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >a)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >członek zarządu lub członkowie zarządu, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rym powierzono nadz</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >r nad obszarem ochrony danych osobowych,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >b)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >osoba wyznaczona przez zarząd do zapewnienia zgodności z ochroną
          danych osobowych.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Za stosowanie niniejszej Polityki odpowiedzialni są:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span lang="PT" style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="PT" style="font-family: 'Arial', sans-serif"
          >Administrator,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >wszyscy członkowie personelu Administratora.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator powinien też zapewnić zgodność </span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">post</span
        ><span style="font-family: 'Arial', sans-serif"
          >ępowania kontrahent</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w z niniejszą Polityką w odpowiednim zakresie, szczeg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lnie w przypadkach gdy mamy do czynienia z przekazaniem im danych
          osobowych przez Administratora. W tym celu Administrator zawiera z
          kontrahentami, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rzy uzyskują dostęp do danych osobowych klient</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w Administratora umowy o powierzenie przetwarzania danych
          osobowych.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21">
        <a name="_Toc16"
          ><span style="font-family: 'Arial', sans-serif"
            >4.1. Ochrona danych osobowych u Administratora – procedury
            ochrony.</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading31" style="text-align: justify">
        <a name="_Toc17"
          ><span style="font-family: 'Arial', sans-serif"
            >4.1.1. Podstawy ochrony danych osobowych:</span
          ></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b><span style="font-family: 'Arial', sans-serif">Legalność</span></b
        ><span style="font-family: 'Arial', sans-serif">
          – Administrator dba o ochronę prywatności i przetwarza dane zgodnie z
          prawem i jedynie na podstawie obowiązujących przepis</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w prawa.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Bezpieczeństwo</span
          ></b
        ><span style="font-family: 'Arial', sans-serif">
          – Administrator zapewnia poziom bezpieczeństwa danych odpowiadający
          sektorowi jego działalności, podejmując stale działania w tym zakresie
          (Administrator korzysta w tym zakresie z usług oferowanych przez
          podmioty zawodowo trudniące się problematyką ochrony danych, takich
          jak kancelarie prawne).</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b><span style="font-family: 'Arial', sans-serif">Prawa</span></b
        ><span style="font-family: 'Arial', sans-serif"> <b>os</b></span
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif">b fizycznych</span></b
        ><span style="font-family: 'Arial', sans-serif"> – </span
        ><span lang="PT" style="font-family: 'Arial', sans-serif"
          >Administrator umo</span
        ><span style="font-family: 'Arial', sans-serif"
          >żliwia osobom fizycznym, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane przetwarza, wykonywanie swoich praw przyznanych przez
          przepisy RODO i realizuje te prawa, stosując się do wszystkich,
          opisanych w niniejszej Polityce stadi</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w ochrony danych.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Rozliczalność</span
          ></b
        ><span style="font-family: 'Arial', sans-serif">
          – Administrator dokumentuje to, w jaki spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b spełnia obowiązki, aby w każdej chwili m</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >c wykazać zgodność. Dokumentacja przechowywana jest w miejscach
          odpowiednio chronionych, przy zachowaniu zasad bezpieczeństwa przed
          wyciekiem danych.</span
        >
      </p>

      <p class="MsoListParagraph">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading31" style="margin-left: 1.5in; text-indent: -14.55pt">
        <a name="_Toc18"
          ><span style="font-family: 'Arial', sans-serif"
            >i.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Zasady ochrony danych</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator przetwarza dane osobowe mając na uwadze przede
          wszystkim, by przetwarzanie danych następował</span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">o:</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >5.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >w oparciu o podstawę prawną i zgodnie z prawem (legalizm),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >rzetelnie i z poszanowaniem praw jednostki (rzetelność),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >7.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif">w spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b przejrzysty dla osoby, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej dane dotyczą, mając na uwadze, że osoby fizyczne mają ograniczoną
          czas na zaznajomienie się ze sposobami przetwarzania danych,
          stosowanymi przez Administratora (transparentność),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >8.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >w konkretnych celach i nie w celu bliżej niesprecyzowanych cel</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w – przetwarzanie danych „na przyszłość” (minimalizacja),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >9.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >jedynie w takim zakresie, jaki jest niezbędny (adekwatność),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >z dbałością o to, by przetwarzane przez Administratora dane były
          zgodne z rzeczywistością (prawidłowość),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >11.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >nie dłużej niż jest to niezbędne do wykonania obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w wynikających ze stosunku prawnego lub faktycznego łączącego
          Administratora z drugą stroną i jedynie w takim zakresie, w jakim
          Administrator powiadomił osobę fizyczną o czasie, w jakim dane będą
          przetwarzane (czasowość),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >12.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >zapewniając odpowiednie bezpieczeństwo danych z uwagi na potencjalne
          ryzyka i zagrożenia związane z operacjami, dokonywanymi na danych
          osobowych (bezpieczeństwo).</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading31" style="margin-left: 1.5in; text-indent: -14.55pt">
        <a name="_Toc19"
          ><span style="font-family: 'Arial', sans-serif"
            >i.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Stosowane systemy ochrony danych</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >System ochrony danych osobowych u Administratora składa się przede
          wszystkim takich składnik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w, jak:</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Inwentaryzacja</span
          ></b
        ><span style="font-family: 'Arial', sans-serif">
          <b>danych</b>. Administrator dokonuje identyfikacji zasob</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w danych osobowych, klas danych, zależności między zasobami danych,
          identyfikacji sposob</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w wykorzystania danych (inwentaryzacja), w tym:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif">przypadk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzania danych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b niezidentyfikowanych przez Administratora (dane
          niezidentyfikowane),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif">przypadk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzania danych dzieci,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif">profilowania,</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Rejestr Przetwarzania Danych Osobowych</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator opracowuje, prowadzi i utrzymuje rejestr czynności
          dokonywanych na danych osobowych u Administratora (dalej:
          „<b>Rejestr</b>” lub „</span
        ><b
          ><span lang="PT" style="font-family: 'Arial', sans-serif"
            >RCPD</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >”). Rejestr jest narzędziem rozliczania zgodności przetwarzania
          danych osobowych u Administratora z powszechnie obowiązującymi
          przepisami prawa.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Podstawy prawne</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator zapewnia, identyfikuje oraz weryfikuje podstawy
          prawne przetwarzania danych i rejestruje je w Rejestrze, w tym:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >utrzymuje system zarządzania zgodami na przetwarzanie danych i
          komunikację na odległość, by w prosty spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b zdeterminować możliwość komunikacji z osobami fizycznymi w
          określonych celach;</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >uzasadnia przypadki, gdy Administrator przetwarza dane na podstawie
          prawnie uzasadnionego interesu Administratora.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Obsługa praw jednostki</span
          ></b
        ><span lang="PT" style="font-family: 'Arial', sans-serif"
          >. Administrator spe</span
        ><span style="font-family: 'Arial', sans-serif"
          >łnia obowiązki informacyjne wzglę</span
        ><span lang="PT" style="font-family: 'Arial', sans-serif">dem os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane przetwarza oraz zapewnia obsługę ich praw (art. 12 ust. 3
          RODO), realizując otrzymane w tym zakresie żądania, w tym:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >obowiązek informacyjny</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator przekazuje osobom wymagane informacje przy zbieraniu
          danych i w innych sytuacjach (na początkowym etapie wdrażania
          przepis</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w RODO, Administrator legalizuje istniejącą bazę danych w zakresie w
          jakim chodzi o powiadomienie o nowych uprawnieniach przyznanych osobom
          fizycznym przez RODO) oraz organizuje i zapewnia udokumentowanie
          realizacji tych obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w, tak by m</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >c wykazać ich wypełnienie w przypadku ewentualnej kontroli Urzędu
          Ochrony Danych Osobowych,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Wykonanie żądań os</span
          ></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif">b fizycznych</span></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator zapewnia możliwość wykonania żądań kierowanych do
          niej przez osoby fizyczne, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane osobowe przetwarza zar</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wno przez siebie i swoich przetwarzających (obowiązki procesor</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w nałożone w drodze um</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w o powierzenie przetwarzania danych osobowych),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >obsługa żądań os</span
          ></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b
          ><span style="font-family: 'Arial', sans-serif">b fizycznych</span></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator zapewnia odpowiednie nakłady finansowe i personelowi,
          jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież procedury, aby żądania os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b były realizowane w terminach i w spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b wymagany RODO, jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież by ich wykonanie zostało każdorazowo udokumentowane we właściwy
          spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b,</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.5.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >zawiadamianie o naruszeniach</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator stosuje procedury, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re pozwalają ustalić konieczność zawiadomienia os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b dotkniętych zidentyfikowanym naruszeniem ochrony danych. W tym celu
          członek zarządu w osobie do tego wyznaczonej, nadzoruje procesy
          przetwarzania danych w ten spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b, by zawiadomienie o naruszeniach mogło nastąpić niezwłocznie,
          jednak zawsze w terminach nie późniejszych niż określone w powszechnie
          obowiązujących przepisach prawa.</span
        >
      </p>

      <p class="MsoListParagraph" style="margin-left: 1in; text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >5.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Minimalizacja</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator wdrożył zasady i metody kompatybilne z określoną
          przepisami RODO zasadą minimalizacji, w ten spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b by nie przetwarzać danych osobowych zbędnych i nadmiarowych.
          Administrator poprzez zasadę minimalizacji dąży, by w jej bazie danych
          nie znajdowały się dane, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re nie są absolutnie niezbędne do poprawnego wykonywania
          stosunk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w prawnych i faktycznych łączących ją z jej klientami i kontrahentami
          (<i>privacy by default</i>), a w tym:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >5.2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >zasady pomagające efektywnie zarządzać adekwatnością danych już na
          etapie zbierania danych (formularze przystosowane do niepobierania
          danych nadmiarowych),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >5.3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >zasady zarządzania dostępem do danych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b fizycznych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re o taki dostęp wnioskują, poprzez odpowiednie przeszkolenie
          os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b odpowiedzialnych za te kwestie na terenie działalności
          Administratora jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież przygotowanie odpowiedniej procedury działania,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >5.4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >zasady zarządzania okresem przechowywania danych i weryfikacji
          dalszej przydatności, a w efekcie niezwłocznego usuwania danych
          osobowych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b fizycznych, gdy wygaśnie podstawa prawna do takiego
          działania.</span
        >
      </p>

      <p class="MsoListParagraph" style="margin-left: 1in; text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Bezpieczeństwo</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator zapewnia odpowiedni poziom bezpieczeństwa danych, w
          tym:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >przeprowadza niezbędne  analizy ryzyka dla czynności przetwarzania
          danych lub ich kategorii, stosując przy tym odpowiednią </span
        ><span lang="DA" style="font-family: 'Arial', sans-serif">skal</span
        ><span style="font-family: 'Arial', sans-serif"
          >ę ryzyk, stanowiącą załącznik do Rejestru Czynności Przetwarzania
          Danych,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >przeprowadza oceny skutk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w dla ochrony danych tam, gdzie ryzyko naruszenia praw i wolności
          os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b jest wysokie ze względu na ich charakter lub miejsce
          przechowywania,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >dostosowuje środki ochrony danych do ustalonego ryzyka,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.5.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >posiada wewnętrzne procedury zarządzania bezpieczeństwem
          informacji,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.6.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >stosuje procedury pozwalające na identyfikację, ocenę i zgłoszenie
          zidentyfikowanego naruszenia ochrony danych Urzędowi Ochrony Danych –
          zarządza incydentami.</span
        >
      </p>

      <p class="MsoListParagraph" style="margin-left: 1in; text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >7.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Podmioty Przetwarzające</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator posiada zasady weryfikacji podmiot</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzających dane na rzecz Administratora, wymog</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w co do warunk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzania (w tym celu z każdym podmiotem przetwarzającym dane
          osobowe powierzone przez Administratora zawierana jest umowa o
          powierzenie przetwarzania danych osobowych), zasad weryfikacji
          wykonywania um</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w powierzenia, przede wszystkim poprzez stosowanie wymog</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przedstawienia przez podmioty przetwarzające stosowanych przez
          Administratora procedur zabezpieczenia, będących załącznikami do
          um</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w powierzenia przetwarzania danych w imieniu Administratora.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >8.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Przekazywanie danych do państw trzecich</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator weryfikuje czy dane osobowe os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b fizycznych nie są przekazywane do państw trzecich (tj. poza teren
          Unii Europejskiej, Norwegii, Lichtensteinu i Islandii) lub do
          organizacji międzynarodowych oraz zapewnia zgodne z prawem warunki
          takiego przekazywania, jeśli ma ono miejsce.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >9.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Privacy by design</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator zarządza zmianami wpływającymi na prywatność i
          kontroluje je w odpowiedni ze względu na przepisy o ochronie danych
          osobowych spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b. W tym celu procedury uruchamiania nowych projekt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w i inwestycji przez Administratora uwzględniają konieczność oceny
          wpływu zmiany na ochronę danych, analizę ryzyka, zapewnienie
          prywatności (a w tym zgodnoś</span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">ci cel</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzania, bezpieczeństwa danych i minimalizacji) już w fazie
          projektowania zmiany, inwestycji czy na początku nowego
          projektu.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Przetwarzanie transgraniczne</span
          ></b
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
          >. Administrator ka</span
        ><span style="font-family: 'Arial', sans-serif"
          >żdorazowo weryfikuje czy nie zachodzi przypadek transgranicznego
          przetwarzania danych osobowych, by w tym celu wypełnić wszystkie
          prawne obowiązki nakładane w związku z tym na administratora.</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 0.6in; text-indent: -0.35in">
        <a name="_Toc20"
          ><span style="font-family: 'Arial', sans-serif"
            >10.1.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span lang="DE" style="font-family: 'Arial', sans-serif"
            >INWENTARYZACJA</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading31" style="margin-left: 14.2pt; text-align: justify">
        <a name="_Toc21"
          ><span style="font-family: 'Arial', sans-serif"
            >4.2.1. Dane szczeg</span
          ></a
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lnych kategorii i dane karne</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator nie identyfikuje przypadk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w, w kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych przetwarza lub może przetwarzać dane szczeg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lnych kategorii lub dane karne w związku z czym nie jest niezbędne
          utrzymywanie mechanizm</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w dedykowanych zapewnieniu zgodności przetwarzania tych kategorii
          danych osobowych z prawem.</span
        >
      </p>

      <p
        class="Heading31"
        style="margin-left: 1.5in; text-align: justify; text-indent: -14.55pt"
      >
        <a name="_Toc22"
          ><span style="font-family: 'Arial', sans-serif"
            >i.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Dane niezidentyfikowane</span
          ></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator identyfikuje przypadki, w kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych przetwarza lub może przetwarzać dane niezidentyfikowane, w
          związku z czym, gdy zachodzi taka konieczność, podejmuje wszystkie
          niezbędne czynności ułatwiające realizację praw os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dotyczą dane niezidentyfikowane.</span
        >
      </p>

      <p
        class="Heading31"
        style="margin-left: 0.75in; text-align: justify; text-indent: -0.5in"
      >
        <a name="_Toc23"
          ><span style="font-family: 'Arial', sans-serif"
            >10.1.2.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Profilowanie
          </span></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator identyfikuje przypadki, w kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dokonuje profilowania przetwarzanych danych w związku z czym
          podejmuje wszelkie środki i starania, by ten proces odbywał się
          zgodnie z prawem i poszanowaniem praw os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b fizycznych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane są przetwarzane.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="Heading31"
        style="margin-left: 0.75in; text-align: justify; text-indent: -0.5in"
      >
        <a name="_Toc24"
          ><span style="font-family: 'Arial', sans-serif"
            >10.1.3.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Współadministrowanie
          </span></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator nie identyfikuje przypadk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w współadministrowania danymi osobowymi.</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 0.6in; text-indent: -0.35in">
        <a name="_Toc25"
          ><span style="font-family: 'Arial', sans-serif"
            >10.2.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span lang="DE" style="font-family: 'Arial', sans-serif"
            >REJESTR CZYNNO</span
          ></a
        ><span style="font-family: 'Arial', sans-serif"
          >ŚCI PRZETWARZANIA DANYCH (DALEJ: „</span
        ><b
          ><span lang="PT" style="font-family: 'Arial', sans-serif"
            >RCPD</span
          ></b
        ><span style="font-family: 'Arial', sans-serif">”)</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >RCPD Stanowi  formę dokumentowania czynności przetwarzania danych,
          pełni rolę mapy przetwarzania danych i jest jednym z podstawowych
          element</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w umożliwiających realizację fundamentalnej zasady, na kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej opiera się cały system ochrony danych osobowych, czyli zasady
          rozliczalności tak, by nie tylko podmioty kontrolujące przetwarzanie
          danych mogły w czytelny spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b określić </span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b wykonywania obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w nałożonych na administratora danych, ale r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">wnież </span
        ><span lang="PT" style="font-family: 'Arial', sans-serif"
          >administrator m</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >gł zidentyfikować wewnętrzne naruszenia i reagował na nie.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator prowadzi RCPD, w kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rym inwentaryzuje i nadzoruje sposoby, w jakie wykorzystuje dane
          osobowe.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >RCPD jest, obok niniejszego dokumentu, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >ry Administrator przekazuje współpracownikom w celach edukacyjnych i
          informacyjnych, jednym z podstawowych narzędzi umożliwiających
          Administratorowi rozliczanie większości obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w ochrony danych.</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >W RCPD dla każdej czynności przetwarzania danych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rą Administrator uznał za odrębną dla potrzeb RCPD, Administrator
          odnotowuje co najmniej:</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif">nazwę czynnoś</span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">ci,</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.5.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >jednostkę organizacyjną</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.6.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >cel przetwarzania,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.7.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif">kategorie os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b,</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.8.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif">kategorie danych,</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.9.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >podstawę prawną przetwarzania,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.10.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif">źr</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">dło danych,</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.11.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >planowany termin usunięcia kategorii danych,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.12.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >nazwę współadministratora i jego dane kontaktowe (jeśli
          dotyczy),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.13.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >nazwę podmiotu przetwarzającego i jego dane kontaktowe (jeśli
          dotyczy)</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.14.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif">kategorie odbiorc</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w (jeśli dotyczy),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.15.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >nazwę systemu lub oprogramowania, używanego przy przetwarzaniu danych
          osobowych,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span lang="DA" style="font-family: 'Arial', sans-serif"
          >4.16.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span lang="DA" style="font-family: 'Arial', sans-serif">Og</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lny opis technicznych i organizacyjnych środk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w bezpieczeństwa zgodnie z art. 32 ust. 1,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.17.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Transfer do kraju trzeciego lub organizacji międzynarodowej (nazwa
          kraju i podmiotu),</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.18.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Jeśli transfer i art. 49 ust. 1 akapit drugi - dokumentacja
          odpowiednich zabezpieczeń.</span
        >
      </p>

      <p class="MsoListParagraph" style="margin-left: 1in; text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span lang="DE" style="font-family: 'Arial', sans-serif"
          >5.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="DE" style="font-family: 'Arial', sans-serif">Wz</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >r RCPD stanowi Załącznik nr 1 do Polityki – „</span
        ><span lang="DE" style="font-family: 'Arial', sans-serif">Wz</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >r Rejestru Czynności Przetwarzania Danych”</span
        ><span lang="DE" style="font-family: 'Arial', sans-serif">, Wz</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >r RCPD zawiera także kolumny niewymagane prawem. W kolumnach
          nieobowiązkowych Administrator rejestruje informacje w miarę potrzeb i
          możliwości, z uwzględnieniem tego, ż</span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">e pe</span
        ><span style="font-family: 'Arial', sans-serif">łniejsza treść </span
        ><span lang="PT" style="font-family: 'Arial', sans-serif">RCPD u</span
        ><span style="font-family: 'Arial', sans-serif"
          >łatwia zarządzanie zgodnością ochrony danych i rozliczenia z niej. 
          Do rejestru Administrator dołącza skalę ryzyk, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >ra w pełniejszy spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b pozwala określić zagrożenia związane z przetwarzaniem konkretnych
          kategorii danych, by w najlepszy możliwy spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b dopasować środki ochrony do kategorii przetwarzanych danych.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 1in; text-indent: -0.25in">
        <a name="_Toc26"
          ><span style="font-family: 'Arial', sans-serif"
            >a.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >PODSTAWY PRAWNE PRZETWARZANIA</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator dokumentuje w RCPD podstawy prawne przetwarzania danych
          dla poszczeg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lnych czynności przetwarzania, by m</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >c dostosowywać rejestr do nowelizacji akt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w prawnych, z kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych wynikają obowiązki.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Poprzez wskazanie w dokumentach og</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lnej podstawy prawnej (zgoda, umowa, obowiązek prawny, żywotne
          interesy, uzasadniony cel Administratora), Administrator dookreśla
          podstawę w precyzyjny spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b, gdy jest to potrzebne i niezbędne ze względu na kategorię danych i
          zasadę przejrzystości. W ten spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b Administrator wskazuje np. zakres uzyskiwanej zgody, przedstawiając
          jednocześnie cel, w jakim jest ona uzyskiwana, a gdy podstawą jest
          prawo – wskazując konkretny przepis i inne dokumenty, np. umowę,
          porozumienie administracyjne itp. – wskazując kategorie zdarzeń, w
          kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych się zmaterializują, uzasadniony cel – wskazując konkretny cel,
          np. marketing bezpośredni, obronę przed roszczeniami jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież możliwość ich dochodzenia.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator wdraża metody zarządzania zgodami, umożliwiające
          rejestrację i weryfikację posiadania zgody osoby na przetwarzanie jej
          konkretnych danych w konkretnym celu na każdym etapie przetwarzania
          danych, zgody na komunikację na odległość zgodnie z przepisami ustawy
          z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004 Nr 171
          poz. 1800) oraz ustawy z dnia 18 lipca 2002 r. o świadczeniu usług
          drogą elektroniczną (Dz.U. 2002 Nr 144 poz. 1204) jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież rejestracją odmowy zgody, cofnięcia zgody i podobnych czynności
          (sprzeciw, żądanie usunięcia danych itp.).</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoListParagraph">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 1in; text-indent: -0.25in">
        <a name="_Toc27"
          ><span style="font-family: 'Arial', sans-serif"
            >a.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >PROCEDURY OBSŁUGI PRAW JEDNOSTKI I OBOWIĄZKÓ</span
          ></a
        ><span lang="DE" style="font-family: 'Arial', sans-serif"
          >W INFORMACYJNYCH</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator dba o czytelność przekazywanych informacji i
          komunikacji z osobami, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane przetwarza, tak by mieć pewność, że osoba zapoznała się z
          przekazywanymi informacjami oraz że w pełni zrozumiała ich treść. W
          tym celu Administrator współpracuje z podmiotami zewnętrznymi (radcy
          prawni) w celu stworzenia obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informacyjnych o treści możliwie najbardziej przejrzystej i zgodnej
          z przepisami powszechnie obowiązującego prawa.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span lang="PT" style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span lang="PT" style="font-family: 'Arial', sans-serif"
          >Administrator u</span
        ><span style="font-family: 'Arial', sans-serif"
          >łatwia osobom korzystanie z ich praw poprzez działania takie jak:
          umieszczanie na stronie internetowej Administratora link</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w do informacji o prawach os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b, sposobie korzystania z nich na terenie działalności
          Administratora, jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież metodach kontaktu z Administratorem w tym celu.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator dba o dotrzymywanie prawnych termin</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w realizacji obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w wzglę</span
        ><span lang="PT" style="font-family: 'Arial', sans-serif">dem os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b fizycznych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane osobowe przetwarza poprzez stosowanie odpowiednich procedur
          i formularzy, za pomocą kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych udziela odpowiedzi na żądania i pytania kierowane do
          Administratora w przedmiocie ochrony danych osobowych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b fizycznych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane są przetwarzane.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator wprowadza adekwatne metody identyfikacji os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b dla potrzeb realizacji praw jednostki i obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informacyjnych w ten spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b, by osoby nieuprawnione nie uzyskały dostępu do danych osobowych,
          kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re ich nie dotyczą.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >5.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >W celu realizacji praw jednostki, Administrator zapewnia procedury i
          mechanizmy pozwalające zidentyfikować dane konkretnych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b, przetwarzane przez Administratora, w celu skutecznej odpowiedzi na
          żądania os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b fizycznych, udostępniając im dane osobowe ich dotyczące jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież dając im możliwość skorzystania z takich uprawnień jak
          sprostowanie danych, ich usunięcie czy przeniesienie (w takim
          zakresie, w jakim to możliwe).</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator dokumentuje obsługę obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informacyjnych, zawiadomień i żądań os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b fizycznych w celu zachowania transparentności działania
          Administratora w dziedzinie ochrony danych osobowych.</span
        >
      </p>

      <p class="MsoListParagraph">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 1in; text-indent: -0.25in">
        <a name="_Toc28"
          ><span style="font-family: 'Arial', sans-serif"
            >a.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif">OBOWIĄ</span></a
        ><span lang="DE" style="font-family: 'Arial', sans-serif"
          >ZKI INFORMACYJNE
        </span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator, w porozumieniu z podmiotami zewnętrznymi (radcy
          prawni) określa zgodne z prawem i skuteczne sposoby wykonywania
          obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w informacyjnych.</span>
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator informuje osobę o przedłużeniu ponad jeden miesiąc
          terminu na rozpatrzenie żądania tej osoby (art. 12 ust. 3 RODO) w
          przypadku, gdy rozpatrzenie jej żądania przed upływem tego terminu
          jest niemożliwe.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator informuje osobę o przetwarzaniu jej danych, w sytuacji,
          gdy dane osobowe pozyskane są bezpośrednio od tej osoby.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator informuje osobę o przetwarzaniu danych osobowych,
          r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież w sytuacji, gdy dane osobowe pozyskane są niebezpośrednio od
          tej osoby.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >5.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator okreś</span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">la spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b informowania os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b o przetwarzaniu danych niezidentyfikowanych, jeśli tylko jest to
          możliwe (np. informacja przy wejściu do budynku o objęciu obszaru
          monitoringiem wizyjnym).</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator informuje osobę o planowanej zmianie celu przetwarzania
          danych, jeśli zachodzi taka sytuacja.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >7.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator informuje odbiorc</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w danych o sprostowaniu, usunięciu lub ograniczeniu przetwarzania
          danych osobowych (chyba, że wymagałoby to niewspółmiernie dużego
          wysiłku lub byłoby niemożliwe).</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >8.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator informuje osobę o prawie sprzeciwu jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież wszystkich przysługujących jej prawach, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">rych źr</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >dłem jest art. 13 lub 14 RODO, względem przetwarzania jej danych
          osobowych najpóźniej przy pierwszym kontakcie z tą osobą.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >9.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator bez zbędnej zwłoki zawiadamia osobę o naruszeniu
          ochrony danych osobowych, jeżeli może ono powodować wysokie ryzyko
          naruszenia praw lub wolności tej osoby.</span
        >
      </p>

      <p class="MsoListParagraph">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoListParagraph">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 1in; text-indent: -0.25in">
        <a name="_Toc29"
          ><span style="font-family: 'Arial', sans-serif"
            >a.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif">ŻĄ</span></a
        ><span lang="PT" style="font-family: 'Arial', sans-serif">DANIA OS</span
        ><span style="font-family: 'Arial', sans-serif">Ó</span
        ><span lang="DE" style="font-family: 'Arial', sans-serif"
          >B FIZYCZNYCH, KT</span
        ><span style="font-family: 'Arial', sans-serif">Ó</span
        ><span lang="DE" style="font-family: 'Arial', sans-serif"
          >RYCH DANE PRZETWARZA ADMINISTRATOR</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b><span style="font-family: 'Arial', sans-serif">Prawa os</span></b
        ><b
          ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
            >ó</span
          ></b
        ><b><span style="font-family: 'Arial', sans-serif">b trzecich</span></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Realizując prawa os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane dotyczą, Administrator wprowadza gwarancje ochrony praw
          os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b trzecich w przedmiocie ochrony ich danych osobowych. W sytuacji gdy
          np. wykonanie żądania osoby o wydanie kopii danych lub prawa do
          przeniesienia danych mogłoby wpłynąć niekorzystnie na prawa i wolności
          innych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b lub w spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b istotny naruszyć ich interesy prawne (np. prawa związane z ochroną
          danych innych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b gdy Administrator musiałby udostępnić dokumenty zawierające dane
          osobowe zainteresowanego, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">re zawierają r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież dane osobowe innych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re nie mogą być z różnych względ</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w zanonimizowane, prawa własności intelektualnej, tajemnicę handlową
          lub dobra osobiste), Administrator może się zwr</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >cić do osoby w celu wyjaśnienia wątpliwości lub odm</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">wić </span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif"
          >zado</span
        ><span style="font-family: 'Arial', sans-serif"
          >śćuczynienia żądaniu.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Odmowa zadośćuczynienia żądaniu</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator, w drodze przesłania odpowiedniego formularza,
          informuje osobę, w ciągu miesiąca od otrzymania żądania, o odmowie
          rozpatrzenia żądania i o prawach osoby z tym związanych w przypadku
          gdy z różnych względ</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w, o kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych mowa w niniejszym dokumencie lub wynikających bezpośrednio z
          powszechnie obowiązujących przepis</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w prawa (np. obowiązki podatkowe) spełnienie żądania osoby jest
          niemożliwe.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Dostęp do danych osobowych</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Na żądanie osoby dotyczące dostępu do jej danych, Administrator
          informuje osobę, czy przetwarza jej dane oraz informuje osobę o
          szczegółach przetwarzania, zgodnie z art. 15 RODO (zakres
          przetwarzania odpowiada obowiązkowi informacyjnemu przy zbieraniu
          danych). Administrator umożliwia dostęp do danych osobowych osobie,
          kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >ra o to wnioskuje, jednak tylko w przypadku gdy nie zagraża to
          naruszeniem danych osobowych innych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b (brak możliwości zanonimizowania danych osobowych niedotyczących
          bezpośrednio osoby kierującej żądanie lub ryzyko udostępnienia
          tajemnicy handlowej itp.). Dostęp do danych może być zrealizowany
          przez wydanie kopii danych, z zastrzeżeniem, że każda kolejna (po
          pierwszej) kopia danych osobowych jest kopią, za kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">rą </span
        ><span lang="PT" style="font-family: 'Arial', sans-serif"
          >Administrator mo</span
        ><span style="font-family: 'Arial', sans-serif"
          >że pobrać odpowiednie opłaty, uzasadnione nakładem pracy związanym z
          jej uzyskaniem i wydaniem osobie zainteresowanej.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Zaprzestanie przetwarzania</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator informuje osobę o tym, że nie przetwarza danych jej
          dotyczących, jeśli taka osoba zgłosiła żądanie dotyczące jej
          praw.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >5.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Sprostowanie danych</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator dokonuje sprostowania nieprawidłowych danych na
          żądanie osoby fizycznej, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej dane osobowe przetwarzane przez Administratora dotyczą.
          Administrator ma prawo odm</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wić sprostowania danych, chyba że osoba w rozsądny spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b wykaże nieprawidłowość danych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych sprostowania się domaga.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >6.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Uzupełnienie danych</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator uzupełnia i aktualizuje dane na żądanie osoby.
          Administrator ma prawo odm</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wić uzupełnienia danych, jeżeli uzupełnienie byłoby niezgodne z
          celami przetwarzania danych w związku z faktem przekazanych już osobie
          fizycznej dokument</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informującym ją o celach przetwarzania (np. Administrator nie
          powinien zgodnie z niniejszym dokumentem przetwarzać danych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re są zbędne lub nadmiarowe). Administrator może polegać na
          oświadczeniu osoby co do uzupełnianych danych, chyba że będzie to
          niewystarczające w świetle przyjętych przez Administratora procedur
          (np. co do pozyskiwania takich danych), prawa lub zaistnieją
          okoliczności faktyczne uzasadniające obawy, że oświadczenie osoby,
          kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >ra kieruje żądanie jest niewiarygodne.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >7.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif">Kopie danych</span></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Na żądanie, Administrator wydaje osobie kopię danych jej
          dotyczących i odnotowuje fakt wydania pierwszej kopii danych, z
          zastrzeżeniem sytuacji zawartych w niniejszym dokumencie, związanych z
          możliwością naruszenia danych osobowych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b trzecich.</span>
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >8.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Przenoszenie danych</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Na żądanie osoby, Administrator wydaje w powszechnie używanym
          formacie nadającym się do odczytu komputerowego lub przekazuje innemu
          podmiotowi, <b>jeśli jest to możliwe</b>, dane dotyczące tej osoby,
          kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re dostarczyła ona Administratorowi, przetwarzane na podstawie zgody
          tej osoby lub w celu zawarcia lub wykonania umowy z nią zawartej w
          systemach informatycznych Administratora.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >9.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Prawo do odwołania przy przetwarzaniu danych osobowych</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Jeżeli Administrator przetwarza dane w spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b automatyczny, w tym w szczeg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lności profiluje osoby, Administrator zapewnia jednocześnie możliwość
          odwołania się do decyzji współpracownika lub członka zarządu,
          upoważnionego do tego typu działania po stronie Administratora, chyba
          że taka automatyczna decyzja
        </span>
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >9.8.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >jest niezbędna do zawarcia lub wykonania umowy między odwołującą się
          osobą </span
        ><span lang="PT" style="font-family: 'Arial', sans-serif"
          >a Administratorem,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >9.9.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >jest wprost dozwolona przepisami prawa,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >9.10.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >opiera się na wyraźnej zgodzie odwołującej osoby.</span
        >
      </p>

      <p class="MsoNormal" style="margin-left: 35.4pt; text-align: justify">
        <span style="font-family: 'Arial', sans-serif">Zauważyć </span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">nale</span
        ><span style="font-family: 'Arial', sans-serif"
          >ży, że na dzień 25 maja 2018 r. Administrator nie podejmuje
          wzglę</span
        ><span lang="PT" style="font-family: 'Arial', sans-serif">dem os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b fizycznych decyzji wywołujących skutki prawne, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re byłyby u podstaw ludzkiej interwencji, o kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej mowa w punkcie 13 powyżej.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Usunięcie danych</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Na żądanie osoby, Administrator usuwa dane, gdy:</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.8.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >dane nie są niezbędne do cel</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w, w kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych zostały zebranie, ani przetwarzane w innych zgodnych z prawem
          celach lub celach wymaganych przepisami prawa,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.9.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >zgoda na ich przetwarzanie została cofnięta, a Administrator nie
          dysponuje inną podstawą prawną przetwarzania,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.10.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif">osoba fizyczna, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej dane osobowe są przetwarzane wniosła skuteczny sprzeciw względem
          przetwarzania tych danych,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.11.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >dane były przetwarzane niezgodnie z prawem,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.12.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >konieczność usunięcia wynika z obowiązku prawnego,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.13.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >żądanie dotyczy danych dziecka zebranych na podstawie zgody w celu
          świadczenia usług oferowanych bezpośrednio dziecku.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">Administrator okreś</span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">la spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b realizacji prawa do usunięcia danych mając przy tym na uwadze
          obowiązek zapewnienia efektywnej realizacji tego prawa. Mowa przede
          wszystkim o zasadzie bezpieczeństwa, a także poszanowaniu obowiązku
          weryfikacji, czy nie zachodzą wyjątki, o kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych mowa w art. 17 ust. 3 RODO.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Jeżeli dane podlegające usunięciu zostały upublicznione przez
          Administratora na stronie internetowej lub w celu marketingowym
          wydarzenia organizowanego przez Administratora lub takiego, w kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rym Administrator bierze czynny udział, przy jednoczesnym założeniu
          otrzymania niezbędnych zg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span lang="SV" style="font-family: 'Arial', sans-serif">d os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane osobowe są przetwarzane w ten spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b, Administrator podejmuje rozsądne działania, w tym środki
          techniczne, by poinformować innych administrator</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzających te dane osobowe o potrzebie usunięcia danych i
          dostępu do nich.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >W przypadku usunięcia danych, Administrator informuje osobę o
          odbiorcach danych, na żądanie tej osoby.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >11.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Ograniczenie przetwarzania</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Administrator dokonuje ograniczenia przetwarzania danych na żądanie
          osoby, gdy:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >11.8.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >dane osobowe przetwarzane przez Administratora są kwestionowane przez
          osobę fizyczną, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej dane dotyczą<b> </b>– na okres niezbędny z punktu widzenia
          weryfikacji ich prawidłowoś</span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">ci,</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >11.9.<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >przetwarzanie jest niezgodne z prawem, jednak osoba, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej dane dotyczą, sprzeciwia się usunięciu danych osobowych, chcąc
          jedynie by ich przetwarzanie zostało ograniczone ze względu na
          wskazane przez nią </span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">cele,</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >11.10.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator nie potrzebuje już danych osobowych, ale są one
          potrzebne osobie, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej dane dotyczą, do ustalenia, dochodzenia lub obrony
          roszczeń,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.6in; text-align: justify; text-indent: -0.35in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >11.11.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >osoba wniosła sprzeciw względem przetwarzania jej danych osobowych –
          do czasu stwierdzenia, czy po stronie Administratora zachodzą prawnie
          uzasadnione podstawy prawne nadrzędne wobec podstaw sprzeciwu (np.
          przepisy podatkowe i inne).</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >W trakcie ograniczenia przetwarzania Administrator przechowuje dane,
          natomiast nie wykorzystuje ich oraz nie przekazuje osobom trzecim, ani
          innym podmiotom odrębnym od Administratora i jego pracownik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w, uprawnionych do dostępu do przedmiotowych danych. Wyjątkiem jest
          wyraźna zgoda osoby, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej dane dotyczą jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież ustalenie, dochodzenie lub obrona roszczeń.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >W przypadku ograniczenia przetwarzania danych, Administrator na
          żądanie osoby, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej przetwarzane dane osobowe dotyczą, informuje tę osobę o
          odbiorcach danych.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >12.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Sprzeciw przeciwko przetwarzaniu danych osobowych</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Jeżeli osoba zgłosi umotywowany sprzeciw względem przetwarzania jej
          danych, a dane przetwarzane są przez Administratora w oparciu o
          uzasadniony interes Administratora lub o powierzone Administratorowi
          zadanie w interesie publicznym, Administrator uwzględni sprzeciw.
          Wyjątkiem od tego jest sytuacja, gdy<b> </b>po stronie Administratora
          zachodzą ważne, prawnie uzasadnione podstawy do przetwarzania,
          kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re ze względu na całokształt okoliczności i powszechnie obowiązujące
          przepisy prawa należy uznać za nadrzędne wobec interes</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w i praw osoby zgłaszającej sprzeciw.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="MsoListParagraph"
        style="text-align: justify; text-indent: -0.25in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >13.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><b
          ><span style="font-family: 'Arial', sans-serif"
            >Sprzeciw względem marketingu bezpośredniego</span
          ></b
        ><span style="font-family: 'Arial', sans-serif"
          >. Jeżeli osoba fizyczna, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej dane osobowe są przetwarzane przez Administratora zgłosi sprzeciw
          względem przetwarzania jej danych przez Administratora na potrzeby
          marketingu bezpośredniego, Administrator uwzględni sprzeciw i
          zaprzestanie takiego przetwarzania, bez wyjątk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w motywowanych sytuacją faktyczną lub przepisami prawa.</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 1in; text-indent: -0.25in">
        <a name="_Toc30"
          ><span style="font-family: 'Arial', sans-serif"
            >a.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;
            </span></span
          ><span lang="DE" style="font-family: 'Arial', sans-serif"
            >MINIMALIZACJA PRZETWARZANIA DANYCH</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator dba o minimalizację przetwarzania danych z punktu
          widzenia zasad, takich jak:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 32.7pt; text-align: justify; text-indent: -32.7pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >adekwatności przetwarzanych danych osobowych do cel</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w, dla kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych są one przetwarzane,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 32.7pt; text-align: justify; text-indent: -32.7pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >dostępu do danych osobowych przetwarzanych przez
          Administratora,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 32.7pt; text-align: justify; text-indent: -32.7pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >czasu przechowywania danych osobowych.</span
        >
      </p>

      <p
        class="Heading31"
        style="margin-left: 0.75in; text-align: justify; text-indent: -0.5in"
      >
        <a name="_Toc31"
          ><span style="font-family: 'Arial', sans-serif"
            >1.1.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Minimalizacja dostępu do danych osobowych</span
          ></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator stosuje ograniczenia dostępu do danych osobowych,
          kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re mają charakter prawny (zobowiązania współpracownik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w do poufności, upoważnienia współpracownik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w posiadających dostęp do danych osobowych), fizyczny (dostęp do
          plik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w z danymi osobowymi tylko dla os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b upoważnionych w spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b by możliwie zminimalizować ryzyko wycieku danych, zamykanie
          pomieszczeń) i logistyczny (przydzielenie odpowiednich haseł dostępu
          do danych osobowych w ten spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b, by zminimalizować ryzyko dostępu do danych os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b nieupoważnionych).</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator stosuje r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież kontrolę dostępu fizycznego poprzez niedopuszczanie do miejsc
          pracy klient</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w i os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re nie podpisały z Administratorem umowy współpracy i odpowiednich
          aneks</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w upoważniających ich do dostępu do danych jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież oświadczeń w zakresie zachowania poufności.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator dokonuje aktualizacji uprawnień dostępowych przy
          zmianach w składzie personelu i zmianach r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span lang="PT" style="font-family: 'Arial', sans-serif">l os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b oraz zmianach podmiot</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzających.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator dokonuje okresowego przeglądu ustanowionych
          użytkownik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w i aktualizuje ich nie rzadziej niż raz na rok.
        </span>
      </p>

      <div
        style="
          border: none;
          border-bottom: solid #e48312 1pt;
          padding: 0in 0in 0in 0in;
        "
      >
        <p class="Heading11">
          <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
        </p>
      </div>

      <p class="Heading31" style="margin-left: 0.75in; text-indent: -0.5in">
        <a name="_Toc32"
          ><span style="font-family: 'Arial', sans-serif"
            >1.2.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Minimalizacja czasu przetwarzania danych</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator wdraża mechanizmy kontroli przetwarzania danych
          osobowych na wszystkich etapach przetwarzania, w tym weryfikacji
          dalszej przydatności danych wzglę</span
        ><span lang="PT" style="font-family: 'Arial', sans-serif"
          >dem termin</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w i punkt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w kontrolnych wskazanych w RCPD jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież w obowiązkach informacyjnych, przekazywanych osobom, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane osobowe są przetwarzane.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">Dane, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych zakres przydatności ulega ograniczeniu wraz z upływem czasu są
          usuwane z system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informatycznych Administratora jak też z miejsc przechowywania
          dokument</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w, zawierających dane osobowe.
        </span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">Dane, o kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych mowa powyżej mogą być archiwizowane w uzasadnionych przypadkach
          oraz znajdować się na kopiach zapasowych system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w i informacji przetwarzanych przez Administratora.</span
        >
      </p>

      <p class="Heading31" style="margin-left: 0.75in; text-indent: -0.5in">
        <a name="_Toc33"
          ><span style="font-family: 'Arial', sans-serif"
            >1.3.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Minimalizacja zakresu przetwarzania danych
          </span></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Przy wdrażaniu do funkcjonowania Administratora RODO, Administrator
          zweryfikował zakres pozyskiwanych danych, zakres w jakim przedmiotowe
          dane są przetwarzane jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież ilość przetwarzanych danych pod kątem adekwatności do cel</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w przetwarzania.</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator zobowiązuje się dokonywać okresowego przeglą</span
        ><span lang="FR" style="font-family: 'Arial', sans-serif">du tre</span
        ><span style="font-family: 'Arial', sans-serif"
          >ści przetwarzanych danych osobowych, ich ilości i zakresu ich
          przetwarzania nie rzadziej niż raz na rok.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator przeprowadza weryfikację zmian co do ilości i zakresu
          przetwarzania danych osobowych, w spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b o kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rym mowa powyżej, w ramach procedur zarządzania przedmiotową zmianą
          (<i>privacy by design</i>).</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 1in; text-indent: -0.25in">
        <a name="_Toc34"
          ><span style="font-family: 'Arial', sans-serif"
            >a.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;
            </span></span
          ><span lang="DE" style="font-family: 'Arial', sans-serif"
            >BEZPIECZE</span
          ></a
        ><span style="font-family: 'Arial', sans-serif">Ń</span
        ><span lang="DE" style="font-family: 'Arial', sans-serif"
          >STWO PRZETWARZANIA DANYCH PRZEZ ADMINISTRATORA</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator zapewnia stopień bezpieczeństwa odpowiadający ryzyku
          naruszenia praw os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b fizycznych w związku z charakterem danych osobowych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re są przetwarzane jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">wnież miejsc, w kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rych dane są przechowywane.</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading31">
        <a name="_Toc35"
          ><span style="font-family: 'Arial', sans-serif"
            >4.9.1. Analizy ryzyka</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator przeprowadza i dokumentuje analizy adekwatnoś</span
        ><span lang="IT" style="font-family: 'Arial', sans-serif">ci </span
        ><span style="font-family: 'Arial', sans-serif">środk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w bezpieczeństwa danych osobowych. W tym celu:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 32.7pt; text-align: justify; text-indent: -32.7pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator zapewnia odpowiedni stan wiedzy o bezpieczeństwie
          informacji i cyberbezpieczeństwie – wewnętrznie oraz ze wsparciem
          podmiot</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w wyspecjalizowanych (kancelarie prawne wyspecjalizowane w zakresie
          ochrony danych osobowych na terenie przedsiębiorstw).</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 32.7pt; text-align: justify; text-indent: -32.7pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator kategoryzuje dane oraz czynności przetwarzania pod
          kątem ryzyka, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >re przedstawiają tworząc przy tym odpowiedni rejestr przetwarzania
          danych, na kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rym opiera się Administrator przy doborze procedur ochrony
          danych.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 32.7pt; text-align: justify; text-indent: -32.7pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >3.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator przeprowadza analizy ryzyka naruszenia praw os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b fizycznych dla czynności przetwarzania danych lub ich kategorii.
          Administrator analizuje możliwe sytuacje i scenariusze naruszenia
          ochrony danych osobowych, uwzględniając charakter i zakres jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież cele przetwarzania, ryzyko naruszenia praw os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b fizycznych o różnym prawdopodobieństwie wystąpienia i wadze
          zagrożenia, w szczeg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lności ze względu na rodzaj i charakter przetwarzanych danych.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 32.7pt; text-align: justify; text-indent: -32.7pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >4.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >Administrator ustala możliwe do zastosowania organizacyjne i
          techniczne środki bezpieczeństwa i ocenia koszt ich wdrażania. W tym
          celu Administrator ustala przydatność i stosuje takie środki i
          podejście, jak:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 35.4pt; text-align: justify; text-indent: -26.4pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >10.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif">pseudonimizacja,</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 35.4pt; text-align: justify; text-indent: -26.4pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >11.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >szyfrowanie danych osobowych,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 35.4pt; text-align: justify; text-indent: -26.4pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >12.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >inne środki, składające się na zdolność </span
        ><span lang="PT" style="font-family: 'Arial', sans-serif">do ci</span
        ><span style="font-family: 'Arial', sans-serif"
          >ągłego zapewniania poufności, integralności, dostępności i
          adekwatności działalności system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w i usług przetwarzania, w tym przede wszystkich system</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w informatycznych,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 35.4pt; text-align: justify; text-indent: -26.4pt"
      >
        <span style="font-family: 'Arial', sans-serif"
          >13.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >środki zapewnienia ciągłości działania i zapobiegania skutkom awarii
          systemowych, czyli zdolności do szybkiego przywr</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >cenia dostępności danych osobowych i dostępu do nich w razie
          incydentu fizycznego lub technicznego, tak aby Administrator m</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">gł zapewnić:</span>
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.75in; text-align: justify; text-indent: -0.5in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >13.1.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >zabezpieczenie przed wyciekiem danych osobowych przetwarzanych przez
          Administratora,</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="margin-left: 0.75in; text-align: justify; text-indent: -0.5in"
      >
        <span style="font-family: 'Arial', sans-serif"
          >13.2.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span style="font-family: 'Arial', sans-serif"
          >możliwość efektywnego korzystania z praw przyznawanych osobom
          fizycznym przez RODO (art. 15-22 RODO).</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p
        class="Heading31"
        style="margin-left: 1.5in; text-align: justify; text-indent: -14.55pt"
      >
        <a name="_Toc36"
          ><span style="font-family: 'Arial', sans-serif"
            >i.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif">Oceny skutk</span></a
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w dla ochrony danych</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator dokonuje oceny skutk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w planowanych operacji przetwarzania dla ochrony danych osobowych
          tam, gdzie zgodnie z analizą ryzyka (stanowiącą załącznik do RCPD),
          ryzyko naruszenia praw i wolności os</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b jest wysokie.</span>
      </p>

      <p
        class="Heading31"
        style="margin-left: 1.5in; text-align: justify; text-indent: -14.55pt"
      >
        <a name="_Toc37"
          ><span style="font-family: 'Arial', sans-serif"
            >ii.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Środki bezpieczeństwa podejmowane przez Administratora</span
          ></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator stosuje środki bezpieczeństwa ustalone w ramach analiz
          ryzyka, właściwych dla poszczeg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lnych kategorii przetwarzania danych jak r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież adekwatności podejmowanych środk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w bezpieczeństwa oraz ocen skutk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w dla ochrony danych.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Środki bezpieczeństwa danych osobowych stanowią </span
        ><span lang="FR" style="font-family: 'Arial', sans-serif">element </span
        ><span style="font-family: 'Arial', sans-serif">środk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w bezpieczeństwa informacji i zapewnienia cyberbezpieczeństwa przez
          Administratora.</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading31" style="margin-left: 1.5in; text-indent: -14.55pt">
        <a name="_Toc38"
          ><span style="font-family: 'Arial', sans-serif"
            >iii.<span style="font: 7pt 'Times New Roman'"> </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >Raportowanie naruszeń</span
          ></a
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator stosuje procedury pozwalające na identyfikację, ocenę i
          zgłoszenie zidentyfikowanego naruszenia ochrony danych Urzędowi
          Ochrony Danych w terminie 72 godzin od ustalenia naruszenia jak
          r</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >wnież zawiadomienie osoby, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rej dane osobowe przetwarzane przez Administratora zostały naruszone,
          tak by zainteresowana osoba mogła podjąć niezbędne kroki w celu
          ochrony swoich praw.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 1in; text-indent: -0.5in">
        <a name="_Toc39"
          ><span style="font-family: 'Arial', sans-serif"
            >1.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >PODMIOTY PRZETWARZAJĄ</span
          ></a
        ><span lang="DE" style="font-family: 'Arial', sans-serif"
          >CE DANE OSOBOWE (TZW. </span
        ><span style="font-family: 'Arial', sans-serif"
          >„PROCESORY” LUB „PODMIOTY PRZETWARZAJĄCE”)</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator posiada zasady doboru i weryfikacji podmiot</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzających dane na rzecz i w imieniu Administratora.
          Przedmiotowe zasady i procedury zostały opracowane w celu zapewnienia,
          aby przetwarzający zapewniali gwarancje wdrożenia odpowiednich
          środk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w organizacyjnych i technicznych dla zapewnienia bezpieczeństwa,
          realizacji praw jednostki i innych obowiązk</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w ochrony danych spoczywających na Administratorze w spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">b, o kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >rym stanowią postanowienia RODO, dostosowany jednocześnie do
          specyfiki Administratora tak, by jak najskuteczniej chronić
          przetwarzane dane osobowe.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator przyjął odpowiednie wymagania co do umowy powierzenia
          przetwarzania danych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >ra stanowi Załącznik nr 2 do Polityki – „</span
        ><span lang="DE" style="font-family: 'Arial', sans-serif">Wz</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >r umowy powierzenia przetwarzania danych”.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator rozlicza przetwarzających z wykorzystania
          podwykonawc</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzania danych osobowych, jak też z innych wymagań
          wynikających z zasad powierzenia danych osobowych. W tym celu
          Administrator nakłada na podmioty przetwarzające obowiązki
          przestrzegania reguł bezpieczeństwa u podwykonawc</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w przetwarzania w zakresie w jakim mowa o nałożeniu na te podmioty
          dokładnie takich samych wymagań faktycznych i prawnych jak na podmioty
          przetwarzające dane osobowe w imieniu administratora.</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 1in; text-indent: -0.25in">
        <a name="_Toc40"
          ><span style="font-family: 'Arial', sans-serif"
            >b.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >PRZESYŁANIE DANYCH DO PAŃ</span
          ></a
        ><span lang="DE" style="font-family: 'Arial', sans-serif"
          >STW TRZECICH</span
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator rejestruje w RCPD przypadki eksportu danych, czyli
          przekazywania danych poza Europejski Obszar Gospodarczy (EOG w 2018 r.
          = Unia Europejska, Islandia, Lichtenstein i Norwegia).</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Aby uniknąć sytuacji nieautoryzowanego eksportu danych do państw
          trzecich, w szczeg</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >lności w związku z wykorzystaniem publicznie dostępnych usług
          chmurowych, Administrator okresowo weryfikuje zachowania
          użytkownik</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">w.</span>
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="Heading21" style="margin-left: 1in; text-indent: -0.25in">
        <a name="_Toc41"
          ><span style="font-family: 'Arial', sans-serif"
            >c.<span style="font: 7pt 'Times New Roman'"
              >&nbsp;&nbsp;
            </span></span
          ><span style="font-family: 'Arial', sans-serif"
            >PROJEKTOWANIE PRYWATNOŚCI</span
          ></a
        >
      </p>

      <p class="MsoNormal">
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >Administrator w spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b aktywny reaguje na zmiany w zakresie przetwarzania danych
          osobowych, kt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif">re mają lub mogą </span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">mie</span
        ><span style="font-family: 'Arial', sans-serif"
          >ć wpływ na prywatność w taki spos</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >b, aby umożliwić zapewnienie odpowiedniego bezpieczeństwa danych
          osobowych oraz minimalizacji ich przetwarzania.</span
        >
      </p>

      <p class="MsoNormal" style="text-align: justify">
        <span style="font-family: 'Arial', sans-serif"
          >W tym celu zasady prowadzenia projekt</span
        ><span lang="ES-TRAD" style="font-family: 'Arial', sans-serif">ó</span
        ><span style="font-family: 'Arial', sans-serif"
          >w i przedsięwzięć przez Administrator odwołują się do zasad
          bezpieczeństwa danych osobowych i minimalizacji, wymagając oceny
          wpływu na prywatność i ochronę danych. Administrator planując nowe
          projekty, uwzględnia bezpieczeństwo i minimalizację przetwarzania
          danych od początku projektu.</span
        >
      </p>

      <div
        style="
          border: none;
          border-bottom: solid #e48312 1pt;
          padding: 0in 0in 0in 0in;
        "
      >
        
    </div>`,
        }}
      />

      {/* </Suspense> */}
    </StyledPrivacyPolicyPageContainer>
  );
};

export default PrivacyPolicyPage;
