import { collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../assets/firebase/firebase.js';

import emailjs from 'emailjs-com';

const storage = getStorage();

export const addCandidate = async data => {
   const { CV, name, email, speciality, about } = data;
   const { REACT_APP_SERVICE, REACT_APP_ACCOUNT, REACT_APP_TEMPLATE_RETURN } =
      process.env;

   const date = new Date().toLocaleString();

   const storageRef = ref(storage, `${name.replace(' ', '-')}-${CV.name}`);

   return await uploadBytes(storageRef, data.CV)
      .then(async () => {
         return await getDownloadURL(
            ref(storage, `${name.replace(' ', '-')}-${CV.name}`)
         )
            .then(async cvUrl => {
               return await addDoc(collection(db, 'candidates'), {
                  name,
                  email,
                  speciality,
                  about,
                  cvUrl,
                  date,
               }).then(async () => {
                  return await emailjs
                     .send(
                        REACT_APP_SERVICE,
                        REACT_APP_TEMPLATE_RETURN,
                        {
                           from_name: name,
                           to_email: email,
                        },
                        REACT_APP_ACCOUNT
                     )
                     .then(
                        result => {
                           return result;
                        },
                        error => {
                           return error;
                        }
                     );
               });
            })
            .catch(error => error);
      })
      .catch(error => error);
};
