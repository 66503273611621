import styled from 'styled-components';

export const StyledContainer = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   align-items: flex-start;
   justify-content: center;
   padding: 5% 0% 5% 10%;
   ${({ flexDirection }) =>
      flexDirection
         ? `
   align-items: flex-start;
    `
         : null}
   @media (max-width: 1050px) {
      flex-direction: column;
      padding: 10% 0%;
      align-items: center !important;
   }
`;

export const StyledInfoBox = styled.div`
   flex: 1;
   display: flex;
   width: 80%;
   margin-bottom: 30px;
   position: relative;
   &:after {
      content: '';
      z-index: 100;
      width: 100%;
      height: 15vh;
      top: 0%;
      right: 7%;
      margin: auto;
      border-left: 2px solid white;
      position: absolute;
   }
   &:last-child {
      margin-bottom: 0px;
      // &:after {
      //    content: '';
      //    z-index: 100;
      //    width: 100%;
      //    height: 15vh;
      //    bottom: 0%;
      //    right: 7%;
      //    margin: auto;
      //    border-left: 2px solid white;
      //    position: absolute;
      // }
   }
`;

export const StyledIconWrapper = styled.div`
   max-width: 100px;
   flex: 1;
   display: flex;
   //    align-items: center
   justify-content: center;
   > svg {
      color: white;
      font-size: 4vh;
   }
`;

export const StyledTextWrapper = styled.p`
   flex: 10;
   color: white;
   font-size: 2vh;
   line-height: 3vh;
   font-weight: 500;
`;
