import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollTrigger from 'gsap/ScrollTrigger';

import FormComponent from 'views-components/HomePageContent/StartProcessForm/Form.js';
import GridContainer from 'components/Grid/GridContainer.js';
import {
   StyledAboutPageContainer,
   StyledTopSectionContact,
   StyledHeaderContact,
   StyledBottomSectionWrapper,
   StyledAddressContainer,
} from './ContactPage.css.js';
import { ListItem, List } from '@material-ui/core';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Button from 'components/CustomButtons/Button.js';

// material icons
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';

import { contactPageAnimations } from 'animations/contactPageAnimations.js';
import Parallax from 'components/Parallax/Parallax.js';
import InfoContainer from 'views-components/InfoContainer/InfoContainer.js';

const ContactPage = () => {
   const { t } = useTranslation();

   useEffect(() => {
      contactPageAnimations();
      document.title = t('pages.titles.item4');
      return () => {
         ScrollTrigger.getAll().forEach(t => {
            if (
               t.vars.id === 'navbar-trigger' ||
               t.vars.id === 'navbar-trigger-2'
            ) {
               return;
            } else {
               t.kill();
            }
         });
      };
   }, []);
   return (
      <StyledAboutPageContainer>
         <Parallax large filter='dark'>
            <StyledHeaderContact>
               <StyledTopSectionContact>
                  <h2 className='contact-page-title'>{t('contact.title')}</h2>
                  <FormComponent contactPage />
                  <Button
                     type='submit'
                     form='contact-form'
                     color='primary'
                     style={{
                        fontSize: '3vh',
                     }}
                     id='send-button'
                  >
                     {t('contact.submit-button')}
                  </Button>
               </StyledTopSectionContact>
            </StyledHeaderContact>
         </Parallax>
         <StyledBottomSectionWrapper>
            <StyledAddressContainer>
               <InfoContainer flexDirection='row' />
            </StyledAddressContainer>
            <iframe
               src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10251.403537940778!2d19.9379124!3d50.0328899!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x94b50e30592f85ce!2sFinseka%20%7C%20Kredyty%20Hipoteczne%20Krak%C3%B3w%2C%20Po%C5%9Brednik%2C%20Doradca%20Kredytowy!5e0!3m2!1spl!2spl!4v1627452948535!5m2!1spl!2spl'
               id='google-map'
               style={{ border: 0 }}
               allowFullScreen='true'
               loading='lazy'
            ></iframe>
         </StyledBottomSectionWrapper>
      </StyledAboutPageContainer>
   );
};

export default ContactPage;
