import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from '../../../../components/Grid/GridContainer.js';
import GridItem from '../../../../components/Grid/GridItem.js';
import Card from '../../../../components/Card/Card.js';
import CardAvatar from '../../../../components/Card/CardAvatar.js';
import CardBody from '../../../../components/Card/CardBody.js';
import CardFooter from '../../../../components/Card/CardFooter.js';
import Button from '../../../../components/CustomButtons/Button.js';
// icons
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';

import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';
import CardHeader from 'components/Card/CardHeader.js';

const msAvatarUrl = '/img/ms2-team.jpg';
const bkAvatarUrl = '/img/bk-team.jpg';
const soAvatarUrl = '/img/so-team.jpg';

const useStyles = makeStyles(testimonialsStyle);

const ProgrammingTeam = () => {
   const classes = useStyles();
   const { t } = useTranslation();

   const mobileOrDesktopBreak = () => {
      const width = window.innerWidth;

      if (width > 1050) {
         return (
            <>
               <br />
               <br />
               <br />
               <br />
               <br />
               <br />
            </>
         );
      } else {
         return (
            <>
               <br />
            </>
         );
      }
   };
   useEffect(() => {
      // programmingTeamAnimations();
   });
   return (
      <div className={classes.container}>
         <GridContainer>
            <GridItem
               xs={12}
               sm={6}
               md={6}
               className={
                  classes.mlAuto +
                  ' ' +
                  classes.mrAuto +
                  ' ' +
                  classes.textCenter
               }
            >
               <div id='financial-team-title'>
                  <h2>{t('about.team.programmers.title')}</h2>
                  <h5 className={classes.description}>
                     {t('about.team.programmers.text')}
                  </h5>
               </div>
            </GridItem>
         </GridContainer>
         {mobileOrDesktopBreak()}
         <GridContainer
            style={{
               justifyContent: 'center',
               transform: 'translateY(5%)',
            }}
         >
            <GridItem xs={12} sm={4} md={4} className='financial-team-person'>
               <Card testimonial className={classes.card1}>
                  <CardHeader image>
                     <a href='#' onClick={e => e.preventDefault()}>
                        <img
                           src={msAvatarUrl}
                           alt='Miltiadis Saratzidis - Finseka doradca kredytowy Kraków'
                           title='Miltiadis Saratzidis - Finseka doradca kredytowy Kraków'
                        />
                        <div
                           className={classes.cardTitleAbsolute}
                           style={{
                              padding: '0 .1rem ',
                              position: 'absolute',
                              bottom: '-.6rem',
                              right: '1px',
                              fontWeight: '700',
                           }}
                        ></div>
                     </a>
                     <div
                        className={classes.coloredShadow}
                        style={{
                           backgroundImage: `url(${msAvatarUrl})`,
                           opacity: '1',
                        }}
                     />
                  </CardHeader>
                  <h4 className={classes.cardTitle}>Miltiadis Saratzidis</h4>
                  <CardFooter testimonial>
                     {/* <h4>FULL STACK DEVELOPER
</h4> */}

                     <br />

                     <Button justIcon round color='linkedin'>
                        <a
                           style={{
                              color: 'white',
                           }}
                           href='https://www.linkedin.com/in/miltiadis-saratzidis-b1a396129/'
                           target='_blank'
                        >
                           <i className='fab fa-linkedin-in' />
                        </a>
                     </Button>
                  </CardFooter>
               </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} className='financial-team-person'>
               <Card testimonial className={classes.card1}>
                  <CardHeader image>
                     <a href='#' onClick={e => e.preventDefault()}>
                        <img
                           src={bkAvatarUrl}
                           alt='Bartłomiej Korytkowski - Finseka doradca kredytowy Kraków'
                           title='Bartłomiej Korytkowski - Finseka doradca kredytowy Kraków'
                           style={{
                              display: 'block',
                           }}
                        />
                        <div
                           className={classes.cardTitleAbsolute}
                           style={{
                              padding: '0 .1rem ',
                              position: 'absolute',
                              bottom: '-.6rem',
                              right: '1px',
                              fontWeight: '700',
                           }}
                        ></div>
                     </a>
                     <div
                        className={classes.coloredShadow}
                        style={{
                           backgroundImage: `url(${bkAvatarUrl})`,
                           opacity: '1',
                        }}
                     />
                  </CardHeader>
                  <h4 className={classes.cardTitle}>Bartłomiej Korytkowski</h4>
                  <CardFooter testimonial>
                     <br />

                     <Button justIcon round color='linkedin'>
                        <a
                           style={{
                              color: 'white',
                           }}
                           href='https://www.linkedin.com/in/bartlomiejkorytkowski/'
                           target='_blank'
                        >
                           <i className='fab fa-linkedin-in' />
                        </a>
                     </Button>
                  </CardFooter>
               </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} className='financial-team-person'>
               <Card testimonial className={classes.card1}>
                  <CardHeader image>
                     <a href='#' onClick={e => e.preventDefault()}>
                        <img
                           src={soAvatarUrl}
                           alt='Serkan Okur - Finseka doradca kredytowy Kraków'
                           title='Serkan Okur - Finseka doradca kredytowy Kraków'
                           style={{
                              display: 'block',
                           }}
                        />
                        <div
                           className={classes.cardTitleAbsolute}
                           style={{
                              padding: '0 .1rem ',
                              position: 'absolute',
                              bottom: '-.6rem',
                              right: '1px',
                              fontWeight: '700',
                           }}
                        ></div>
                     </a>
                     <div
                        className={classes.coloredShadow}
                        style={{
                           backgroundImage: `url(${soAvatarUrl})`,
                           opacity: '1',
                        }}
                     />
                  </CardHeader>
                  <h4 className={classes.cardTitle}>Serkan Okur</h4>
                  <CardFooter testimonial>
                     <br />

                     <Button justIcon round color='linkedin'>
                        <a
                           style={{
                              color: 'white',
                           }}
                           href='https://www.linkedin.com/in/serkan-okur-97623728/'
                           target='_blank'
                        >
                           <i className='fab fa-linkedin-in' />
                        </a>
                     </Button>
                  </CardFooter>
               </Card>
            </GridItem>
         </GridContainer>
      </div>
   );
};

export default ProgrammingTeam;
