import React, { useEffect, useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from 'components/CustomButtons/Button.js';

import style from '../../assets/jss/material-kit-pro-react/modalStyle.js';
import { useTranslation } from 'react-i18next';

const box1BackgroundUrl = '/img/box-1-background.jpg';
const box2BackgroundUrl = '/img/box-2-background.jpg';
const box3BackgroundUrl = '/img/box-3-background.webp';
const box4BackgroundUrl = '/img/box-4-background.jpg';
const box5BackgroundUrl = '/img/box-5-background.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction='down' ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

export default function AboutCompanyModal({
   isModalActive,
   handleCloseModal,
   activeBubble,
}) {
   const classes = useStyles();
   const { t } = useTranslation();

   const [activeBackground, setActiveBackground] = useState();

   const { bubble, titleNumber } = activeBubble;

   useEffect(() => {
      switch (bubble) {
         case 1:
            return setActiveBackground(`url(${box1BackgroundUrl})`);
         case 2:
            return setActiveBackground(`url(${box2BackgroundUrl})`);
         case 3:
            return setActiveBackground(`url(${box3BackgroundUrl})`);
         case 4:
            return setActiveBackground(`url(${box4BackgroundUrl})`);
         case 5:
            return setActiveBackground(`url(${box5BackgroundUrl})`);
      }
   }, [bubble]);

   return (
      <div>
         <Dialog
            classes={{
               root: classes.modalRoot,
               paper: classes.modal + ' ' + classes.modalLarge,
            }}
            open={isModalActive}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseModal}
            aria-describedby='classic-modal-slide-description'
         >
            <DialogContent
               id='classic-modal-slide-description'
               className={classes.modalBody}
               style={{
                  backgroundRepeat: 'no-repeat',
                  border: '1px solid lightblue',
                  backgroundSize: 'cover',
                  backgroundImage: activeBackground,
                  position: 'relative',
                  padding: 0,
                  borderRadius: '6px',
                  overflow: 'hidden',
               }}
            >
               <div
                  style={{
                     width: '100%',
                     height: '100%',
                     background: 'rgba(0,0,0,0.3)',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     flexDirection: 'column',
                     padding: '150px 50px',
                  }}
               >
                  <Button
                     simple
                     className={classes.modalCloseButton}
                     style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                     }}
                     key='close'
                     aria-label='Close'
                     onClick={handleCloseModal}
                  >
                     <Close className={classes.modalClose} />
                  </Button>
                  <h5
                     style={{
                        color: 'white',
                        fontSize: '55px',
                        margin: 0,
                        padding: 0,
                     }}
                  >
                     {titleNumber}
                  </h5>
                  <p
                     style={{
                        color: 'white',
                        fontSize: '40px',
                        borderBottom: '3px solid #FF00CF',
                        lineHeight: '50px',
                     }}
                  >
                     {t(`homepage.bubbles.box-${bubble}-title`)}
                  </p>
                  <span
                     style={{
                        color: 'white',
                        fontSize: '20px',
                        textAlign: 'center',
                     }}
                     className='bubble-text-expansion'
                  >
                     {t(`homepage.bubbles.box-${bubble}-text`)}
                  </span>
               </div>
            </DialogContent>
         </Dialog>
      </div>
   );
}
