import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';
gsap.registerPlugin(ScrollTrigger);
CSSPlugin.defaultForce3D = false;

export const stepFiveAnimations = () => {
   // title
   gsap.from('#step-five-title', {
      scrollTrigger: {
         trigger: '#step-five-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '-10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   // image elements
   gsap.from('#process_card', {
      scrollTrigger: {
         trigger: '#step-five-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      y: '-10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   gsap.from('#process_worker1', {
      scrollTrigger: {
         trigger: '#step-five-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   gsap.from('#process_worker2', {
      scrollTrigger: {
         trigger: '#step-five-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '-10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   gsap.from('#process_flower', {
      scrollTrigger: {
         trigger: '#step-five-container',
         scrub: true,
         start: '30% bottom',
         end: '50% bottom',
      },
      y: '10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   // text
   gsap.from('#step-five-card', {
      scrollTrigger: {
         trigger: '#step-five-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      y: '-15vh',
      opacity: 0,
      ease: 'easeInOut',
   });
};
