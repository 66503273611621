import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledJoinSection } from './JoinUsSection.css.js';
import classNames from 'classnames';
// @material-ui/core components
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// core components
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';
import CustomInput from '../../../components/CustomInput/CustomInput.js';
import Button from '../../../components/CustomButtons/Button.js';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import contactStyle from '../../../assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.js';
import { InputLabel } from '@material-ui/core';
import CustomFileInput from 'components/CustomFileInput/CustomFileInput.js';
import DescriptionIcon from '@material-ui/icons/Description';
import { Form, Field } from 'react-final-form';
import { addCandidate } from 'data/firebase.fetch.js';
import { LoadingIndicator } from 'views-components/index.js';

const useStyles = makeStyles(contactStyle);

const JoinUsSection = () => {
   const classes = useStyles();
   const { t } = useTranslation();
   const [isLoading, setIsLoading] = useState(false);
   const [messageBack, setMessageBack] = useState('');
   const [isSendError, setIsSendError] = useState();
   const [isMessageShouldBeShow, setIsMessageShouldBeShow] = useState(false);

   const handleSubmitForm = async data => {
      setIsLoading(true);
      await addCandidate(data).then(() => {
         setIsLoading(false);
         setIsMessageShouldBeShow(true);
         setIsSendError(false);
         setMessageBack(t('about.joinus.form.return-text'));
      });
   };

   return (
      <StyledJoinSection id='join-container'>
         <div>
            <h3
               dangerouslySetInnerHTML={{
                  __html: t('about.joinus.title', {
                     interpolation: { escapeValue: false },
                  }),
               }}
            ></h3>
            <p
               dangerouslySetInnerHTML={{
                  __html: t('about.joinus.text', {
                     interpolation: { escapeValue: false },
                  }),
               }}
            ></p>
         </div>
         <div>
            {isLoading ? (
               <LoadingIndicator width='100%' height='300px' />
            ) : (
               <Form
                  onSubmit={data => handleSubmitForm(data)}
                  validate={values => {
                     const errors = {
                        name: undefined,
                        email: undefined,
                        spaciality: undefined,
                        about: undefined,
                        CV: undefined,
                     };

                     if (!values.name) {
                        errors.name = t('form.error');
                     } else if (values.name.length < 2) {
                        errors.name =
                           'Imię musi zawierać przynajmniej 2 znaki.';
                     }

                     if (!values.email) {
                        errors.email = t('form.error');
                     } else if (!values.email.includes('@')) {
                        errors.email = 'Coś jest nie tak z adresem email.';
                     }

                     if (!values.about) {
                        errors.about = t('form.error');
                     } else if (values.about.lenght < 10) {
                        errors.about = 'Napisz o sobie coś wiecej!';
                     }

                     if (!values.CV) {
                        errors.CV = t('form.error');
                     }

                     return errors;
                  }}
                  render={({
                     handleSubmit,
                     hasValidationErrors,
                     values,
                     form,
                  }) => {
                     return (
                        <form onSubmit={handleSubmit}>
                           <GridContainer>
                              <Field name='name'>
                                 {({ input, meta }) => (
                                    <GridItem md={4} sm={4}>
                                       <CustomInput
                                          inputProps={{
                                             ...input,
                                             name: 'name',
                                             className: 'form-input',
                                          }}
                                          labelText={t(
                                             'about.joinus.form.input1'
                                          )}
                                          formControlProps={{
                                             fullWidth: true,
                                          }}
                                       />
                                       <span style={{ color: 'red' }}>
                                          {meta.error &&
                                             meta.touched &&
                                             meta.error}
                                       </span>
                                    </GridItem>
                                 )}
                              </Field>
                              <Field name='email'>
                                 {({ input, meta }) => (
                                    <GridItem md={4} sm={4}>
                                       <CustomInput
                                          inputProps={{
                                             ...input,
                                             name: 'email',
                                             className: 'form-input',
                                          }}
                                          labelText={t(
                                             'about.joinus.form.input2'
                                          )}
                                          formControlProps={{
                                             fullWidth: true,
                                          }}
                                       />
                                       <span style={{ color: 'red' }}>
                                          {meta.error &&
                                             meta.touched &&
                                             meta.error}
                                       </span>
                                    </GridItem>
                                 )}
                              </Field>
                              <Field name='speciality'>
                                 {({ input, meta }) => (
                                    <GridItem md={4} sm={4}>
                                       <FormControl
                                          fullWidth
                                          className={
                                             classes.selectFormControl +
                                             ' ' +
                                             classes.selectUnderlineRoot
                                          }
                                       >
                                          <InputLabel id='demo-controlled-open-select-label'>
                                             {t('about.joinus.form.input3')}
                                          </InputLabel>
                                          <Select
                                             MenuProps={{
                                                className: classes.selectMenu,
                                             }}
                                             classes={{
                                                select: classes.select,
                                             }}
                                             inputProps={{
                                                id: 'speciality-select',
                                                ...input,
                                                name: 'spaciality',
                                                className: 'form-input',
                                             }}
                                          >
                                             <MenuItem
                                                disabled
                                                classes={{
                                                   root: classes.selectMenuItem,
                                                }}
                                             >
                                                {t('about.joinus.form.input3')}
                                             </MenuItem>
                                             <MenuItem
                                                classes={{
                                                   root: classes.selectMenuItem,
                                                   selected:
                                                      classes.selectMenuItemSelected,
                                                }}
                                                value='1'
                                             >
                                                {t(
                                                   'about.joinus.form.input3-select1'
                                                )}
                                             </MenuItem>
                                             <MenuItem
                                                classes={{
                                                   root: classes.selectMenuItem,
                                                   selected:
                                                      classes.selectMenuItemSelected,
                                                }}
                                                value='2'
                                             >
                                                {t(
                                                   'about.joinus.form.input3-select2'
                                                )}
                                             </MenuItem>
                                             <MenuItem
                                                classes={{
                                                   root: classes.selectMenuItem,
                                                   selected:
                                                      classes.selectMenuItemSelected,
                                                }}
                                                value='3'
                                             >
                                                {t(
                                                   'about.joinus.form.input3-select3'
                                                )}
                                             </MenuItem>
                                          </Select>
                                       </FormControl>
                                    </GridItem>
                                 )}
                              </Field>
                           </GridContainer>
                           <GridContainer>
                              <GridItem
                                 md={6}
                                 sm={6}
                                 className={classNames(
                                    classes.mrAuto,
                                    classes.mlAuto,
                                    classes.textCenter
                                 )}
                              >
                                 <Field name='about'>
                                    {({ input, meta }) => (
                                       <>
                                          <CustomInput
                                             labelText={t(
                                                'about.joinus.form.input4'
                                             )}
                                             inputProps={{
                                                ...input,
                                                name: 'about',
                                                className: 'form-input',
                                             }}
                                             formControlProps={{
                                                fullWidth: true,
                                             }}
                                          />
                                          <span style={{ color: 'red' }}>
                                             {meta.error &&
                                                meta.touched &&
                                                meta.error}
                                          </span>
                                       </>
                                    )}
                                 </Field>
                                 <Field name='CV'>
                                    {({ input, meta }) => (
                                       <>
                                          <CustomFileInput
                                             accept='application/pdf'
                                             type='file'
                                             formControlProps={{
                                                fullWidth: true,
                                             }}
                                             inputProps={{
                                                placeholder: t(
                                                   'about.joinus.form.input5'
                                                ),
                                                type: 'file',
                                                accept: 'application/pdf',
                                                ...input,
                                                name: 'CV',
                                             }}
                                             // value={values.CV}
                                             onChange={file => {
                                                form.change('CV', file[0]);
                                             }}
                                             endButton={{
                                                buttonProps: {
                                                   round: true,
                                                   color: 'primary',
                                                   justIcon: true,
                                                   fileButton: true,
                                                },
                                                icon: <DescriptionIcon />,
                                             }}
                                          />
                                          <span style={{ color: 'red' }}>
                                             {meta.error &&
                                                meta.touched &&
                                                meta.error}
                                          </span>
                                       </>
                                    )}
                                 </Field>
                              </GridItem>
                           </GridContainer>
                           <GridContainer
                              style={{
                                 marginTop: '5vh',
                              }}
                           >
                              <GridItem
                                 md={4}
                                 sm={4}
                                 className={classNames(
                                    classes.mrAuto,
                                    classes.mlAuto,
                                    classes.textCenter
                                 )}
                              >
                                 <Button
                                    type='submit'
                                    color='primary'
                                    style={{
                                       fontSize: '3vh',
                                    }}
                                    round
                                 >
                                    {t('about.joinus.form.submit')}
                                 </Button>
                              </GridItem>
                           </GridContainer>
                           {isMessageShouldBeShow ? (
                              <p
                                 style={{
                                    fontSize: '1.5vh',
                                    color: 'green',
                                    textAlign: 'center',
                                 }}
                              >
                                 <CheckCircleIcon />
                                 <br></br> {messageBack}
                              </p>
                           ) : null}
                        </form>
                     );
                  }}
               />
            )}
         </div>
      </StyledJoinSection>
   );
};

export default JoinUsSection;
