import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import emailjs from 'emailjs-com';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from 'views-components/index.js';

const FormComponent = ({ contactPage }) => {
   const { t } = useTranslation();
   const [isLoading, setIsLoading] = useState(false);
   const [messageBack, setMessageBack] = useState('');
   const [isSendError, setIsSendError] = useState();
   const [isMessageShouldBeShow, setIsMessageShouldBeShow] = useState(false);

   const handleSendEmail = async data => {
      const {
         REACT_APP_SERVICE,
         REACT_APP_TEMPLATE,
         REACT_APP_ACCOUNT,
         REACT_APP_TEMPLATE_RETURN,
      } = process.env;

      setIsLoading(true);

      await emailjs
         .send(REACT_APP_SERVICE, REACT_APP_TEMPLATE, data, REACT_APP_ACCOUNT)
         .then(
            result => {
               if (result.text === 'OK') {
                  emailjs
                     .send(
                        REACT_APP_SERVICE,
                        REACT_APP_TEMPLATE_RETURN,
                        {
                           from_name: data.from_name,
                           to_email: data.email,
                        },
                        REACT_APP_ACCOUNT
                     )
                     .then(
                        result => {
                           if (result.text === 'OK') {
                              setIsLoading(false);
                              setIsMessageShouldBeShow(true);
                              setIsSendError(false);
                              setMessageBack(
                                 t('homepage.contact-us.form-return-message')
                              );
                           }
                        },
                        error => {
                           setIsLoading(false);
                           setIsMessageShouldBeShow(true);
                           setIsSendError(true);
                           setMessageBack(t('form.send-error'));
                        }
                     );
               } else {
                  setIsLoading(false);
                  setIsMessageShouldBeShow(true);
                  setIsSendError(true);
                  setMessageBack(t('form.send-error'));
               }
            },
            error => {
               console.log(error.text);
            }
         );
   };

   return isLoading ? (
      <LoadingIndicator width='100%' height={contactPage ? '300px' : '60%'} />
   ) : (
      <Form
         onSubmit={handleSendEmail}
         validate={values => {
            const errors = {
               from_name: undefined,
               email: undefined,
               message: undefined,
            };
            if (!values.from_name) {
               errors.from_name = t('form.error');
            } else if (values.from_name.length < 2) {
               errors.from_name = 'Imię musi zawierać przynajmniej 2 znaki.';
            }
            if (!values.email) {
               errors.email = t('form.error');
            } else if (!values.email.includes('@')) {
               errors.email = 'Coś jest nie tak z adresem email.';
            }
            if (!values.message) {
               errors.message = t('form.error');
            } else if (values.message.length < 10) {
               errors.message =
                  'Wiadomość musi zawierać przynajmniej 10 znaków.';
            }
            return errors;
         }}
         render={({ handleSubmit, hasValidationErrors, values, form }) => {
            return (
               <form onSubmit={handleSubmit} className='form' id='contact-form'>
                  <Field name='from_name'>
                     {({ input, meta }) => (
                        <>
                           <CustomInput
                              labelText={t('homepage.contact-us.form-input-1')}
                              id='float'
                              error={meta.error && meta.touched}
                              success={!meta.error}
                              inputRootCustomClasses='dark-background'
                              inputProps={{
                                 ...input,
                                 name: 'from_name',
                                 className: 'form-input',
                              }}
                              formControlProps={{
                                 fullWidth: true,
                              }}
                           />
                           <span className='input-error-span'>
                              {meta.error && meta.touched && meta.error}
                           </span>
                        </>
                     )}
                  </Field>
                  <Field name='email'>
                     {({ input, meta }) => (
                        <>
                           <CustomInput
                              labelText={t('homepage.contact-us.form-input-2')}
                              id='float'
                              inputRootCustomClasses='dark-background'
                              error={meta.error && meta.touched}
                              success={!meta.error}
                              inputProps={{
                                 ...input,
                                 name: 'email',
                                 className: 'form-input',
                              }}
                              formControlProps={{
                                 fullWidth: true,
                              }}
                           />
                           {meta.error && meta.touched && (
                              <span className='input-error-span'>
                                 {meta.error}
                              </span>
                           )}
                        </>
                     )}
                  </Field>
                  <Field name='message'>
                     {({ input, meta }) => (
                        <>
                           <CustomInput
                              labelText={t('homepage.contact-us.form-input-3')}
                              id='float'
                              inputRootCustomClasses='dark-background'
                              error={meta.error && meta.touched}
                              success={!meta.error}
                              inputProps={{
                                 ...input,
                                 name: 'message',
                                 className: 'form-input',
                              }}
                              formControlProps={{
                                 fullWidth: true,
                              }}
                           />
                           {meta.error && meta.touched && (
                              <span className='input-error-span'>
                                 {meta.error}
                              </span>
                           )}
                        </>
                     )}
                  </Field>
                  {contactPage ? null : hasValidationErrors ? null : (
                     <Button
                        onClick={setTimeout(() => {
                           form.reset;
                        }, 500)}
                        color='primary'
                        type='submit'
                        className='form-submit-button'
                     >
                        Wyślij
                     </Button>
                  )}
                  {isMessageShouldBeShow ? (
                     <p
                        style={{
                           fontSize: '1.7vh',
                           fontWeight: '500',
                           color: isSendError ? 'red' : 'green',
                        }}
                     >
                        {isSendError ? <CancelIcon /> : <CheckCircleIcon />}{' '}
                        {messageBack}
                     </p>
                  ) : null}
               </form>
            );
         }}
      />
   );
};

export default FormComponent;
