import styled from 'styled-components';
import { underlineAnimation } from 'views/AboutPage/AboutPage.css.js';

const backgroundImageUrl = '/img/calculator-page-image.jpg';

export const StyledMortgageHeader = styled.div`
   width: 100vw;
   height: 100vh;
   background-image: url(${backgroundImageUrl});
   background-size: cover;
   background-repeat: no-repeat;
   background-position: 20% 20%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding-left:15%;
   padding-top:5%;
   > .mortgage-page-title {
      transform:translate
      text-align: center;
      margin-bottom: 3vh;
      z-index: 200;
      color: white;
      font-size: 5vh;
      position: relative;
      &:after {
         animation: ${underlineAnimation} 1s ease forwards;
         animation-delay: 1s;
         content: '';
         z-index: 100;
         width: 0%;
         left: 0;
         bottom: 0%;
         // right: 0;
         margin: auto;
         border-bottom: 3px solid ${({ theme }) => theme.colors.purple.light};
         position: absolute;
      }
   }

   > p {
      z-index: 1000;
      color: white;
      padding: 0 35%;
      line-height: 30px;
      text-align: center;
      font-size: 2.5vh;
   }
   // mobile
   @media (max-width: 1050px) {
      padding-left:0%;
      padding-top:0%;
      > p {
         padding: 0 5%;
      }
   }
`;

export const StyledButton = styled.button`
   width: 200px;
   height: 60px;
   background: transparent;
   outline: 0;
   border: 0;
   text-transform: uppercase;
   font-size: 15px;
   cursor: pointer;
   border-radius: 6px;
   box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.colors.blue.normal};
   position: relative;
   overflow: hidden;
   > span {
      transition: 0.2s ease;
      position: absolute;
      color: ${({ theme }) => theme.colors.blue.normal};
      font-weight: 600;
      z-index: 100 !important;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
   }
   &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0px;
      height: 100%;
      background: ${({ theme }) => theme.colors.blue.normal};
      z-index: 0;
      transition: width 150ms ease-in-out;
   }
   // &:before {
   //    z-index: 0 !important;
   //    content: '';
   //    background: purple;
   //    top: 0;
   //    bottom: 0;
   //    right: 0;
   //    width: 0%;
   //    margin: 0;
   //    position: absolute;
   //    transition: 0.5s ease;
   // }
   // &:hover:before,
   &:hover:after {
      width: 100%;
   }
   &:hover {
      > span {
         color: white;
      }
   }
`;

export const StyledCalculatorWrapper = styled.div`
   width: 95vw;
   margin-left: 2.5%;
   border-radius: 6px;
   padding: 5vh 15% 10vh 15%;
   margin-top: -6vh;
   transform: translateY(5vh);
   box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%),
      0 8px 10px -5px rgb(0 0 0 / 20%);
   // margin-bottom: 40vh;
   background: white !important;
   .calculator-card {
      border: 1px solid #ff00cf;
      width: 100%;
      height: 650px;
   }
   > h2 {
      font-size: 6vh;
      text-align: center;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
   > p {
      font-size: 4vh;
      line-height: 4.5vh;
      text-align: center;
   }
   #calculator-chart {
      display: flex;
      justify-content: center;
   }
   // mobile
   @media (max-width: 1050px) {
      padding: 0 5%;
      .calculator-card {
         height: auto;
         padding: 0 5%;
      }
   }
   @media (max-width: 600px) {
      .calculator-card {
         height: auto;
         padding: 0 10%;
      }
   }
`;
