import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger);
CSSPlugin.defaultForce3D = false;

export const stepThreeAnimations = () => {
   // title
   gsap.from('#step-three-title', {
      scrollTrigger: {
         trigger: '#step-three-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '-10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   // image elements
   gsap.from('#doc_1', {
      scrollTrigger: {
         trigger: '#step-three-container',
         scrub: true,
         start: 'top bottom',
         end: '60% bottom',
      },
      x: '10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   gsap.from('#doc_2', {
      scrollTrigger: {
         trigger: '#step-three-container',
         scrub: true,
         start: 'top bottom',
         end: '40% bottom',
      },
      x: '10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   gsap.from('#doc_3', {
      scrollTrigger: {
         trigger: '#step-three-container',
         scrub: true,
         start: 'top bottom',
         end: '20% bottom',
      },
      x: '10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   gsap.from('#doc_girl', {
      scrollTrigger: {
         trigger: '#step-three-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      opacity: 0,
      ease: 'easeInOut',
   });
   // text
   gsap.from('#step-three-card', {
      scrollTrigger: {
         trigger: '#step-three-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      y: '-15vh',
      opacity: 0,
      ease: 'easeInOut',
   });
};
