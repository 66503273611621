import styled from 'styled-components';

export const StyledTeamContainer = styled.section`
   width: 100%;
   margin-top: 10vh;
   // TEAM SECTION CLASSES
   .team-profile-pic {
      width: 60%;
      border-radius: 50%;
   }
   .team-profile-name {
      font-size: 2.3vh;
      font-weight: 100;
      margin-bottom: 2.5%;
   }
   .team-profile-status {
      font-size: 1.5vh;
      color: grey;
      font-weight: 100;
      margin-bottom: 2.5%;
   }
   .team-profile-desc {
      font-size: 1.8vh;
      color: grey;
      font-weight: 200;
   }
   .team-profile-footer {
      width: 100%;
      align-items: center;
      justify-content: center;
   }
`;
export const StyledSectionTitle = styled.div`
   width: 100%;
   height: 10vh;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0 15%;
   .teams-title {
      text-align: center;
      position: relative;
      flex: 1;
      font-size: 6vh;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }

   @media (max-width: 1050px) {
      text-align: center;
      .teams-title {
         font-size: 2.4rem;
         visibility: visible;
      }
   }
`;

export const StyledTeamWrapper = styled.div`
   width: 100%;
   position: relative;
   display: flex;
   padding: 0% 10%;
   flex-direction: column;
   &:nth-child(odd) {
      > h4 {
         top: 2%;
      }
   }
   > h4 {
      left: 2%;
   }
   &:last-child {
      // > h4 {
      //    right: 2%;
      // }
   }
   > h4 {
      top: 1%;
      bottom: 0;
      position: absolute;
      font-size: 20vh;
      text-align: center;
      color: grey;
      opacity: 0.1;
   }
   > .executive-wrapper {
      padding: 0 10%;
   }
   @media (max-width: 1050px) {
      > .executive-wrapper {
         padding: 0 0%;
      }
      &:nth-child(3) > h4 {
         top: 6%;
      }
      &:nth-child(4) > h4 {
         top: 5%;
      }
   }

   @media (min-height: 1300px) and (max-width: 1050px) {
      margin-bottom: 0% !important;
   }
`;
