import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// import { financialSpecialistAnimations } from 'animations/aboutPageAnimations.js';

// @material-ui/icons
import Star from '@material-ui/icons/Star';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from '../../../../components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Warning from 'components/Typography/Warning.js';
import Button from '../../../../components/CustomButtons/Button.js';

import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';

const FS1 = '/img/as-team.jpg';
const FS2 = '/img/kw-team.jpg';

const useStyles = makeStyles(testimonialsStyle);

const FinancialSpecialistTeam = () => {
   const classes = useStyles();
   const { t } = useTranslation();

   const mobileOrDesktopBreak = () => {
      const width = window.innerWidth;

      if (width > 1050) {
         return (
            <>
               <br />
               <br />
               <br />
               <br />
               <br />
               <br />
               <br />
            </>
         );
      } else {
         return (
            <>
               <br />
               <br />
               <br />
               <br />
            </>
         );
      }
   };

   useEffect(() => {
      // financialSpecialistAnimations();
   });
   return (
      <div className={classes.container}>
         <GridContainer>
            <GridItem
               xs={12}
               sm={6}
               md={6}
               className={
                  classes.mlAuto +
                  ' ' +
                  classes.mrAuto +
                  ' ' +
                  classes.textCenter
               }
            >
               <div id='financial-team-title'>
                  <h2>{t('about.team.specialist.title')}</h2>
                  <h5 className={classes.description}>
                     {t('about.team.specialist.text')}
                  </h5>
               </div>
            </GridItem>
         </GridContainer>
         {mobileOrDesktopBreak()}
         <GridContainer
            style={{
               justifyContent: 'center',
            }}
         >
            <GridItem xs={12} sm={4} md={4} className='financial-team-person'>
               <Card testimonial className={classes.card1}>
                  <CardHeader image>
                     <a href='#' onClick={e => e.preventDefault()}>
                        <img
                           src={FS1}
                           alt='Aneta Śliwowska - Finseka doradca kredytowy Kraków'
                           title='Aneta Śliwowska - Finseka doradca kredytowy Kraków'
                        />
                        <div
                           className={classes.cardTitleAbsolute}
                           style={{
                              padding: '0 .1rem ',
                              position: 'absolute',
                              bottom: '-.6rem',
                              right: '1px',
                              fontWeight: '700',
                           }}
                        ></div>
                     </a>
                     <div
                        className={classes.coloredShadow}
                        style={{
                           backgroundImage: `url(${FS1})`,
                           opacity: '1',
                        }}
                     />
                  </CardHeader>
                  <h4 className={classes.cardTitle}>Aneta Śliwowska</h4>
                  <CardFooter testimonial>
                     <h4>{t('about.team.specialist.rate')}</h4>
                     <div>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                     </div>
                     <br />
                     <Button justIcon round color='linkedin'>
                        <i className='fab fa-linkedin-in' />
                     </Button>
                  </CardFooter>
               </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} className='financial-team-person'>
               <Card testimonial className={classes.card1}>
                  <CardHeader image>
                     <a href='#' onClick={e => e.preventDefault()}>
                        <img
                           src={FS2}
                           alt='Kornelia Wójciak - Finseka doradca kredytowy Kraków'
                           title='Kornelia Wójciak - Finseka doradca kredytowy Kraków'
                           style={{
                              display: 'block',
                           }}
                        />
                        <div
                           className={classes.cardTitleAbsolute}
                           style={{
                              padding: '0 .1rem ',
                              position: 'absolute',
                              bottom: '-.6rem',
                              right: '1px',
                              fontWeight: '700',
                           }}
                        ></div>
                     </a>
                     <div
                        className={classes.coloredShadow}
                        style={{
                           backgroundImage: `url(${FS2})`,
                           opacity: '1',
                        }}
                     />
                  </CardHeader>
                  <h4 className={classes.cardTitle}>Kornelia Wójciak</h4>
                  <CardFooter testimonial>
                     <h4>{t('about.team.specialist.rate')}</h4>
                     <div>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                        <Warning>
                           <Star className={classes.starIcons} />
                        </Warning>
                     </div>
                     <br />

                     <Button justIcon round color='linkedin'>
                        <i className='fab fa-linkedin-in' />
                     </Button>
                  </CardFooter>
               </Card>
            </GridItem>
         </GridContainer>
      </div>
   );
};

export default FinancialSpecialistTeam;
