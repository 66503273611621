import styled, { keyframes } from 'styled-components';

export const underlineAnimation = keyframes`
   0%{
      width:0%;
   }
   100%{
      width:80%;
   }
`;

export const StyledHomePage = styled.main`
   width: 100%;
   display: flex;
   flex-direction: column;
   .parallax-home {
      height: 100vh;
   }
`;

export const StyledMainSection = styled.section`
   width: 100%;
   height: 100vh;
   &:after {
      content: '';
      z-index: 0;
      background-image: url('/img/landing-image.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 20% 20%;
      top: -23%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      position: absolute;
      // MOBILE
      @media (max-width: 1050px) {
         background-position: 30% 20%;
      }
   }
`;

export const StyledMainSectionTextWrapper = styled.div`
   position: absolute;
   z-index: 100;
   width: 100%;
   height: 100%;
   display: flex;
   padding: 5% 5% 10% 60%;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   > h1 {
      z-index: 1000;
      font-family: 'Roboto', sans-serif;
      font-size: 6vh;
      color: white;
      margin-bottom: 5%;
      position: relative;
      &:after {
         animation: ${underlineAnimation} 1s ease forwards;
         animation-delay: 1s;
         content: '';
         z-index: 100;
         width: 0%;
         left: 0;
         bottom: -10%;
         // right: 0;
         margin: auto;
         border-bottom: 3px solid ${({ theme }) => theme.colors.purple.light};
         position: absolute;
      }
   }
   > p {
      line-height: 3vh;
      color: white;
      z-index: 1000;
      font-family: 'Roboto', sans-serif;
      font-weight: 100;
      font-size: 2.5vh;
   }
   @media (max-width: 1465px) {
      > h1:after {
         bottom: -2% !important;
      }
   }
   // MOBILE
   @media (max-width: 1050px) {
      padding: 35% 5% 0% 5%;
      > p {
         line-height: 3.5vh;
      }
      > h1 {
         font-size: 5vh;
         &:after {
            left: 0;
            right: auto;
            bottom: -5%;
         }
      }
   }
`;
