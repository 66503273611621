import React, { useEffect, useRef, useState } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import {
   StyledNavBar,
   StyledHamburgerMenu,
   StyledPhoneNumberWrapper,
   StyledChangeLanguageBox,
} from './NavBar.css.js';

// material
import LanguageIcon from '@material-ui/icons/Language';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown.js';
// icon
import CallIcon from '@material-ui/icons/Call';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const whiteLogoUrl = '/img/logo-navbar-white.png';
const darkLogoUrl = '/img/logo-navbar.png';

const NavBar = () => {
   const [isLogoShouldBeDark, setIsLogoShouldBeDark] = useState(false);
   const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

   const navBarLogoRef = useRef(null);
   const navBarRef = useRef(null);

   const { i18n, t } = useTranslation();
   const { location } = useHistory();

   const scrollToSectionInAboutPage = section => {
      switch (section) {
         case 'mission':
            document
               .getElementById('mission-container')
               .scrollIntoView({ behavior: 'smooth' });
            break;
         case 'values':
            document
               .getElementById('services-container')
               .scrollIntoView({ behavior: 'smooth' });
            break;
         case 'team':
            document
               .getElementById('teams-container')
               .scrollIntoView({ behavior: 'smooth' });
            break;
         case 'office':
            document
               .getElementById('office-section-container')
               .scrollIntoView({ behavior: 'smooth' });
            break;
         case 'joinus':
            document
               .getElementById('join-container')
               .scrollIntoView({ behavior: 'smooth' });
            break;
         default:
            break;
      }
   };

   const aboutPageNavList = () => {
      if (location.pathname === '/about') {
         return (
            <>
               <li>
                  <a
                     style={{
                        cursor: 'pointer',
                     }}
                     onClick={() => scrollToSectionInAboutPage('mission')}
                  >
                     Misja firmy
                  </a>
               </li>
               <li>
                  <a
                     style={{
                        cursor: 'pointer',
                     }}
                     onClick={() => scrollToSectionInAboutPage('values')}
                  >
                     Nasze wartości
                  </a>
               </li>
               <li>
                  <a
                     style={{
                        cursor: 'pointer',
                     }}
                     onClick={() => scrollToSectionInAboutPage('team')}
                  >
                     Nasz zespół
                  </a>
               </li>
               <li>
                  <a
                     style={{
                        cursor: 'pointer',
                     }}
                     onClick={() => scrollToSectionInAboutPage('office')}
                  >
                     Siedziba naszej firmy
                  </a>
               </li>
               <li>
                  <a
                     style={{
                        cursor: 'pointer',
                     }}
                     onClick={() => scrollToSectionInAboutPage('joinus')}
                  >
                     Dołącz do nas
                  </a>
               </li>
            </>
         );
      } else {
         return (
            <>
               <li>
                  <NavLink
                     to={{
                        pathname: '/about',
                        state: { section: 'mission' },
                     }}
                  >
                     Misja firmy
                  </NavLink>
               </li>
               <li>
                  <NavLink
                     to={{
                        pathname: '/about',
                        state: { section: 'values' },
                     }}
                  >
                     Nasze wartości
                  </NavLink>
               </li>
               <li>
                  <NavLink
                     to={{
                        pathname: '/about',
                        state: { section: 'team' },
                     }}
                  >
                     Nasz zespół
                  </NavLink>
               </li>
               <li>
                  <NavLink
                     to={{
                        pathname: '/about',
                        state: { section: 'office' },
                     }}
                  >
                     Siedziba naszej firmy
                  </NavLink>
               </li>
               <li>
                  <NavLink
                     to={{
                        pathname: '/about',
                        state: { section: 'joinus' },
                     }}
                  >
                     Dołącz do nas
                  </NavLink>
               </li>
            </>
         );
      }
   };

   const handleToogleIsMobileMenuActive = () => {
      isMobileMenuActive
         ? setIsMobileMenuActive(false)
         : setIsMobileMenuActive(true);
   };

   const handleChangeLanguage = language => {
      i18n.changeLanguage(language);
   };

   /// USE EFFECT
   useEffect(() => {
      gsap.registerPlugin(ScrollTrigger);
      const showAnim = gsap
         .from('.nav-bar', {
            yPercent: -100,
            paused: true,
            duration: 0.2,
         })
         .progress(1);
      // style trigger
      ScrollTrigger.create({
         trigger: 'body',
         start: '10vh top',
         id: 'navbar-trigger-2',
         end: 'bottom bottom',
         onLeaveBack: () => {
            if (
               // location.pathname === '/mortgage-calculator' ||
               // location.pathname === '/ranking' ||
               location.pathname === '/blog'
            ) {
               return 0;
            } else {
               setIsLogoShouldBeDark(false);
               const { current: nav_bar } = navBarRef;
               nav_bar?.classList.remove('nav-bar-active');
            }
         },
         onUpdate: () => {
            if (
               // location.pathname === '/mortgage-calculator' ||
               // location.pathname === '/ranking' ||
               location.pathname === '/blog'
            ) {
               return 0;
            } else {
               setIsLogoShouldBeDark(true);
               const { current: nav_bar } = navBarRef;
               nav_bar?.classList.add('nav-bar-active');
            }
         },
      });
      // hide trigger
      ScrollTrigger.create({
         trigger: 'body',
         start: 'top top',
         end: 999999,
         id: 'navbar-trigger',
         onUpdate: self => {
            self.direction === -1 ? showAnim.play() : showAnim.reverse();
         },
      });
   }, []);
   useEffect(() => {
      const width = window.innerWidth;
      const body = document.querySelector('body');
      if (width < 1050) {
         isMobileMenuActive
            ? (body.style.overflow = 'hidden')
            : (body.style.overflow = 'visible');
      }
   }, [isMobileMenuActive]);

   useEffect(() => {
      const navBarImage = document.querySelector('#nav-bar-logo');
      if (
         // location.pathname === '/mortgage-calculator' ||
         // location.pathname === '/ranking' ||
         location.pathname.includes('/blog')
      ) {
         const { current: nav_bar } = navBarRef;
         nav_bar?.classList.add('nav-bar-active');
         navBarImage.src = darkLogoUrl;
      } else {
         isLogoShouldBeDark
            ? (navBarImage.src = darkLogoUrl)
            : (navBarImage.src = whiteLogoUrl);
      }
   }, [isLogoShouldBeDark, location.pathname]);

   return (
      <StyledNavBar
         className='nav-bar'
         ref={navBarRef}
         isMobileMenuActive={isMobileMenuActive}
      >
         <StyledHamburgerMenu
            isMobileMenuActive={isMobileMenuActive}
            onClick={handleToogleIsMobileMenuActive}
            id='hamburger-menu'
         >
            <div></div>
            <div></div>
            <div></div>
         </StyledHamburgerMenu>

         <NavLink to='/' exact>
            <img
               width='250px'
               height='auto'
               alt='Finseka - Kredyty hipoteczne Kraków'
               src={whiteLogoUrl}
               ref={navBarLogoRef}
               id='nav-bar-logo'
            />
         </NavLink>
         <StyledPhoneNumberWrapper
            id='phone-number'
            style={{
               visibility: window.innerWidth < 1050 ? 'visible' : 'hidden',
               position: 'absolute',
               left: 0,
               right: 0,
               top: 0,
               bottom: 0,
               margin: 'auto',
               width: '40%',
               height: '45%',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}
         >
            <a
               href='tel:+48698235973'
               style={{
                  color: 'white',
               }}
            >
               +48 698 235 973
            </a>
         </StyledPhoneNumberWrapper>
         <div id='navbar-items'>
            <NavLink
               to='/'
               exact
               className='nav-link'
               onClick={handleToogleIsMobileMenuActive}
            >
               {t('homepage.navbar.item0')}
            </NavLink>
            {/* <div className='nav-link'> */}
            <NavLink
               to={t('pages.path.item1')}
               className='nav-link'
               onClick={handleToogleIsMobileMenuActive}
            >
               {t('homepage.navbar.item1')}
            </NavLink>
            {/* <ul class='dropdown-nav'> */}
            {/* {aboutPageNavList()} */}
            {/* <li>
                     <NavLink
                        to={{
                           pathname: '/about',
                           state: { section: 'mission' },
                        }}
                     >
                        Misja firmy
                     </NavLink>
                  </li>
                  <li>
                     <NavLink
                        to={{
                           pathname: '/about',
                           state: { section: 'values' },
                        }}
                     >
                        Nasze wartości
                     </NavLink>
                  </li>
                  <li>
                     <NavLink
                        to={{
                           pathname: '/about',
                           state: { section: 'team' },
                        }}
                     >
                        Nasz zespół
                     </NavLink>
                  </li>
                  <li>
                     <NavLink
                        to={{
                           pathname: '/about',
                           state: { section: 'office' },
                        }}
                     >
                        Siedziba naszej firmy
                     </NavLink>
                  </li>
                  <li>
                     <NavLink
                        to={{
                           pathname: '/about',
                           state: { section: 'joinus' },
                        }}
                     >
                        Dołącz do nas
                     </NavLink>
                  </li> */}
            {/* </ul> */}
            {/* </div> */}
            <NavLink
               to={t('pages.path.item2')}
               className='nav-link'
               onClick={handleToogleIsMobileMenuActive}
            >
               {t('homepage.navbar.item5')}
            </NavLink>
            <NavLink
               to={t('pages.path.item4')}
               className='nav-link'
               onClick={handleToogleIsMobileMenuActive}
            >
               {t('homepage.navbar.item2')}
            </NavLink>
            {/* <NavLink
               to='/ranking'
               className='nav-link'
               onClick={handleToogleIsMobileMenuActive}
            >
               {t('homepage.navbar.item4')}
            </NavLink> */}
            {/* <NavLink
               to='/blog'
               className='nav-link'
               onClick={handleToogleIsMobileMenuActive}
            >
               {t('homepage.navbar.item6')}
            </NavLink> */}
            <NavLink
               to={t('pages.path.item3')}
               className='nav-link'
               onClick={handleToogleIsMobileMenuActive}
            >
               {t('homepage.navbar.item3')}
            </NavLink>
            <a
               href=' https://wa.me/48698235973'
               aria-label='Whatsapp'
               target='_blank'
            >
               <WhatsAppIcon
                  style={{
                     color: '#25D366',
                     fontSize: '4vh',
                  }}
               />
            </a>
            <StyledPhoneNumberWrapper id='phone-number'>
               <CallIcon />
               <a href='tel:+48698235973'>+48 698 235 973</a>
            </StyledPhoneNumberWrapper>
            {/* <CustomDropdown
               className='language-dropdown'
               simple
               onClick={handleChangeLanguage}
               buttonIcon={LanguageIcon}
               dropdownList={[
                  <a
                     href='/'
                     locale={'en'}
                     className='dropdown-item'
                     style={{ color: 'black', fontSize: '12px' }}
                  >
                     <span>English</span>
                  </a>,
                  <a
                     href='/'
                     locale={'pl'}
                     className='dropdown-item'
                     style={{ color: 'black', fontSize: '12px' }}
                  >
                     <span>Polish</span>
                  </a>,
               ]}
            /> */}
            <StyledChangeLanguageBox className='language-box'>
               <a locale={'pl'} onClick={() => handleChangeLanguage('pl')}>
                  PL
               </a>{' '}
               |{' '}
               <a locale={'en'} onClick={() => handleChangeLanguage('en')}>
                  EN
               </a>
            </StyledChangeLanguageBox>
         </div>
      </StyledNavBar>
   );
};

export default NavBar;
