import React from 'react';
import { useTranslation } from 'react-i18next';

import {
   StyledContainer,
   StyledInfoBox,
   StyledIconWrapper,
   StyledTextWrapper,
} from './InfoContainer.css.js';

// material icons
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';

const InfoContainer = ({ flexDirection }) => {
   const { t } = useTranslation();
   return (
      <StyledContainer
         style={{
            flexDirection,
         }}
         flexDirection={flexDirection === 'row'}
      >
         <StyledInfoBox>
            <StyledIconWrapper>
               <RoomOutlinedIcon />
            </StyledIconWrapper>
            <StyledTextWrapper>
               Imperial Business Center
               <br />
               ul. Wadowicka 7
               <br />
               30-363 Kraków
               <br />
               Polska
            </StyledTextWrapper>
         </StyledInfoBox>
         <StyledInfoBox>
            <StyledIconWrapper>
               <PhoneOutlinedIcon />
            </StyledIconWrapper>
            <StyledTextWrapper>
               Jakub Ossowski <br /> +48 698 235 973
               <br />
               <a
                  style={{
                     color: 'white',
                  }}
                  href='mailto:info@finseka.pl'
               >
                  info@finseka.pl
               </a>
               <br />
               {t('footer')} 9:00 - 20:00
               <br />
            </StyledTextWrapper>
         </StyledInfoBox>
         <StyledInfoBox>
            <StyledIconWrapper>
               <BusinessCenterOutlinedIcon />
            </StyledIconWrapper>
            <StyledTextWrapper>
               Finseka Sp. z.o.o. <br />
               ul. Wadowicka 7<br />
               30-363 Kraków
               <br />
               NIP: 6793220081
               <br />
               <br />
               BANK: mBank
               <br />
               IBAN: PL 24 1140 2004 0000 3902 8155 6734
               <br />
            </StyledTextWrapper>
         </StyledInfoBox>
      </StyledContainer>
   );
};

export default InfoContainer;
