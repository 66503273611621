import styled, { keyframes } from 'styled-components';

export const underlineAnimation = keyframes`
   0%{
      width:0%;
   }
   100%{
      width:100%;
   }
`;
const backgroundUrl = '/img/about-img.jpg';
const figureImageUrl = '/img/about-image2.jpg';

export const StyledAboutPageWrapper = styled.main`
   width: 100%;
   position: relative;
   // margin-bottom: 40vh;
`;

export const StyledAboutTopContainer = styled.div`
   width: 100%;
   height: 100vh;

   padding-top: 10%;

   &:after {
      content: '';
      z-index: 0;
      background-image: url(${backgroundUrl});
      background-size: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 20% 20%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0;
      position: absolute;
      // MOBILE
      @media (max-width: 1050px) {
         background-position: 30% 20%;
      }
   }
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   > h2,
   p {
      z-index: 200;
      font-size: 2.5vh;
      color: white;
   }
   > .about-page-title {
      z-index: 200;
      color: white;
      // text-transform: uppercase;
      font-size: 5vh;
      position: relative;
      &:after {
         animation: ${underlineAnimation} 1s ease forwards;
         animation-delay: 1s;
         content: '';
         z-index: 100;
         width: 0%;
         left: 0;
         bottom: 0%;
         // right: 0;
         margin: auto;
         border-bottom: 3px solid ${({ theme }) => theme.colors.purple.light};
         position: absolute;
      }
   }

   > p {
      padding: 0 30%;
      line-height: 30px;
      text-align: center;
      font-size: 2.5vh;
   }

   // mobile
   @media (max-width: 960px) {
      padding-top: 0%;
      > p {
         padding: 0 5%;
      }
   }
`;

export const StyledAboutBottomContainer = styled.section`
   width: 95%;
   box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%),
      0 8px 10px -5px rgb(0 0 0 / 20%);
   border-radius: 6px;
   background: white;
   margin-left: 2.5%;
   margin-top: -6vh;
   transform: translateY(5vh);
   padding-top: 5vh;
   z-index: 1000;

   position: relative;
`;
export const StyledMissionContainer = styled.div`
   z-index: 100;
   width: 100%;
   height: 30vh;
   display: flex;
   align-items: center;
   flex-direction: column;
   padding: 0 15%;
   .mission-title {
      flex: 1;
      text-align: center;
      font-size: 6vh;

      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }

   > p {
      text-align: center;
      font-size: 4vh;
      line-height: 4.5vh;
      color: #696969;
      flex: 1;
   }
   // mobile
   @media (max-width: 1050px) {
      flex-direction: column;
      height: 40vh;
      padding: 0 5%;
      justify-content: space-around;
      .mission-title {
         flex: 1;
         text-align: center;
         font-size: 2.4rem;
      }
      > p {
         font-size: 3vh;
         line-height: 3.5vh;
         flex: 4;
         text-align: center;
      }
      .mission-title {
         visibility: visible;
      }
   }
`;
export const StyledImageSpace = styled.figure`
   width: 100%;
   height: 90vh;
   background-image: url(${figureImageUrl});
   background-size: 100%;
   background-repeat: no-repeat;
   background-position: center;
   position: relative;
   // &:after {
   //    transition: 0.5s ease;
   //    content: '';
   //    z-index: 0;
   //    background: rgba(255, 255, 255, 0.8);
   //    width: 50%;
   //    height: 100%;
   //    left: 0;
   //    bottom: 0;
   //    right: 0;
   //    margin: 0;
   //    position: absolute;
   // }
   // &:before {
   //    transition: 0.5s ease;
   //    content: '';
   //    z-index: 0;
   //    background: rgba(255, 255, 255, 0.8);
   //    width: 50%;
   //    height: 100%;
   //    right: 0;
   //    bottom: 0;
   //    right: 0;
   //    margin: 0;
   //    position: absolute;
   // }

   // mobile
   @media (max-width: 1050px) {
      background-size: cover;
   }
`;
