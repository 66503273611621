import React from 'react';

import {
   StyledLoadingIndicatorWrapper,
   StyledLoadingIndicator,
} from './LoadingIndicator.css';

const LoadingIndicator = ({ width, height }) => {
   return (
      <StyledLoadingIndicatorWrapper
         style={{
            width,
            height,
         }}
      >
         <StyledLoadingIndicator>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
         </StyledLoadingIndicator>
      </StyledLoadingIndicatorWrapper>
   );
};

export default LoadingIndicator;
