import styled from 'styled-components';

export const StyledStepWrapper = styled.section`
   width: 100%;
   position: relative;
   display: flex;

   > .image-container {
      flex:1;
   }
   > .steps-card {
      flex:1;
   }
   
   &::last-child{
      position: relative;
      &:before {
         border-bottom: 3px solid #3c4858;
         position: Absolute;
         bottom: -2%;
         right: 0;
         left: 0;
         margin: auto;
         content: '';
         width: 50vw;
         height: 100%;
      }
   }
   &:nth-child(odd) {
    
      > h4 {
         text-align: end;
      }
    
   }
   > canvas {
      width: 100%;
      height: 100%;
      position: absolute;
   }
   > #step-six-title {
      // padding: 0;
      // text-align: center;
      font-size: 20vh;
   }
   > h4 {
      display:none;
      padding: 0 5%;

      font-size: 10vw;
      color: grey;
      opacity: 0.2;
   }

   > .image-container {
      width:50%;
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
         width: 80%;
         height: 80%;
      }
   }

   .step-one-button {
      position:absolute !important;
      left:0;
      right:0;
      bottom:25%;
      margin:auto;
      width: 220px;
      z-index:100;
      display: flex;
      align-items: center;
      padding: 15px;
      color: white;
      cursor: pointer;
      font-size: 1.5vh !important;
      @media (max-width: 1050px) {
         bottom:20%;
      }

      >  span a {
         font-weight: 500 !important;
         font-size: 1.5vh !important;
         color: white;
         &:hover {
            transform: none !important;
         }
      }
      &:hover {
         color: ${({ theme }) => theme.colors.purple.light};
         > span a {
            color: ${({ theme }) => theme.colors.purple.light} !important;
         }
         &:after {
            background: white;
         }
      }
      
      &:after {
         transition: 0.2s ease;
         z-index: -10;
         width: 105% !important;
         height: 105% !important;
         border-radius: 60px;
         border-top-right-radius: 0px;
         position: absolute;
         left: 0;
         right: 0;
         margin: auto;
         content: '';
         background: ${({ theme }) => theme.colors.purple.light};
      }
   }}
   
   // MOBILE
   @media (max-width: 1050px) {
      // margin-bottom: 25vh;
      .text-container {
         // height: 200%;
         p {
            line-height:1;
            font-size: 2.5vh;
         }
      }
      .steps-card-header {
         text-align:center;
         font-size: 2.5vh;
      }
      > .image-container {
         width:100%;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom:50%;
         > svg {
            width: 280%;
            height: 280%;
         }
      }
      &:nth-child(odd),
      &:nth-child(even) {
         flex-direction:column;
         > h4 {
            font-size: 8vh !important;
         }
         > .image-container {
        
            grid-row-end: 3;
         }
         .steps-card {
         
            padding: 0;
         }
      }
   }
   // MOBILE
   @media (max-width: 960px) {
      .text-container {
         p {
            font-size: 2.5vh !important;
            line-height:3vh;
         }
     
   }
`;

export const StyledStepCard = styled.div`
   width: 100%;
   height: 100%;
   padding: 20% 5%;

   // MOBILE
   @media (max-width: 1050px) {
      padding: 15% 5%;
      transform: translateY(-20%);
   }
`;
export const StyledCardHeader = styled.header`
   height: 100px;
   display: flex;
   align-items: center;

   z-index: 10000;

   background: ${({ theme }) => theme.colors.blue.normal};
   color: white;
   padding: 1rem 15px;
   margin-top: -30px;
   margin-left: 15px;
   margin-right: 15px;
   border-radius: 6px;
   margin-bottom: 0;
   > h3 {
      width: 100%;
      text-align: center;
      font-size: 4vh;
   }
   @media (max-width: 1050px) {
      height: 100px;
   }
`;

export const StyledCardBody = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   flex: 1 1 auto;
   padding: 0.9375rem 1.875rem;
   color: rgba(0, 0, 0, 0.87);
   width: 100%;
   height: 100%;
   border: 0;
   display: flex;
   position: relative;
   font-size: 0.875rem;
   min-width: 0;
   word-wrap: break-word;
   background: #fff;
   box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
      0 1px 5px 0 rgb(0 0 0 / 12%);
   border-radius: 6px;
   // margin-top: -5%;
   transform: translateY(-5%);
   flex-direction: column;
   z-index: -1;
   > p {
      color: rgb(89, 89, 89);
      font-family: 'Roboto', sans-serif;
      font-size: 2.5vh;
      line-height: 3.5vh;
      padding: 5% 0;
      text-align: center;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }
`;
