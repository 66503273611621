import styled from 'styled-components';

export const StyledReviewContainer = styled.div`
   // opacity: 0;
   // transform: translateY(100px);
   width: 17vw;
   padding: 2% 2%;
   display: flex;
   flex-direction: column;
   // position: absolute;
   // &:after {
   //    position: absolute;
   //    width: 100%;
   //    height: 85%;
   //    top: 0;
   //    right: 0;
   //    left: 0;
   //    z-index: 0;
   //    border-radius: 6px;
   //    margin: auto;
   //    border: 1px solid ${({ theme }) => theme.colors.blue.normal};
   //    content: '';
   // }

   // &:before {
   //    position: absolute;
   //    width: 100%;
   //    height: 85%;
   //    top: 0px;
   //    right: 0px;
   //    z-index: 0;
   //    border-radius: 6px;
   //    margin: auto;
   //    box-shadow: 10px 10px 9px 0px rgba(0, 0, 0, 0.4);

   //    // background: ${({ theme }) => theme.colors.purple.light};
   //    content: '';
   // }
   // @media (max-width: 1350px) {
   //    &:after {
   //       height: 76%;
   //    }
   //    &:before {
   //       height: 76%;
   //    }
   // }
   @media (max-width: 1150px) {
      &:after {
         height: 80%;
      }
      &:before {
         height: 80%;
      }
   }
`;

export const StyledCustomerAvatarContainer2 = styled.div`

   height:150px;
   z-index: 100;
   position: relative;
 
      transform:translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      width: 100%;
      height: 100%;
      > figure {
         border-radius: 100%;
         width: 6vw;
         height: 6vw;
         box-shadow: 0px 5px 5px 0px grey;
         overflow: hidden;
         > img {
            width: 100%;
         }
      }
      > span {
         width: 100%;
         height: 20%;
         text-align: center;
         font-size: 1.2vh;
         padding-top: 10px;
         font-weight: 600;
         text-transform: uppercase;
         color: black;
         transform:translateY(5px);
      }
   }

   @media (max-width:1700px){
      @media (max-width: 1050px) {
         
         transform: translateY(0px);
      > div:nth-child(1) {
         > figure {
               transform:translateY(25px);

         }
         >span{
            transform:translateY(25px);
         }
   }
   }

   // mobile
   @media (max-width: 1050px) {
    
         > figure {
            width: 100px;
            height: 100px;
         }
         >span{
            font-size:2vh;
         }
   }
`;
export const StyledCustomerReviewText2 = styled.div`
   flex: 2;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   flex-direction: column;
   transform: translateY(-100px);
   > p {
      padding: 0% 2%;
      text-align: center;
      color: black;
      position: relative;
      z-index: 100;
      font-size: 1.5vh;
      > svg:nth-child(1) {
         z-index: -10;
         position: absolute;
         top: -30%;
         left: 0;
         margin: auto;
         font-size: 15vh;
         color: rgba(80, 0, 105, 0.7);
      }
      > svg:nth-child(2) {
         z-index: -10;
         position: absolute;
         bottom: -30%;
         right: 0;
         margin: auto;
         font-size: 15vh;
         color: rgba(80, 0, 105, 0.7);
      }
   }
   > .rating-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
         z-index: 100;
         font-size: 2vh;
         color: gold;
      }
   }
   > span {
      z-index: 100;
      font-size: 1.5vh;
      color: ${({ theme }) => theme.colors.purple.light};
      padding: 5px 2%;
   }
   @media (max-width: 1050px) {
      transform: translateY(0px);
      > span {
         font-size: 2vh !important;
      }
      > p {
         font-size: 2.2vh !important;
      }
   }
`;

export const StyledSectionContainer = styled.section`
   width: 100%;
   padding-left: 45vw;
   overflow: visible;

   display: flex;
   flex-direction: column;
   // reviews card styles
   // @media (min-width: 1050px) {
   //    > div:nth-child(1) div {
   //       // &:nth-child(1) {
   //       //    top: 4%;
   //       //    left: 2%;
   //       // }
   //       // &:nth-child(2) {
   //       //    top: 10%;
   //       //    right: 5%;
   //       // }
   //       // &:nth-child(3) {
   //       //    bottom: 2%;
   //       //    left: 3%;
   //       // }
   //    }
   //    > div:nth-child(2) div {
   //       &:nth-child(1) {
   //          top: 15%;
   //       }
   //       &:nth-child(2) {
   //          top: 0%;
   //          left: 35%;
   //       }
   //       &:nth-child(3) {
   //          bottom: 4%;
   //          right: 15%;
   //       }
   //    }
   //    > div:nth-child(3) div {
   //       &:nth-child(1) {
   //          top: 10%;
   //          left: 2%;
   //       }
   //       &:nth-child(2) {
   //          top: 15%;
   //          right: 5%;
   //       }
   //       &:nth-child(3) {
   //          bottom: 5%;
   //          left: 3%;
   //       }
   //    }
   // }
   // mobile
   @media (max-width: 1050px) {
      flex-direction: column;
      padding: 0;
   }
`;

export const StyledFeatureContent = styled.article`
   flex: 1;
   display: flex;
   flex-direction: column;
   .services-title {
      visibility: hidden;
      position: relative;
      flex: 1;
      font-size: 5vh;
   }
   .services-title .text-wrapper {
      position: relative;
      display: inline-block;
      padding-top: 0.2em;
      padding-right: 0.05em;
      padding-bottom: 0.1em;
      overflow: hidden;
   }
   .services-title .letter {
      display: inline-block;
      line-height: 1em;
   }
   .services-title .letter:nth-child(4),
   .services-title .letter:nth-child(5),
   .services-title .letter:nth-child(5),
   .services-title .letter:nth-child(6),
   .services-title .letter:nth-child(7),
   .services-title .letter:nth-child(8),
   .services-title .letter:nth-child(9),
   .services-title .letter:nth-child(10),
   .services-title .letter:nth-child(11) {
      color: ${({ theme }) => theme.colors.blue.normal};
   }
   > div {
      padding: 5% 10% 20% 10%;
      height: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      > h3 {
         font-weight: 700;
         font-size: 3.5vh;
         position: relative;
         &:after {
            content: '';
            z-index: 100;
            width: 25%;
            left: 0;
            right: 0;
            bottom: -5%;
            margin: auto;
            border-bottom: 4px solid ${({ theme }) => theme.colors.purple.light};
            position: absolute;
         }
      }
      > p {
         // text-align: center;
         font-size: 2.5vh;
      }
   }
   // MOBILE
   @media (max-width: 1050px) {
      > .services-title {
         text-align: center;
      }
   }
`;
