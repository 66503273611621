import styled, { keyframes } from 'styled-components';
const bubbleTextAnimation = keyframes`
	0% {
		opacity:0;
	}
	100% {
		opacity:1 !important;
	}
`;
export const StyledProcessWrapper = styled.div`
   width: 95%;
   box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%),
      0 8px 10px -5px rgb(0 0 0 / 20%);
   border-radius: 6px;
   background: white;
   margin-left: 2.5%;
   margin-top: -1vh;
   padding-top: 2.5vh;
   > div:nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: center;
   }
   > div:nth-child(3) h2 {
      text-align: center;
      line-height: 6vh;
      font-size: 5vh;
      font-weight: 300;
      font-family: 'Roboto', sans-serif;
      > span {
         color: ${({ theme }) => theme.colors.purple.light};
      }
   }

   @media (max-width: 1050px) {
      height: 25%;
      > div:first-child h2 {
         font-size: 4vh;
      }
      > div:nth-child(3) h2 {
         font-size: 2.4rem;
         line-height: 2.6rem;
      }
   }

   > #step-six-container {
      position: relative;
      &:before {
         border-bottom: 3px solid #3c4858;
         position: Absolute;
         bottom: -2%;
         right: 0;
         left: 0;
         margin: auto;
         content: '';
         width: 60vw;
         height: 100%;
      }
   }

   // bubble active class
   .active-bubble {
      opacity: 1 !important;
      z-index: 10000 !important;
      width: ${40 * 1.6}vh !important;
      height: ${40 * 1}vh !important;

      top: 35% !important;
      left: -5% !important;
      &:after {
         background: radial-gradient(
            ellipse at center,
            ${({ theme }) => theme.colors.purple.light} 0%,
            ${({ theme }) => theme.colors.blue.normal} 60%
         ) !important;
      }
      > h5 {
         font-size: 6vh !important;
      }
      > p {
         font-size: 2vh !important;
      }
      > .bubble-text-expansion {
         opacity: 0;

         display: block !important;
         color: white !important;
         font-size: 2vh !important;
         text-align: center !important;
         animation: ${bubbleTextAnimation} 3.5s ease;
         animation-fill-mode: forwards !important;
      }
      > .bubble-icon {
         font-size: 30vh !important;
      }
      // mobile active
      @media (max-width: 1050px) {
         width: ${25 * 1.6}vh !important;
         height: ${25 * 1}vh !important;
         top: 20% !important;
         left: 0 !important;
         right: 0 !important;
         margin: auto;
         > h5 {
            font-size: 4vh !important;
         }
         > .bubble-text-expansion {
            padding: 0 2%;
         }
      }
   }
`;
