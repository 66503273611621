import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger);
CSSPlugin.defaultForce3D = false;

export const stepFourAnimations = () => {
   // title
   gsap.from('#step-four-title', {
      scrollTrigger: {
         trigger: '#step-four-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   // image elements
   gsap.from('#sign_docs, #Vector_19', {
      scrollTrigger: {
         trigger: '#step-four-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      y: '-10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   // signature
   gsap.from('#sign_docs #Vector_10', {
      scrollTrigger: {
         trigger: '#step-four-container',
         scrub: true,
         start: '60% bottom',
         end: '80% bottom',
      },
      opacity: 0,
      ease: 'easeInOut',
   });
   gsap.from('#sign_card', {
      scrollTrigger: {
         trigger: '#step-four-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      rotate: '-20',
      y: '-10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   // card lines
   gsap.from(
      '#sign_card #Vector_12,#sign_card #Vector_13,#sign_card #Vector_14,#sign_card #Vector_15,#sign_card #Vector_16,#sign_card #Vector_17,#sign_card #Vector_18',
      {
         scrollTrigger: {
            trigger: '#step-four-container',
            scrub: true,
            start: '50% bottom',
            end: '70% bottom',
         },
         opacity: 0,
         ease: 'easeInOut',
      }
   );
   // client
   gsap.from('#sign_client', {
      scrollTrigger: {
         trigger: '#step-four-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '15vh',
      ease: 'easeInOut',
   });

   // text
   gsap.from('#step-four-card', {
      scrollTrigger: {
         trigger: '#step-four-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      y: '-15vh',
      opacity: 0,
      ease: 'easeInOut',
   });
};
