import styled from 'styled-components';

export const StyledFooterContainer = styled.footer`
   width: 100vw;
   height: 40vh;
   // position: absolute;
   // bottom: -15%;
   margin: auto;
   // transform: translateY(-10%);
   background: ${({ theme }) => theme.colors.blue.normal};
   z-index: -100;
   // visibility: hidden;
   // mobile
   @media (max-width: 1050px) {
      padding: 10% 0;
      height: 75vh;
   }
`;
