import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger);
CSSPlugin.defaultForce3D = false;

export const stepOneAnimations = () => {
   // title
   gsap.from('#step-one-title', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '-10vh',
      opacity: 0,
      ease: 'easeInOut',
   });
   // image elements
   gsap.from('#phone-name', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '25vh',
      y: '-10vh',

      ease: 'easeInOut',
   });
   gsap.from('#logo', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '-25vh',
      y: '-10vh',

      ease: 'easeInOut',
   });

   gsap.from('#phone-icon1, #phone-icon4', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '-25vh',
      y: '10vh',

      ease: 'easeInOut',
   });
   gsap.from('#phone-icon2, #phone-icon5', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },

      y: '25vh',

      ease: 'easeInOut',
   });
   gsap.from('#phone-icon3, #phone-icon6', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      x: '25vh',
      y: '-10vh',

      ease: 'easeInOut',
   });
   gsap.from('#background', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      y: '25vh',
      ease: 'easeInOut',
   });
   gsap.from('#bush1, #bush2', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: '30% bottom',
         end: '50% bottom',
      },
      y: '25vh',

      ease: 'easeInOut',
   });
   gsap.from('#man-with-phone', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: '10% bottom',
         end: '70% bottom',
      },
      x: '10vh',

      ease: 'easeInOut',
   });

   // text
   gsap.from('#step-one-card', {
      scrollTrigger: {
         trigger: '#step-one-container',
         scrub: true,
         start: 'top bottom',
         end: '50% bottom',
      },
      y: '-15vh',
      opacity: 0,

      ease: 'easeInOut',
   });
};
