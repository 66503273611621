import styled, { keyframes } from 'styled-components';

export const underlineAnimation = keyframes`
   0%{
      width:0%;
   }
   100%{
      width:25%;
   }
`;

const backgroundUrl = '/img/contact-page-img.jpg';

export const StyledAboutPageContainer = styled.main`
   width: 100vw;
`;

export const StyledHeaderContact = styled.div`
   width: 100vw;
   height: 100vh;
   background-image: url(${backgroundUrl});
   background-size: cover;
   background-position-x: -15rem;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      opacity: 0.25;
      background: black;
      z-index: 0;
   }
`;

export const StyledTopSectionContact = styled.section`
   // height: 100vh;
   width: 50%;
   position: relative;
   z-index: 100;
   display: flex;
   padding: 0% 7%;
   justify-content: center;
   // align-items: center;
   flex-direction: column;
   .input-error-span {
      color: red;
      font-weight: 500;
      width: 100%;
      font-size: 1.8vh;
   }

   .dark-background input {
      color: white !important;
   }
   > .contact-page-title {
      z-index: 200;
      color: white;
      font-size: 5vh;
      position: relative;
      &:after {
         animation: ${underlineAnimation} 1s ease forwards;
         animation-delay: 1s;
         content: '';
         z-index: 100;
         width: 0%;
         left: 0;
         bottom: 0%;
         // right: 0;
         margin: auto;
         border-bottom: 3px solid ${({ theme }) => theme.colors.purple.light};
         position: absolute;
      }
   }

   .form-input {
      color: black;
   }
   > #send-button {
      background-color: ${({ theme }) => theme.colors.blue.normal};
      transform-origin: 0 50%;
      box-shadow: none;
   }
   // mobile
   @media (max-width: 1050px) {
      width: 100%;
   }
`;
export const StyledBottomSectionWrapper = styled.section`
   margin-top: -1vh;
   z-index: 100;
   width: 95%;
   margin-bottom: 5%;
   box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%),
      0 8px 10px -5px rgb(0 0 0 / 20%);
   border-radius: 6px;
   background: white;
   margin-left: 2.5%;
   // padding: 0 5%;
   display: flex;
   flex-direction: column;
   > #google-map {
      width: 100%;
      z-index: 150;
      height: 600px;
   }
   // mobile
   @media (max-width: 1050px) {
      flex-direction: column;
      > #google-map {
         width: 100%;
         height: 500px;
      }
   }
`;
export const StyledAddressContainer = styled.div`
   background: ${({ theme }) => theme.colors.blue.normal};
   width: 100%;
   height: 100%;
   z-index: 150;
   display: flex;
   border-top-left-radius: 6px;
   border-top-right-radius: 6px;

   // mobile
   @media (max-width: 1050px) {
      width: 100%;
      height: 70%;
      > div {
         flex-direction: column !important;
      }
   }
`;
