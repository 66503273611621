import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
   authDomain: 'finseka-website.firebaseapp.com',
   projectId: 'finseka-website',
   storageBucket: 'finseka-website.appspot.com',
   messagingSenderId: '449599172556',
   appId: '1:449599172556:web:b0d74070aead4ea3c0b769',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
