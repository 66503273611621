import { gsap } from 'gsap/dist/gsap.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import CSSPlugin from 'gsap/CSSPlugin';

gsap.registerPlugin(ScrollTrigger);
CSSPlugin.defaultForce3D = false;

export const platformSectionAnimations = () => {
   const videoPlayer = document.querySelector('#video-player-homepage');

   const textOnCard1 = document.querySelector('#text-card-1').childNodes;
   const textOnCard3 = document.querySelector('#text-card-3').childNodes;

   textOnCard1.forEach(text => {
      gsap.from(text, {
         y: 100,
         opacity: 0,
         stagger: 0.2,
         scrollTrigger: {
            trigger: '#cards-container-1',
            start: 'top 80%',
         },
      });
   });
   textOnCard3.forEach(text => {
      gsap.from(text, {
         y: 100,
         opacity: 0,
         stagger: 0.2,
         scrollTrigger: {
            trigger: '#cards-container-3',
            start: 'top 80%',
         },
      });
   });

   gsap.from('#text-card-2', {
      y: 100,
      opacity: 0,
      scrollTrigger: {
         trigger: '#laptop-card',
         start: 'top 80%',
      },
   });
};
