import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
   StyledServicesContainer,
   StyledServicesContent,
   StyledCardsContainer,
   StyledServiceCard,
   StyledServiceNumberWrapper,
   StyledServiceTextWrapper,
} from './ServicesSections.css.js';
import { StyledFeatureCard } from 'views-components/ReviewsMenu/ReviewsMenu.css.js';
import { StyledFeatureNumberWrapper } from 'views-components/ReviewsMenu/ReviewsMenu.css.js';
import { StyledFeatureTextWrapper } from 'views-components/ReviewsMenu/ReviewsMenu.css.js';

// icons
import SecurityIcon from '@material-ui/icons/Security';

const ServicesSection = () => {
   const { t } = useTranslation();

   return (
      <StyledServicesContainer id='services-container'>
         <StyledServicesContent>
            <h1
               className='services-title'
               dangerouslySetInnerHTML={{
                  __html: t('about.values.title', {
                     interpolation: { escapeValue: false },
                  }),
               }}
            ></h1>
         </StyledServicesContent>
         <StyledCardsContainer>
            <StyledServiceCard>
               <StyledServiceNumberWrapper className='card-title'>
                  <span class='material-icons-outlined'>
                     volunteer_activism
                  </span>
               </StyledServiceNumberWrapper>
               <StyledServiceTextWrapper className='card-text'>
                  <h3>{t('about.values.card1-title')}</h3>
                  <h4>{t('about.values.card1-text')}</h4>
               </StyledServiceTextWrapper>
            </StyledServiceCard>
            <StyledServiceCard>
               <StyledServiceNumberWrapper className='card-title'>
                  <span class='material-icons-outlined'>security</span>
               </StyledServiceNumberWrapper>
               <StyledServiceTextWrapper className='card-text'>
                  <h3>{t('about.values.card2-title')}</h3>
                  <h4>{t('about.values.card2-text')}</h4>
               </StyledServiceTextWrapper>
            </StyledServiceCard>
            <StyledServiceCard>
               <StyledServiceNumberWrapper className='card-title'>
                  <span class='material-icons-outlined'>business_center</span>
               </StyledServiceNumberWrapper>
               <StyledServiceTextWrapper className='card-text'>
                  <h3>{t('about.values.card3-title')}</h3>
                  <h4>{t('about.values.card3-text')}</h4>
               </StyledServiceTextWrapper>
            </StyledServiceCard>
         </StyledCardsContainer>
      </StyledServicesContainer>
   );
};

export default ServicesSection;
