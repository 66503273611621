import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ScrollTrigger from 'gsap/ScrollTrigger';

import Parallax from '../../components/Parallax/Parallax.js';
import {
   ServicesSection,
   TeamSection,
   OfficeSection,
   JoinUsSection,
} from '../../views-components/AboutPageSections';
import {
   StyledAboutPageWrapper,
   StyledAboutTopContainer,
   StyledAboutBottomContainer,
   StyledMissionContainer,
   StyledImageSpace,
} from './AboutPage.css.js';
import { mainSectionAnimations } from 'animations/aboutPageAnimations';
import { Footer } from 'views-components';
import InfoContainer from 'views-components/InfoContainer/InfoContainer.js';

const AboutPage = () => {
   const { state } = useLocation();
   const { t } = useTranslation();

   useEffect(() => {
      // title and animations
      document.title = t('pages.titles.item1');
      mainSectionAnimations();
      window.scrollTo({
         top: 0,
      });
      // scroll to section function (if user click in list element)
      // setTimeout(() => {
      //    if (state?.section) {
      //       switch (state.section) {
      //          case 'mission':
      //             document
      //                .getElementById('mission-container')
      //                .scrollIntoView({ behavior: 'smooth' });
      //             break;
      //          case 'values':
      //             document
      //                .getElementById('services-container')
      //                .scrollIntoView({ behavior: 'smooth' });
      //             break;
      //          case 'team':
      //             document
      //                .getElementById('teams-container')
      //                .scrollIntoView({ behavior: 'smooth' });
      //             break;
      //          case 'office':
      //             document
      //                .getElementById('office-section-container')
      //                .scrollIntoView({ behavior: 'smooth' });
      //             break;
      //          case 'joinus':
      //             document
      //                .getElementById('join-container')
      //                .scrollIntoView({ behavior: 'smooth' });
      //             break;
      //          default:
      //             break;
      //       }
      //    }
      // }, 500);

      // clean function
      return () => {
         ScrollTrigger.getAll().forEach(t => {
            if (
               t.vars.id === 'navbar-trigger' ||
               t.vars.id === 'navbar-trigger-2'
            ) {
               return;
            } else {
               t.kill();
            }
         });
      };
   }, []);
   return (
      <>
         <StyledAboutPageWrapper id='about-page-container'>
            <Parallax filter='dark' large style={{ zIndex: '-50' }}>
               <StyledAboutTopContainer>
                  <h2 className='about-page-title'>
                     {t('about.header.title-text')}
                  </h2>
                  <p
                     dangerouslySetInnerHTML={{
                        __html: t('about.header.paragraph-text', {
                           interpolation: { escapeValue: false },
                        }),
                     }}
                  ></p>
               </StyledAboutTopContainer>
            </Parallax>
            <StyledAboutBottomContainer id='about-bottom-container'>
               <StyledMissionContainer id='mission-container'>
                  <h3
                     className='mission-title'
                     dangerouslySetInnerHTML={{
                        __html: t('about.mission.title', {
                           interpolation: { escapeValue: false },
                        }),
                     }}
                  ></h3>
                  <p
                     dangerouslySetInnerHTML={{
                        __html: t('about.mission.text', {
                           interpolation: { escapeValue: false },
                        }),
                     }}
                  ></p>
               </StyledMissionContainer>
               <ServicesSection />
               <TeamSection />
               {/* <StyledImageSpace id='about-image-space' /> */}
               <OfficeSection id='office-section' />
               <JoinUsSection />
            </StyledAboutBottomContainer>
            <Footer />
         </StyledAboutPageWrapper>
      </>
   );
};

export default AboutPage;
