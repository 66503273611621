import styled from 'styled-components';

export const StyledNavBar = styled.nav`
   width: 100vw;
   height: 15vh;
   z-index: 100000;
   position: fixed;
   transition: 0.3s ease;
   display: flex;
   align-items: center;
   padding: 0 5% 0 1%;
   justify-content: space-between;
   > a {
      width: 60%;
   }
   > a img {
      cursor: pointer;
      width: 250px;
      margin-top: -10px;
   }
   > #navbar-items {
      width: 200%;
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
   .dropdown-nav {
      overflow: hidden;
      border-radius: 6px;
      transition: 0.3s ease;
      position: absolute;
      left: -125%;
      top: 30px;
      width: 350%;
      list-style: none;
      transform: translateY(-50%) scaleY(0);
      opacity: 0;
      background: white;
      padding: 10px 10px;
      > li {
         border-radius: 2px;
         transition: 0.2s ease;
         padding: 10px 0%;
      }
      > li:last-child {
         border-bottom: 0;
      }
      > li a {
         transition: 0.2s ease;
         font-weight: 100;
         text-align: start;
         font-size: 15px;
         width: 100%;
         height: 100%;
         color: black;
         text-decoration: none;
         padding: 10px;
      }

      > li:hover {
         background: ${({ theme }) => theme.colors.purple.light};
      }
   }
   .dropdown-text {
      text-transform: none;
      text-decoration: none;
      color: white;
      font-size: 2.2vh;
      font-weight: 300;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      transition: 0.2s ease;
   }
   > #navbar-items .nav-link {
      position: relative;
      text-decoration: none;
      color: white;
      font-size: 2.2vh;
      font-weight: 300;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      transition: 0.2s ease;
      &:hover {
         transform: translateY(-5px);
      }
      &:hover ul {
         transform: translateY(0%) scaleY(1);
         opacity: 1;
      }
      &:hover::after {
         width: 90%;
      }
      &:after {
         transition: 0.2s ease;
         z-index: -10;
         width: 0%;
         height: 100%;
         position: absolute;
         bottom: -10%;
         left: 0;
         right: 0;
         margin: auto;
         content: '';
         border-bottom: 2px solid ${({ theme }) => theme.colors.purple.light};
      }
   }
   @media (max-width: 1050px) {
      height: 10vh;
      padding: 0 2%;

      .makeStyles-button-26 {
         > span {
            color: black;
         }
      }
      > a img {
         margin-top: 10px;
         cursor: pointer;
         width: 50%;
      }
      > div .jss26 {
         background: ${({ theme }) => theme.colors.purple.light} !important;
         box-shadow: none;
      }
      > #navbar-items {
         position: absolute;
         width: 100%;
         left: 0;
         top: 0;
         height: 100vh;
         flex-direction: column;
         padding: 20% 0 60% 0;
         background: white;
         margin-left: 100%;
         transition: 0.3s ease;
         ${({ isMobileMenuActive }) =>
            isMobileMenuActive
               ? `
               margin-left: 0%;
               .nav-link{
                  color:black;
               }
               .jss26 {
                  background: ${({ theme }) => theme.colors.purple.light};
                  box-shadow: none;
               }
               
         `
               : null}
         > a {
            color: black;
         }
      }
   }
   // DROPDOWN
   .jss26 {
      background: transparent;
      box-shadow: none;
   }
   .jss26:hover {
      background: ${({ theme }) => theme.colors.purple.light};
   }
   .jss26:focus {
      background: ${({ theme }) => theme.colors.purple.light};
   }
   .jss9:hover {
      background: ${({ theme }) => theme.colors.purple.light} !important;
   }
   .jss9:hover .dropdown-item {
      color: white !important;
   }
   // REACT ROUTER ACTIVE CLASS
   .active {
      pointer-events: none;
      transform: translateY(-5px);
      &::after {
         width: 90% !important;
      }
   }
`;

export const StyledHamburgerMenu = styled.div`
   width: 50px;
   height: 55px;
   position: absolute;
   right: 5%;
   top: 3vw;
   z-index: 1000;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-around;
   padding: 15px 5px;
   display: none;
   > div {
      transition: 0.2s ease;
      border-radius: 1px;
      width: 28px;
      height: 3px;
      background: white;
   }
   @media only screen and (max-width: 1050px) {
      display: flex;
   }
   ${({ isMobileMenuActive }) =>
      isMobileMenuActive
         ? `
         >div{
            background:black
         }
         justify-content: flex-start;
      >div:first-child{
         transform:translateY(6px) rotate(45deg);
      }
      >div:last-child{
         transform: rotate(-45deg);
      }
      >div:nth-child(2){
         opacity:0;
         }
         `
         : null}
`;

export const StyledPhoneNumberWrapper = styled.span`
   position: relative;
   display: flex;
   align-items: center;
   padding: 5px;
   color: white;
   cursor: pointer;
   > a {
      font-weight: 500 !important;
      font-size: 1.6vh !important;
      color: white;
      &:hover {
         transform: none !important;
      }
   }
   &:hover {
      color: ${({ theme }) => theme.colors.purple.light};
      > a {
         color: ${({ theme }) => theme.colors.purple.light};
      }
      &:after {
         background: white;
      }
   }
   &:after {
      transition: 0.2s ease;
      z-index: -10;
      width: 105% !important;
      height: 105% !important;
      border-radius: 60px;
      border-top-right-radius: 0px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      content: '';
      background: ${({ theme }) => theme.colors.purple.light};
   }
   @media only screen and (max-width: 1050px) {
      position: relative;
      color: black;
      right: 0px;
      top: 0px;
      > a {
         font-size: 2vh !important;
         color: black;
      }
   }
`;

export const StyledChangeLanguageBox = styled.div`
   color: ${({ theme }) => theme.colors.purple.light};
   font-size: 2vh;
   > a {
      transition: 0.2s ease;
      color: white;
      cursor: pointer;
      &:hover {
         opacity: 0.5;
      }
   }
   @media only screen and (max-width: 1050px) {
      > a {
         color: black;
      }
   }
`;
