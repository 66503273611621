import React, { useEffect, useState } from 'react';

import { StyledCustomCursor } from './CustomCursor.css.js';

const CustomCursor = () => {
   const [position, setPosition] = useState({ x: 0, y: 0 });
   const [isCursorVisible, setIsCursorVisible] = useState(false);

   const onBoxEventListener = () => {
      const boxes = document.querySelectorAll('.feature-box');
      if (boxes) {
         boxes.forEach(box => {
            box.addEventListener('mousemove', changeCursorToVisible);
            box.addEventListener('mouseout', changeCursorToHidden);
         });
      }
   };

   const changeCursorToVisible = () => {
      setIsCursorVisible(true);
      document.querySelector('body').style = 'cursor:none';
   };

   const changeCursorToHidden = () => {
      setIsCursorVisible(false);
      document.querySelector('body').style = 'cursor:normal';
   };
   const addEventListeners = () => {
      document.addEventListener('mousemove', onMouseMove);
   };

   const removeEventListeners = () => {
      document.removeEventListener('mousemove', onMouseMove);
   };

   const onMouseMove = e => {
      setPosition({ x: e.clientX, y: e.clientY });
   };

   useEffect(() => {
      onBoxEventListener();
      addEventListeners();
      return () => removeEventListeners();
   }, []);

   return isCursorVisible ? (
      <StyledCustomCursor
         style={{ left: `${position.x + 10}px`, top: `${position.y + 10}px` }}
      ></StyledCustomCursor>
   ) : null;
};

export default CustomCursor;
