import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { stepTwoAnimations } from './stepTwoAnimations.js';

import {
   StyledStepWrapper,
   StyledStepCard,
   StyledCardHeader,
   StyledCardBody,
} from '../ProcessSteps.css.js';
import { ReactComponent as StepTwoImage } from '../../ProcessStepsImages/step_two.svg';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';

const StepTwo = () => {
   const { t } = useTranslation();

   useEffect(() => {
      stepTwoAnimations();
   });
   return (
      <>
         <h4 id='step-two-title' className='step-title'>
            {t('homepage.process.step-text')} 02
         </h4>
         <StyledStepWrapper
            style={{
               flexDirection:
                  window.innerWidth > 1050 ? 'row-reverse' : 'column',
            }}
            id='step-two-container'
         >
            <div className='image-container'>
               <StepTwoImage
                  style={{
                     transform: 'translateY(8%)',
                  }}
                  id='step-two-image'
               />
            </div>
            <div className='steps-card' id='step-two-card'>
               <StyledStepCard>
                  <StyledCardHeader className='steps-card-header'>
                     <h3>{t('homepage.process.step2-title')}</h3>
                  </StyledCardHeader>
                  <StyledCardBody className='text-container'>
                     <p
                        dangerouslySetInnerHTML={{
                           __html: t('homepage.process.step2-text', {
                              interpolation: { escapeValue: false },
                           }),
                        }}
                     ></p>
                  </StyledCardBody>
               </StyledStepCard>
            </div>
         </StyledStepWrapper>
      </>
   );
};

export default StepTwo;
