import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons

// core components
import styles from 'assets/jss/material-kit-pro-react/components/cardFooterStyle.js';

const useStyles = makeStyles(styles);

export default function CardFooter(props) {
   const {
      className,
      children,
      plain,
      profile,
      pricing,
      testimonial,
      ...rest
   } = props;
   const classes = useStyles();
   const cardFooterClasses = classNames({
      [classes.cardFooter]: true,
      [classes.cardFooterPlain]: plain,
      [classes.cardFooterProfile]: profile || testimonial,
      [classes.cardFooterPricing]: pricing,
      [classes.cardFooterTestimonial]: testimonial,
      [className]: className !== undefined,
   });
   return (
      <div className={cardFooterClasses} {...rest}>
         {children}
      </div>
   );
}

CardFooter.propTypes = {
   className: PropTypes.string,
   plain: PropTypes.bool,
   profile: PropTypes.bool,
   pricing: PropTypes.bool,
   testimonial: PropTypes.bool,
   children: PropTypes.node,
};
