import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Grid from '@material-ui/core/Grid';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CardAvatar from 'components/Card/CardAvatar.js';

// import color1 from 'assets/img/examples/color1.jpg';
const millenniumLogo = '/img/millenniumLogo.png';
const mbank = '/img/mbank.png';
const inglogo = '/img/inglogo.png';
const pekaoLogo = '/img/pekaobank.png';
const pkoLogo = '/img/pkobank.jpg';
const ailorbankLogo = '/img/ailorbank.png';
const bnpLogo = '/img/bnpbank.jpg';
const santanderLogo = '/img/santanderbank.jpg';
const citiLogo = '/img/citihandlowybank.jpg';
const bosLogo = '/img/bosbank.png';

import Success from 'components/Typography/Success.js';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import DateRangeIcon from '@material-ui/icons/DateRange';
import NoteIcon from '@material-ui/icons/Note';

import Divider from '@material-ui/core/Divider';

import TalkToExpert from 'views-components/TalkToExpert';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import styles from 'assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js';
const useStyles = makeStyles(styles);

import NumberFormat from 'react-number-format';
import { formatCurrency } from 'assets/theme/formatCurrency.js';

import {
   StyledRankingHeader,
   StyledCalculatorsWrapper,
} from './RankingOffers.css.js';
import Parallax from 'components/Parallax/Parallax.js';
import { rankingPageAnimations } from 'animations/rankingPageAnimations.js';
import { Footer } from 'views-components';
import gsap from 'gsap/dist/gsap';
import { useTranslation } from 'react-i18next';

function NumberFormatCustom(props) {
   const { inputRef, onChange, ...other } = props;

   return (
      <NumberFormat
         {...other}
         getInputRef={inputRef}
         onValueChange={values => {
            onChange({
               target: {
                  name: props.name,
                  value: values.value,
               },
            });
         }}
         thousandSeparator
         isNumericString
         prefix='$'
      />
   );
}

function RankingOffers() {
   const classes = useStyles();
   const [amountBorrow, setAmountBorrow] = React.useState(400000);
   const [DownPayment, setDownPayment] = React.useState(60000);
   const [lengthMortgage, setLengthMortgage] = React.useState(22);

   const { t } = useTranslation();

   const theme = useTheme();

   const medium = useMediaQuery(theme.breakpoints.down('md'));
   const small = useMediaQuery(theme.breakpoints.down('sm'));
   const tooSmall = useMediaQuery(theme.breakpoints.down('xs'));

   const banksInformation = [
      {
         name: 'Mlllennium Bank',
         interest: '2.1',
         RPSO: '2.1',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: millenniumLogo,
      },
      {
         name: 'ING Bank',
         interest: '2.25',
         RPSO: '2.9',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: inglogo,
      },
      {
         name: 'mBank',
         interest: '2.0',
         RPSO: '1.6',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: mbank,
      },
      {
         name: 'Banku Pekao S.A.',
         interest: '2.2',
         RPSO: '2.1',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: pekaoLogo,
      },
      {
         name: 'PKO BP',
         interest: '2.0',
         RPSO: '1.5',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: pkoLogo,
      },
      {
         name: 'Alior Banku',
         interest: '2.2',
         RPSO: '1.5',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: ailorbankLogo,
      },
      {
         name: 'BNP Paribas',
         interest: '2.5',
         RPSO: '2.3',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: bnpLogo,
      },
      {
         name: 'Santander',
         interest: '2.2',
         RPSO: '2.1',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: santanderLogo,
      },
      {
         name: 'Citi Handlowy',
         interest: '2.2',
         RPSO: '2.1',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: citiLogo,
      },
      {
         name: 'Banku Ochrony Środowiska',
         interest: '2.2',
         RPSO: '2.1',
         amount_repaid: '143.342',
         instalments: '1.400',
         picture: bosLogo,
      },
   ];
   // DateRangeIcon

   const [paymentMonth, setPaymentMonth] = React.useState(() => {
      var paymentMonthAll = [];
      var p = amountBorrow - DownPayment;
      var t = lengthMortgage * 12;

      banksInformation.forEach(bankInfo => {
         var r = (parseFloat(bankInfo.interest) / 12) * 0.01;

         var a = p * r * (1 + r) ** t;
         var b = (1 + r) ** t - 1;

         var paymentMonth = a / b;

         paymentMonthAll.push(paymentMonth);
      });
      return paymentMonthAll;
   });

   const CalculatePaymentMonth = () => {
      var paymentMonthAll = [];
      var p = amountBorrow - DownPayment;
      var t = lengthMortgage * 12;

      banksInformation.forEach(bankInfo => {
         var r = (parseFloat(bankInfo.interest) / 12) * 0.01;

         var a = p * r * (1 + r) ** t;
         var b = (1 + r) ** t - 1;

         var paymentMonth = a / b;

         paymentMonthAll.push(paymentMonth);
      });
      setPaymentMonth(paymentMonthAll);
   };

   const handlemountBorrow = (event, newValue) => {
      setAmountBorrow(newValue);
      CalculatePaymentMonth();
   };

   const handlemountBorrowTerm = event => {
      setAmountBorrow(event.target.value);
      CalculatePaymentMonth();
   };

   const handleLengthMortgage = (event, newValue) => {
      setLengthMortgage(newValue);
      CalculatePaymentMonth();
   };
   const handleLengthMortgageTerm = event => {
      setLengthMortgage(event.target.value);
      CalculatePaymentMonth();
   };

   const handleDownPayment = (event, newValue) => {
      setDownPayment(newValue);
      CalculatePaymentMonth();
   };
   const handleDownPaymentTerm = event => {
      setDownPayment(event.target.value);
      CalculatePaymentMonth();
   };

   const changeNumberCash = numberTemp => {
      const number = numberTemp
         .replace(',', '')
         .replace(',', '')
         .replace(',', '')
         .replace(',', '')
         .replace(',', '')
         .replace(',', '')
         .replace('.', '')
         .replace('.', '');
      const encode = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      return encode;
   };

   console.log('Elisavvet is here my g = ', paymentMonth);

   useEffect(() => {
      rankingPageAnimations();
   });

   return (
      <React.Fragment>
         <Parallax
            large
            filter='dark'
            style={{
               zIndex: '-1',
            }}
         >
            <StyledRankingHeader>
               <h2 className='ranking-page-title'>
                  {t('ranking.header.title-text')}
               </h2>
               <p
                  dangerouslySetInnerHTML={{
                     __html: t('ranking.header.paragraph-text'),
                  }}
               ></p>
            </StyledRankingHeader>
         </Parallax>

         {/* DYNAMIC COLORED SHADOWS START */}
         <StyledCalculatorsWrapper>
            {/* <GridContainer>
               <GridItem xs={12} sm={7} md={7}>
                  <div className={classes.title}>
                     <h2 id='ranking-title'>
                        Choose the <span>Best Bank</span> for you
                     </h2>
                     <h4>
                        This is all the potential banks that you can choose the
                        Financial product that best suits you. After the choice
                        we will help you to strucuture the application in order
                        to ensure a succesfull.
                     </h4>
                     <br />
                  </div>
               </GridItem>
            </GridContainer> */}
            <h2
               dangerouslySetInnerHTML={{
                  __html: t('ranking.section.title'),
               }}
            ></h2>
            <p
               style={{
                  marginBottom: '10vh',
                  fontSize: '4vh',
               }}
            >
               {t('ranking.section.text')}
            </p>

            <React.Fragment>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <Card blog style={{ maxHeight: '25em' }}>
                        <CardHeader image></CardHeader>
                        <CardBody>
                           <br />
                           <h4
                              className={classes.cardCategory}
                              style={{
                                 fontWeight: 'bold',
                                 fontSize: '4vh',
                                 width: '100%',
                                 color: '#270091',
                                 textAlign: 'center',
                              }}
                           >
                              {t('ranking.mortgage-params.title')}
                           </h4>

                           <Divider />
                           <br />

                           <GridContainer>
                              <GridItem xs={12} sm={4}>
                                 <TextField
                                    id='standard-basic'
                                    label={t('ranking.mortgage-params.input1')}
                                    style={{
                                       marginTop: '16px',
                                    }}
                                    InputLabelProps={{
                                       style: {
                                          fontSize: '1.5625rem',
                                          fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
                                          fontWeight: 300,
                                       },
                                    }}
                                    variant='filled'
                                    size='normal'
                                    value={amountBorrow}
                                    onChange={e => {
                                       handlemountBorrowTerm(e);
                                    }}
                                    InputProps={{
                                       inputComponent: NumberFormatCustom,
                                    }}
                                 />
                                 <br />
                                 {tooSmall === true ? (
                                    true
                                 ) : (
                                    <Grid container spacing={2}>
                                       <Grid item xs>
                                          <Slider
                                             step={500}
                                             min={0}
                                             max={1000000}
                                             valueLabelDisplay='auto'
                                             value={amountBorrow}
                                             onChange={handlemountBorrow}
                                             aria-labelledby='continuous-slider'
                                          />
                                       </Grid>
                                       <Grid item>
                                          <LocalAtmIcon />
                                       </Grid>
                                    </Grid>
                                 )}
                              </GridItem>

                              <GridItem xs={12} sm={4}>
                                 <TextField
                                    id='standard-basic'
                                    label={t('ranking.mortgage-params.input2')}
                                    style={{ marginTop: '16px' }}
                                    InputLabelProps={{
                                       style: {
                                          fontSize: '1.5625rem',
                                          fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
                                          fontWeight: 300,
                                       },
                                    }}
                                    variant='filled'
                                    size='normal'
                                    value={DownPayment}
                                    onChange={e => {
                                       handleDownPaymentTerm(e);
                                    }}
                                    InputProps={{
                                       inputComponent: NumberFormatCustom,
                                    }}
                                 />
                                 <br />
                                 {tooSmall === true ? (
                                    true
                                 ) : (
                                    <Grid container spacing={2}>
                                       <Grid item xs>
                                          <Slider
                                             valueLabelDisplay='auto'
                                             step={500}
                                             min={0}
                                             max={amountBorrow}
                                             value={DownPayment}
                                             onChange={handleDownPayment}
                                             aria-labelledby='continuous-slider'
                                          />
                                       </Grid>

                                       <Grid item>
                                          <NoteIcon />
                                       </Grid>
                                    </Grid>
                                 )}
                              </GridItem>

                              <GridItem xs={12} sm={4}>
                                 <TextField
                                    id='standard-basic'
                                    label={t('ranking.mortgage-params.input3')}
                                    style={{ marginTop: '16px' }}
                                    InputLabelProps={{
                                       style: {
                                          fontSize: '1.5625rem',
                                          fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
                                          fontWeight: 300,
                                       },
                                    }}
                                    variant='filled'
                                    size='normal'
                                    value={lengthMortgage}
                                    onChange={e => {
                                       handleLengthMortgageTerm(e);
                                    }}
                                 />
                                 <br />
                                 {tooSmall === true ? (
                                    true
                                 ) : (
                                    <Grid container spacing={2}>
                                       <Grid item xs>
                                          <Slider
                                             valueLabelDisplay='auto'
                                             min={1}
                                             max={30}
                                             value={lengthMortgage}
                                             onChange={handleLengthMortgage}
                                             aria-labelledby='continuous-slider'
                                          />
                                       </Grid>

                                       <Grid item>
                                          <DateRangeIcon />
                                       </Grid>
                                    </Grid>
                                 )}
                              </GridItem>

                              <GridItem xs={12} sm={1} md={1}></GridItem>
                           </GridContainer>
                        </CardBody>
                        <br />
                     </Card>
                  </GridItem>
               </GridContainer>
            </React.Fragment>

            {banksInformation.map((bank, index) => (
               <React.Fragment>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={12}>
                        <Card blog>
                           <CardHeader image>
                              <a
                                 href='#pablo'
                                 onClick={e => e.preventDefault()}
                              >
                                 <img
                                    src={bank.picture}
                                    alt='...'
                                    style={{
                                       maxHeight: '10em',
                                       maxWidth: '15em',
                                    }}
                                 />
                              </a>
                              {/* <div
                                 className={classes.coloredShadow}
                                 style={{
                                    backgroundImage: `url(${bank.picture})`,
                                    opacity: '1',
                                    maxHeight: '10em',
                                    maxWidth: '15em',
                                 }}
                              /> */}
                           </CardHeader>
                           <CardBody>
                              <GridContainer>
                                 {/* <GridItem xs={12} sm={1} md={1} lg={1}></GridItem> */}
                                 <GridItem xs={6} sm={6} md={2} lg={3}>
                                    {small === true ? (
                                       <>
                                          <h5>{t('ranking.bank.text1')}:</h5>
                                          <h5 className={classes.cardTitle}>
                                             {bank.interest}%
                                          </h5>
                                       </>
                                    ) : (
                                       <>
                                          <h3>{t('ranking.bank.text1')}:</h3>
                                          <h3 className={classes.cardTitle}>
                                             {bank.interest}%
                                          </h3>
                                       </>
                                    )}
                                    {/* <h3 className={classes.cardTitle} 
                          >
                              {bank.interest}%
                          </h3> */}
                                 </GridItem>

                                 <GridItem xs={6} sm={6} md={3} lg={3}>
                                    {small === true ? (
                                       <>
                                          <h5>{t('ranking.bank.text2')}:</h5>
                                          <h5 className={classes.cardTitle}>
                                             {formatCurrency(
                                                paymentMonth[index],
                                                'pl'
                                             )}
                                          </h5>
                                       </>
                                    ) : (
                                       <>
                                          <h3>{t('ranking.bank.text2')}:</h3>
                                          <h3 className={classes.cardTitle}>
                                             {formatCurrency(
                                                paymentMonth[index],
                                                'pl'
                                             )}
                                          </h3>
                                       </>
                                    )}
                                    {/* {small===true?<h5>Instalments:</h5>:<h3>Instalments:</h3>}

                          <h3 className={classes.cardTitle} 
                          >
                              ${paymentMonth[index].toFixed(2)}
                          </h3> */}
                                 </GridItem>
                                 <GridItem xs={4} sm={5} md={3} lg={3}>
                                    {small === true ? (
                                       <>
                                          <h5>{t('ranking.bank.text3')}:</h5>
                                          <h5 className={classes.cardTitle}>
                                             {formatCurrency(
                                                paymentMonth[index] *
                                                   lengthMortgage *
                                                   12,
                                                'pl'
                                             )}
                                          </h5>
                                       </>
                                    ) : (
                                       <>
                                          <h3>{t('ranking.bank.text3')}:</h3>
                                          <h3 className={classes.cardTitle}>
                                             {formatCurrency(
                                                paymentMonth[index] *
                                                   lengthMortgage *
                                                   12,
                                                'pl'
                                             )}
                                          </h3>
                                       </>
                                    )}

                                    {/* {small===true?<h5>:</h5>:<h3>Repaid:</h3>}
                          <h3 className={classes.cardTitle} 
                          >
                            ${(paymentMonth[index]*lengthMortgage*12).toFixed(2)}
                          </h3> */}
                                 </GridItem>
                                 <GridItem
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                 ></GridItem>
                                 {/* <GridItem xs={3} sm={6} md={3} lg={1}> */}
                                 <div style={{ paddingTop: 30 }}>
                                    {/* <Button variant="contained" color="primary">
                              Ask for Loan
                            </Button> */}
                                    <TalkToExpert bank={bank} />
                                 </div>
                                 {/* </GridItem> */}
                                 <GridItem
                                    style={{
                                       marginTop: '20px',
                                    }}
                                 >
                                    <h3
                                       style={{
                                          fontWeight: 'bold',
                                          fontSize: '3vh',
                                          color: '#270091',
                                       }}
                                    >
                                       {t('ranking.bank.comments')}:
                                    </h3>
                                    <p
                                       style={{
                                          fontSize: '3vh',
                                       }}
                                    >
                                       Tutaj do uzupełnienia
                                    </p>
                                 </GridItem>
                              </GridContainer>
                           </CardBody>
                           <br />
                           {/* <br/> */}
                        </Card>
                     </GridItem>
                  </GridContainer>
               </React.Fragment>
            ))}
            <div
               style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '5vh 0 5vh 0',
               }}
            >
               <p
                  style={{
                     fontSize: '4vh',
                     lineHeight: '4.5vh',
                     textAlign: 'center',
                  }}
               >
                  To tylko wybrane{' '}
                  <span
                     style={{
                        color: '#FF00CF',
                     }}
                  >
                     najpopularniejsze banki.
                  </span>
                  <br></br> W razie potrzeby współpracujemy z wieloma innymi.
               </p>
            </div>
         </StyledCalculatorsWrapper>

         <Footer />
      </React.Fragment>
   );
}

export default RankingOffers;
