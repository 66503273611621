import styled from 'styled-components';

export const StyledJoinSection = styled.section`
   width: 100%;
   z-index:1000;
   display: flex;
   flex-direction: column;
   
   padding: 0 25% 10vh 25%;
   > div {
      flex: 1;
   }
   > div:nth-child(1) {
      margin-bottom:10vh;
    > h3 {
        text-align: center;
        font-weight: 100;
        font-size: 6vh;
        margin-bottom:1%;
        @media (max-width: 1050px) {
         font-size: 2.4rem;
      }
        > span {
           color:  ${({ theme }) => theme.colors.purple.light};
        }
     }
    > p{
        text-align: center;
        font-weight: 200;
        font-size: 4vh;
        line-height:4.5vh;
        @media (max-width: 1050px) {
         font-size: 2rem;
      }
        color:grey;
        padding: 0 5%;
    }
  }
   }
   .join-us-grid{
        padding:0 15%;
   }
   .select-grid-item{
      display:flex;
      padding-top:1.1%;
      align-items:center;
   }
   //mobile 
   // mobile
   @media (max-width: 1050px) {
      padding: 0 10%;
   }
  
`;
