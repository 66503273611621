import anime from 'animejs/lib/anime.es.js';

export const contactPageAnimations = () => {
   // contact page title letters animation
   const textWrapper = document.querySelector('.contact-page-title');
   if (textWrapper) {
      textWrapper.innerHTML = textWrapper.textContent.replace(
         /\S/g,
         "<span class='letter'>$&</span>"
      );

      anime.timeline().add({
         targets: '.contact-page-title .letter',
         translateX: [40, 0],
         translateZ: 0,
         opacity: [0, 1],
         easing: 'easeOutExpo',
         duration: 1200,
         delay: (el, i) => 500 + 30 * i,
      });
   }
};
