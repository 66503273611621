import React, { useEffect } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap/dist/gsap';

import { StyledFooterContainer } from './Footer.css.js';

import InfoContainer from 'views-components/InfoContainer/InfoContainer.js';

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
   return (
      <StyledFooterContainer id='footer-container'>
         <InfoContainer />
      </StyledFooterContainer>
   );
};

export default Footer;
