import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { aboutCompanyAnimations } from 'animations/homePageAnimations.js';

import {
   StyledAboutCompanyContainer,
   StyledAboutCompanyContentWrapper,
   StyledBubblesContainer,
   StyledTextWrapper,
} from './AboutCompany.css.js';

//material ui
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import AboutCompanyModal from './Modal.js';

import { styled } from '@material-ui/core';

const box1BackgroundUrl = '/img/box-1-background.jpg';
const box2BackgroundUrl = '/img/box-2-background.jpg';
const box3BackgroundUrl = '/img/box-3-background.webp';
const box4BackgroundUrl = '/img/box-4-background.jpg';
const box5BackgroundUrl = '/img/box-5-background.jpg';

const GridItemStyled = styled(GridItem)(({ theme }) => ({
   [theme.breakpoints.down('sm')]: {
      marginBottom: '8px',
      padding: '0 !important',
   },
   [theme.breakpoints.down('md')]: {
      padding: '0 4px 0 0 ',
   },
   [theme.breakpoints.up('lg')]: {
      marginBottom: '8px',
      padding: '0 ',
   },
}));

const GridItemStyledLeft = styled(GridItem)(({ theme }) => ({
   [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
   },
   [theme.breakpoints.down('md')]: {
      padding: '0 0 0 4px ',
   },
   [theme.breakpoints.up('lg')]: {
      padding: '0',
   },
}));

const AboutCompany = () => {
   const [isModalActive, setIsModalActive] = useState(false);
   const [activeBubble, setActiveBubble] = useState({
      bubble: 1,
      titleNumber: '12',
   });

   const { t } = useTranslation();

   const handleCloseModal = () => {
      setIsModalActive(false);
   };
   const handleOpenModal = (bubble, titleNumber) => {
      setActiveBubble({
         bubble,
         titleNumber,
      });
      setIsModalActive(true);
   };

   useEffect(() => {
      aboutCompanyAnimations();
   }, []);

   return (
      <StyledAboutCompanyContainer id='about-company-container'>
         <AboutCompanyModal
            handleCloseModal={handleCloseModal}
            isModalActive={isModalActive}
            activeBubble={activeBubble}
         />
         <h2
            dangerouslySetInnerHTML={{
               __html: t('homepage.bubbles.title', {
                  interpolation: { escapeValue: false },
               }),
            }}
         ></h2>
         <StyledAboutCompanyContentWrapper>
            <GridContainer spacing={1}>
               <GridItem lg={6} md={12} xs={12}>
                  <StyledTextWrapper>
                     <p
                        id='about-company-text'
                        dangerouslySetInnerHTML={{
                           __html: t('homepage.bubbles.header-text', {
                              interpolation: { escapeValue: false },
                           }),
                        }}
                     ></p>
                  </StyledTextWrapper>
               </GridItem>
               <GridItem id='bubble-box-4' lg={3} md={6} xs={12}>
                  <div
                     className='grid-bubble'
                     onClick={() => handleOpenModal(4, 12)}
                     style={{
                        backgroundImage: `url(${box4BackgroundUrl}`,
                     }}
                  >
                     <div>
                        <h5
                           style={{
                              fontSize: '55px',
                           }}
                        >
                           21
                        </h5>
                        <p>{t('homepage.bubbles.box-4-title')}</p>
                        <span className='bubble-text-expansion'>
                           {t('homepage.bubbles.box-4-text')}
                        </span>
                     </div>
                  </div>
               </GridItem>
               <GridItem id='bubble-box-5' lg={3} md={6} xs={12}>
                  <div
                     className='grid-bubble'
                     onClick={() => handleOpenModal(5, '500.000.000+')}
                     style={{
                        backgroundImage: `url(${box5BackgroundUrl}`,
                     }}
                  >
                     <div>
                        <h5
                           style={{
                              fontSize: '50px',
                           }}
                        >
                           500.000.000+
                        </h5>
                        <p>{t('homepage.bubbles.box-5-title')}</p>
                        <span className='bubble-text-expansion'>
                           {t('homepage.bubbles.box-5-text')}
                        </span>
                     </div>
                  </div>
               </GridItem>
               {/* <GridItem id='empty-grid' lg={6} md={6} xs={12}></GridItem> */}

               <GridItem id='bubble-box-1' lg={6} md={12} sm={12} xs={12}>
                  <div
                     className='grid-bubble'
                     onClick={() => handleOpenModal(1, '12')}
                     style={{
                        backgroundImage: `url(${box1BackgroundUrl}`,
                        backgroundPosition: '20%',
                        height: '100%',
                        minHeight: '250px',
                     }}
                  >
                     <div>
                        <h5
                           style={{
                              fontSize: '55px',
                           }}
                        >
                           12
                        </h5>
                        <p>{t('homepage.bubbles.box-1-title')}</p>
                        <span className='bubble-text-expansion'>
                           {t('homepage.bubbles.box-1-text')}
                        </span>
                     </div>
                  </div>
               </GridItem>
               <GridContainer
                  style={{
                     marginLeft: 0,
                     padding: 3,
                  }}
                  spacing={-1}
                  id='bubble-box-2'
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
               >
                  <GridItemStyled lg={12} md={6}>
                     <div
                        className='grid-bubble'
                        onClick={() => handleOpenModal(2, '2000+')}
                        style={{
                           backgroundImage: `url(${box2BackgroundUrl}`,
                           backgroundPosition: '20%',
                           // marginBottom: '8px',
                        }}
                     >
                        <div>
                           <h5
                              style={{
                                 fontSize: '55px',
                              }}
                           >
                              2000 +
                           </h5>
                           <p>{t('homepage.bubbles.box-2-title')}</p>
                           <span className='bubble-text-expansion'>
                              {t('homepage.bubbles.box-2-text')}
                           </span>
                        </div>
                     </div>
                  </GridItemStyled>
                  <GridItemStyledLeft lg={12} md={6}>
                     <div
                        className='grid-bubble'
                        onClick={() => handleOpenModal(3, '0')}
                        style={{
                           backgroundImage: `url(${box3BackgroundUrl}`,
                        }}
                     >
                        <div>
                           <h5
                              style={{
                                 fontSize: '55px',
                              }}
                           >
                              0
                           </h5>
                           <p>{t('homepage.bubbles.box-3-title')}</p>
                           <span className='bubble-text-expansion'>
                              {t('homepage.bubbles.box-3-text')}
                           </span>
                        </div>
                     </div>
                  </GridItemStyledLeft>
               </GridContainer>

               {/* <GridItem id='bubble-box-3' lg={6} md={6} sm={12} xs={12}>
                  <div
                     className='grid-bubble'
                     onClick={() => handleOpenModal(3, '0')}
                     style={{
                        backgroundImage: `url(${box3BackgroundUrl}`,
                     }}
                  >
                     <h5
                        style={{
                           fontSize: '55px',
                        }}
                     >
                        0
                     </h5>
                     <p>{t('homepage.bubbles.box-3-title')}</p>
                     <span className='bubble-text-expansion'>
                        {t('homepage.bubbles.box-3-text')}
                     </span>
                  </div>
               </GridItem> */}
               {/* <GridItem
                  style={{
                     height: '350px',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                  }}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
               >
                  <div
                     className='grid-bubble'
                     style={{
                        backgroundImage: `url(${activeBubbleBackground})`,
                        width: `${30 * 1.6}vh`,
                        height: '30vh',
                     }}
                  >
                     <h5
                        style={{
                           fontSize: '55px',
                        }}
                     >
                        {activeBubbleTitleNumber}
                     </h5>
                     <p>{t(`homepage.bubbles.box-${activeBubble}-title`)}</p>
                     <span
                        style={{
                           display: 'block',
                           color: 'white',
                           textAlign: 'center',
                        }}
                        className='bubble-text-expansion'
                     >
                        {t(`homepage.bubbles.box-${activeBubble}-text`)}
                     </span>
                  </div>
               </GridItem> */}

               {/* <GridItem
                  id='empty-grid'
                  style={{
                     height: '250px',
                  }}
                  lg={3}
                  md={0}
                  sm={0}
                  xs={6}
                  display={{ md: 'none', lg: 'block' }}
               ></GridItem> */}
            </GridContainer>

            {/* <div>
               <p
                  id='about-company-text'
                  dangerouslySetInnerHTML={{
                     __html: t('homepage.bubbles.header-text', {
                        interpolation: { escapeValue: false },
                     }),
                  }}
               ></p>
            </div>  */}
            {/* <StyledBubblesContainer> */}
            {/* <div 
                  className='bubble bubble-1'
                  onClick={() => handleChangeActiveBubble(0)}
               >
                  <h5>12</h5>
                  <p>{t('homepage.bubbles.box-1-title')}</p>
                  <span className='bubble-text-expansion'>
                     {t('homepage.bubbles.box-1-text')}
                  </span>
               </div>
               <div
                  className='bubble bubble-2'
                  onClick={() => handleChangeActiveBubble(1)}
               >
                  <h5>2000 +</h5>
                  <p> {t('homepage.bubbles.box-2-title')}</p>
                  <span className='bubble-text-expansion '>
                     {t('homepage.bubbles.box-2-text')}
                  </span>
               </div>
               <div
                  className='bubble bubble-3'
                  onClick={() => handleChangeActiveBubble(2)}
               >
                  <h5>0</h5>
                  <p>{t('homepage.bubbles.box-3-title')}</p>
                  <span className='bubble-text-expansion'>
                     {t('homepage.bubbles.box-3-text')}
                  </span>
               </div>

               <div
                  className='bubble bubble-4'
                  onClick={() => handleChangeActiveBubble(3)}
               >
                  <h5>21</h5>
                  <p> {t('homepage.bubbles.box-4-title')}</p>
                  <span className='bubble-text-expansion '>
                     {t('homepage.bubbles.box-4-text')}
                  </span>
               </div>
               <div
                  className='bubble bubble-5'
                  onClick={() => handleChangeActiveBubble(4)}
               >
                  <h5>500.000.000 +</h5>
                  <p> {t('homepage.bubbles.box-5-title')}</p>
                  <span className='bubble-text-expansion'>
                     {t('homepage.bubbles.box-5-text')}
                  </span>
               </div> */}
            {/* </StyledBubblesContainer> */}
         </StyledAboutCompanyContentWrapper>
      </StyledAboutCompanyContainer>
   );
};

export default AboutCompany;
