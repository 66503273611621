import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
   reviewsMenuAnimations,
   activeCardsTrigger,
} from 'animations/homePageAnimations.js';

import {
   StyledReviewsMenuContainer,
   StyledCardsContainer,
   StyledFeatureCard,
   StyledFeatureNumberWrapper,
   StyledFeatureTextWrapper,
   StyledImageWrapper,
} from './ReviewsMenu.css.js';
import gsap from 'gsap/dist/gsap';

const ReviewsMenu = () => {
   const [activeCard, setActiveCard] = useState();
   const { t } = useTranslation();

   useEffect(() => {
      reviewsMenuAnimations();
      activeCardsTrigger(setActiveCard);
   }, []);
   return (
      <StyledReviewsMenuContainer id='reviews-menu'>
         <StyledCardsContainer>
            <StyledImageWrapper />
            <StyledFeatureCard
               id='service-card-1'
               className='feature-card'
               onClick={() => {
                  document.querySelector('#reviews-box-1').scrollIntoView({
                     behavior: 'smooth',
                  });
                  setTimeout(() => {
                     window.scrollTo(0, window.scrollY + 1);
                  }, 1200);
               }}
               isActive={activeCard === 0}
            >
               <StyledFeatureNumberWrapper className='card-title'>
                  <h2>01</h2>
               </StyledFeatureNumberWrapper>
               <StyledFeatureTextWrapper className='card-text'>
                  <h3>{t('homepage.reviews.menu.title1')}</h3>
                  <h4>{t('homepage.reviews.menu.text1')}</h4>
               </StyledFeatureTextWrapper>
            </StyledFeatureCard>
            <StyledFeatureCard
               id='service-card-2'
               className='feature-card'
               onClick={() => {
                  document.querySelector('#reviews-box-2').scrollIntoView({
                     behavior: 'smooth',
                  });
                  setTimeout(() => {
                     window.scrollTo(0, window.scrollY + 1);
                  }, 1200);
               }}
               isActive={activeCard === 1}
            >
               <StyledFeatureNumberWrapper className='card-title'>
                  <h2>02</h2>
               </StyledFeatureNumberWrapper>
               <StyledFeatureTextWrapper className='card-text'>
                  <h3>{t('homepage.reviews.menu.title2')}</h3>
                  <h4>{t('homepage.reviews.menu.text2')}</h4>
               </StyledFeatureTextWrapper>
            </StyledFeatureCard>
            <StyledFeatureCard
               id='service-card-3'
               className='feature-card'
               onClick={() =>
                  document.querySelector('#reviews-box-3').scrollIntoView({
                     behavior: 'smooth',
                  })
               }
               isActive={activeCard === 2}
            >
               <StyledFeatureNumberWrapper className='card-title'>
                  <h2>03</h2>
               </StyledFeatureNumberWrapper>
               <StyledFeatureTextWrapper className='card-text'>
                  <h3>{t('homepage.reviews.menu.title3')}</h3>
                  <h4>{t('homepage.reviews.menu.text3')}</h4>
               </StyledFeatureTextWrapper>
            </StyledFeatureCard>
         </StyledCardsContainer>
      </StyledReviewsMenuContainer>
   );
};

export default ReviewsMenu;
